import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const getLoadLinkVehicleType = async () => {
  const response = await axiosInterceptor.get(
    API.QUOTE.LOAD_LINK.GET_VEHICLE_TYPE
  );

  return response;
};
