import { CheckCircle } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';
import Confirmation from 'src/components/Confirmation';
import { updateQuoteStatus } from 'src/services/QuoteService';
import WalToast from 'src/utils/WalToast';

const ConfirmOrder = ({ data, handleClose, getQuoteById }: any) => {
  const confirmOrderBooking = () => {
    updateQuoteStatus(data.id)
      .then(() => {
        WalToast.success('Quote Booking Requested successfully');
        getQuoteById();
        handleClose();
      })
      .catch(() => {
        WalToast.error('Quote booking not requested ', '');
        handleClose();
      });
  };

  return (
    <CommonModal
      handleClose={() => handleClose()}
      primaryBtnOnClick={confirmOrderBooking}
      secondaryBtnOnClick={() => handleClose()}
      // title="Confirm Order Booking"
      size="max-w-[480px]"
      // titleDesc={`Confirm and Finalize Your Order Booking Process.`}
      modalHeaderClass="border-b-0 !px-6 !pt-6 !pb-2"
      modalHeaderTextClass="!flex-col"
      modalFooterClass="border-t-0 pt-0 max-w-[264px] mx-auto w-full"
      headerIcon={<CheckCircle />}
      secondaryBtnText="Cancel"
      primaryBtnText="Book Order"
    >
      <div className="px-5 pb-5">
        <Confirmation
          title="Confirm Order Booking"
          desc="Confirm and Finalize Your Order Booking Process"
          containerClassName="!my-0 pt-16"
          contentClassName="before:!bg-[url('../../assets/img/not-found-images/coming-soon.png')] min-w-[280px]"
          Icon={<CheckCircle className="w-7 h-7" />}
        />
        <ul className="my-4 rounded-md border border-utilityGray200p p-3 flex flex-col gap-y-3">
          <li className="flex justify-between gap-x-2">
            <h6 className="text-grayLight900 text-xs font-normal">
              Total Cost
            </h6>
            <p className="text-grayLight900 text-xs font-medium">
              ${data?.finalCharge}
            </p>
          </li>
          <li className="flex justify-between gap-x-2">
            <h6 className="text-grayLight900 text-xs font-normal">
              Est. Transit Time
            </h6>
            <p className="text-grayLight900 text-xs font-medium">
              Est. {data.transitTime} Working days
            </p>
          </li>
        </ul>
      </div>
    </CommonModal>
  );
};
export default ConfirmOrder;
