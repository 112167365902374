import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useCallback, useEffect, useRef } from 'react';

// Import the marker images
// import middleMarkerImg from '../../../assets/img/pin-light.svg';
import startMarkerImg from '../../../assets/img/pinWithParcel.svg';
import endMarkerImg from '../../../assets/img/pinWithParcel.svg';

interface MapboxProps {
  startPoint: { lat: number; lng: number; name: string | null };
  endPoint?: { lat: number; lng: number; name: string | null };
  midpoint?: { lat: number; lng: number; name: string };
  height?: any;
  className?: string;
  markerRef?: any;
  isShowRoute?: boolean;
  isZoomControlVisible?: boolean;
  isFullscreen?: boolean;
  isDraggable?: boolean;
  isZoomable?: boolean;
  defaultZoom?: any;
}

const MapboxMap: React.FC<MapboxProps> = ({
  startPoint,
  endPoint,
  midpoint,
  height = 500,
  className,
  markerRef,
  isShowRoute,
  isZoomControlVisible = false,
  isFullscreen = true,
  isDraggable = false,
  isZoomable = true,
  defaultZoom = 2,
}) => {
  const mapRef = useRef<any>(null);
  const mapContainerRef = useRef<any>(null);

  const initializeMap = useCallback(() => {
    if (!window?.MAPBOX_ACCESSTOKEN) {
      console.error('Mapbox access token is missing');

      return;
    }

    mapboxgl.accessToken = window.MAPBOX_ACCESSTOKEN;

    // if (mapContainerRef.current && !mapRef.current) {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/light-v11',
      center: [-95, 55],
      zoom: defaultZoom,
      pitch: 0,
      bearing: 0,
      boxZoom: isZoomable,
      antialias: true,
      dragPan: isDraggable,
      scrollZoom: isZoomable,
      doubleClickZoom: isZoomable,
      touchZoomRotate: isZoomable,
      projection: 'mercator',
      attributionControl: false,
    });

    if (isZoomControlVisible) {
      mapRef.current.addControl(new mapboxgl.NavigationControl());
    }

    // Fullscreen control
    if (isFullscreen) {
      const fullscreenControl = new mapboxgl.FullscreenControl();
      mapRef.current.addControl(fullscreenControl);
    }

    if (startPoint || endPoint) {
      mapRef.current.on('load', async () => {
        if (startPoint && startPoint.lng) {
          // Create a custom marker for the start point
          const startMarker = document.createElement('div');
          startMarker.style.width = '23px';
          startMarker.style.height = '30px';
          startMarker.style.backgroundImage = `url(${startMarkerImg})`;
          startMarker.style.backgroundSize = 'contain';

          // Add start point marker
          const startMarkerObj = new mapboxgl.Marker(startMarker)
            .setLngLat([startPoint.lng, startPoint.lat])
            .addTo(mapRef.current);

          if (startPoint?.name) {
            // Create a Popup for the start point with the place name
            const startPopup = new mapboxgl.Popup({
              offset: 25,
              closeButton: false,
            }).setHTML(`<h3>${startPoint.name}</h3>`);

            startMarkerObj.setPopup(startPopup); // Attach popup to marker
            startPopup.addTo(mapRef.current); // Show the popup by default
          }
        }

        if (endPoint && endPoint.lng) {
          // Create a custom marker for the end point
          const endMarker = document.createElement('div');
          endMarker.style.width = '23px';
          endMarker.style.height = '30px';
          endMarker.style.backgroundImage = `url(${endMarkerImg})`;
          endMarker.style.backgroundSize = 'contain';

          // Add end point marker
          const endMarkerObj = new mapboxgl.Marker(endMarker)
            .setLngLat([endPoint.lng, endPoint.lat])
            .addTo(mapRef.current);

          if (endPoint?.name) {
            const endPopup = new mapboxgl.Popup({
              offset: 25,
              closeButton: false,
            }).setHTML(`<h3>${endPoint.name}</h3>`);

            endMarkerObj.setPopup(endPopup);
            endPopup.addTo(mapRef.current);
          }
        }

        if (
          isShowRoute &&
          startPoint &&
          startPoint.lng &&
          endPoint &&
          endPoint.lng
        ) {
          const query = await fetch(
            `https://api.mapbox.com/directions/v5/mapbox/driving/${startPoint.lng},${startPoint.lat};${endPoint.lng},${endPoint.lat}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=${mapboxgl.accessToken}`,
            { method: 'GET' }
          );

          const json = await query.json();

          if (json.routes && json.routes.length > 0) {
            const fetchedRoute = json.routes[0].geometry.coordinates;

            mapRef.current.addSource('fetched-route', {
              type: 'geojson',
              data: {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates: fetchedRoute,
                },
              },
            });

            mapRef.current.addLayer({
              id: 'fetched-route',
              type: 'line',
              source: 'fetched-route',
              layout: {
                'line-join': 'round',
                'line-cap': 'round',
              },
              paint: {
                'line-color': '#444CE7',
                'line-width': 3,
                'line-opacity': 0.75,
              },
            });

            // Adjust map bounds
            const bounds = new mapboxgl.LngLatBounds(
              fetchedRoute[0],
              fetchedRoute[0]
            );

            fetchedRoute.forEach((coord: any) => bounds.extend(coord));
            mapRef.current.fitBounds(bounds, {
              padding: { top: 50, bottom: 50, left: 50, right: 50 },
            });
          }
        }

        const bounds = new mapboxgl.LngLatBounds();

        if (startPoint && startPoint.lng) {
          bounds.extend([startPoint.lng, startPoint.lat]);
        }

        if (endPoint && endPoint.lng) {
          bounds.extend([endPoint.lng, endPoint.lat]);
        }

        // mapRef?.current?.fitBounds(bounds, {
        //   padding: { top: 50, bottom: 50, left: 50, right: 50 },
        // });

        if (startPoint && endPoint) {
          mapRef?.current?.fitBounds(bounds, {
            padding: { top: 50, bottom: 50, left: 50, right: 50 },
            maxZoom: 10,
          });
        } else if (startPoint) {
          mapRef?.current?.setCenter([startPoint.lng, startPoint.lat]);
          mapRef?.current?.setZoom(defaultZoom > 16 ? defaultZoom : 16);
        } else if (endPoint) {
          mapRef?.current?.setCenter([endPoint.lng, endPoint.lat]);
          mapRef?.current?.setZoom(defaultZoom > 16 ? defaultZoom : 16);
        }
      });
    }
    // }
  }, [startPoint, endPoint]);

  useEffect(() => {
    initializeMap();

    // return () => {
    //   if (mapRef.current) {
    //     mapRef.current.remove();
    //   }
    // };
  }, [initializeMap]);

  useEffect(() => {
    if (midpoint?.lat && midpoint?.lng) {
      const midpointMarker = document.createElement('div');
      midpointMarker.style.width = '23px';
      midpointMarker.style.height = '30px';
      midpointMarker.style.backgroundImage = `url(${endMarkerImg})`;
      midpointMarker.style.backgroundSize = 'cover';

      if (markerRef.current) {
        markerRef.current.remove();
      }

      markerRef.current = new mapboxgl.Marker(midpointMarker)
        .setLngLat([midpoint.lng, midpoint.lat])
        .addTo(mapRef.current);
    } else {
      if (markerRef.current) {
        markerRef.current.remove();
        markerRef.current = null;
      }
    }

    return () => {
      if (markerRef.current) {
        markerRef.current.remove();
        markerRef.current = null;
      }
    };
  }, [midpoint]);

  return (
    <div
      id="map-container"
      ref={mapContainerRef}
      style={{ width: '100%', height: `${height}` }}
      className={className}
    />
  );
};

export default MapboxMap;
