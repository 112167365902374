import { ImagePlus } from '@untitled-ui/icons-react/build/cjs';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { languages } from 'src/constants/common';

import ButtonCmp from '../../components/ButtonCmp';
import ErrorMsg from '../../components/errorMsg';
import InputText from '../../components/InputText/InputText';
import PhoneInputCmp from '../../components/PhoneInputCmp/PhoneInputCmp';
import { ROUTES } from '../../constants/routes';
import { AuthContext } from '../../context/AuthContext';
// import { BasicContext } from '../../context/BasicContext';
import { saveBasicDetails } from '../../services/AuthService';
import AuthWrapper from '../AppLayout/AuthWrapper';

// Custom validation functions
const validateEmail = (email: string) => /\S+@\S+\.\S+/.test(email);

const stepTitle = {
  title: 'Create your account',
  subtitle: 'Fill Your Personal Details.',
} as any;

const SaveBasicDetails = () => {
  const formRef = useRef<any>();
  const { setIsAuthenticated, currentUser, setIsUpdateProfile, isMainUser } =
    useContext(AuthContext);
  const [isFormSubmitLoading, setIsFormSubmitLoading] = useState<any>(false);
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState<any>(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState<any>(false);

  const [formData, setFormData] = useState<any>({
    name: currentUser?.name || '',
    profile_image: '',
    title: currentUser?.title || '',
    contact_email: currentUser?.email || '',
    contact_no: currentUser?.phone || '',
    phone_country_code: '',
    language: currentUser?.language || 'english',
  });

  useEffect(() => {
    if (currentUser?.isSetBasicDetail) {
      navigate(ROUTES.HOME);
    }

    if (isMainUser) {
      navigate(ROUTES.SET_DETAILS);
    }
    const defaultProfileImage = currentUser?.image
      ? `${currentUser?.imageUrl}${currentUser?.image}`
      : null;
    setProfileImage(defaultProfileImage || null);
    setFormData({
      name: currentUser?.name || '',
      profile_image: defaultProfileImage,
      title: currentUser?.title || '',
      contact_email: currentUser?.email || '',
      contact_no: currentUser?.phone || '',
      phone_country_code: '',
      language: currentUser?.language || 'english',
    });
  }, [currentUser]);

  const [validForm, setValidForm] = useState<any>({
    name: true,
    title: true,
    contact_email: true,
    contact_no: true,
    contact_no_valid: true,
    profile_image: true,
    profile_image_size_valid: true,
  });

  // Validation function for each step
  const validateStep = () => {
    let isValid = true;
    const newValidForm = { ...validForm };

    // Step 1 Validation

    if (!formData?.name || formData?.name?.trim() === '') {
      newValidForm.name = false;
      isValid = false;
    } else {
      newValidForm.name = true;
    }

    if (!formData?.title || formData?.title?.trim() === '') {
      newValidForm.title = false;
      isValid = false;
    } else {
      newValidForm.title = true;
    }

    if (!validateEmail(formData.contact_email)) {
      newValidForm.contact_email = false;
      isValid = false;
    } else {
      newValidForm.contact_email = true;
    }

    if (profileImage === null || profileImage === '') {
      newValidForm.profile_image = false;
      isValid = false;
    } else {
      newValidForm.profile_image = true;
    }

    if (formData.contact_no === null || formData.contact_no === '') {
      newValidForm.contact_no = false;
      isValid = false;
    } else {
      newValidForm.contact_no = true;
    }
    setValidForm(newValidForm);

    return isValid;
  };

  // Handle input change
  const handleInputChange = (event: any) => {
    setFormData({ ...formData, [event?.target?.name]: event?.target?.value });

    // Live validation for the current field
    setValidForm({
      ...validForm,
      [event?.target?.name]: event?.target?.value.trim() !== '',
    });
  };

  const hasAnyFormValueFalse = (object: any) => {
    for (let key in object) {
      if (key === 'profile_image' || key === 'phone_country_code') {
        continue;
      }

      if (!object[key]) {
        return false;
      }
    }

    return true;
  };

  const convertToFormDataWithImages = async (formDataStructure: any) => {
    const formValueData = new FormData();

    for (const step in formDataStructure) {
      if (step === 'profile_image') {
        continue;
      }
      formValueData.append(`${step}`, formDataStructure[step]);
      // await appendStepData(step);
    }

    return formValueData;
  };

  // Final form submission
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsFormSubmitted(true);
    console.log('🚀 ~ handleSubmit ~ payload:', formData);
    if (!validateStep() || !hasAnyFormValueFalse(formData)) return;
    setIsFormSubmitted(false);
    const payload = await convertToFormDataWithImages(formData);
    console.log('🚀 ~ handleSubmit ~ payload:', payload);
    payload.append(`customer_logo`, formData?.profile_image as any);
    setIsFormSubmitLoading(true);

    saveBasicDetails(payload)
      .then((response: any) => {
        if (response?.status) {
          setIsAuthenticated(true);
          setIsUpdateProfile(true);
          navigate(ROUTES.HOME);
        }
        setIsFormSubmitLoading(false);
      })
      .catch((error: any): any => {
        setIsFormSubmitLoading(false);
        console.log('🚀 ~ handleSubmit ~ error:', error);
      });
    // Handle final form submission logic here
    console.log('Form submitted:', payload);
  };

  // const handleNumberChange = (
  //   country: any,
  //   phone: string,
  //   code: string,
  //   isValid: boolean
  // ) => {
  //   // setFormData({
  //   //   ...formData,
  //   //   ['contact_no']: phone === '' || phone === null ? '' : `+${code}${phone}`,
  //   // });

  //   setFormData((old: any) => ({
  //     ...old,
  //     contact_no: phone === '' || phone === null ? '' : `+${code}${phone}`,
  //     phone_country_code: country,
  //   }));

  //   // setFormData({ ...formData, ['phone_country_code']: country });

  //   if ((phone !== '' || phone !== null) && isValid) {
  //     setValidForm({
  //       ...validForm,
  //       ...{ contact_no: true, contact_no_valid: isValid },
  //     });
  //   } else {
  //     setValidForm({ ...validForm, contact_no_valid: isValid });
  //   }
  // };

  const handleNumberChange = (phone: any, isValid: any) => {
    setFormData((old: any) => ({
      ...old,
      contact_no: phone === '' || phone === null ? '' : `${phone}`,
    }));
    setValidForm({
      ...validForm,
      ...{ contact_no: true, contact_no_valid: isValid },
    });
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      setProfileImage(null);

      if (file.type.match('image')) {
        setFormData({ ...formData, ['profile_image']: file });
      }

      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const img = new Image();

          img.onload = () => {
            if (img.width > 800 || img.height > 800) {
              setValidForm({
                ...validForm,
                ['profile_image_size_valid']: false,
                ['profile_image']: true,
              });
            } else {
              setValidForm({
                ...validForm,
                ['profile_image_size_valid']: true,
                ['profile_image']: true,
              });
            }

            setProfileImage(img.src);
          };
          img.src = e.target!.result as string;
        };
        reader.readAsDataURL(file);
      }
    },
    [formData, validForm]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/svg+xml': ['.svg'],
    },
  });

  const handleDropdownChange = (e: any) => {
    setFormData({
      ...formData,
      ['language']: e?.value,
    });
  };

  const externalSubmitHandler = () => {
    // Programmatically trigger the form submission
    formRef.current.requestSubmit();
  };

  return (
    <AuthWrapper
      authHeading={stepTitle?.title}
      authSubTitle={stepTitle?.subtitle}
      displayLogo={false}
      stepNavigation={
        <>
          {/* Navigation Buttons */}
          <div className="flex items-center justify-end pt-2  bottom-0 bg-white z-[1] sticky max-w-[380px] mx-auto w-full">
            <ButtonCmp
              type="submit"
              className="btn_primary min-w-[90px]"
              onClick={externalSubmitHandler}
              loading={isFormSubmitLoading}
            >
              Save
            </ButtonCmp>
          </div>
        </>
      }
    >
      <form ref={formRef} onSubmit={handleSubmit}>
        <div className="flex flex-col gap-y-4 ">
          {/* Step 1 - Personal Information */}
          <div>
            <label className="form_label block mb-1.5">Profile Image</label>
            <div className="flex gap-2 md:flex-row flex-col">
              {profileImage !== null ? (
                <img
                  className="w-[58px] h-[58px] rounded-lg border border-borderPrimary bg-utilityGray100 object-cover"
                  src={profileImage}
                  alt={formData.firstName}
                  title={formData.firstName}
                />
              ) : (
                <label className="w-[58px] h-[58px] rounded-lg border border-borderPrimary bg-utilityGray100 flex justify-center items-center">
                  <ImagePlus className="text-grayLight600" />
                </label>
              )}
              <div className="relative z-[1] flex-grow md:w-auto w-full cursor-pointer flex-auto rounded-xl border border-utilityGray200 py-3 px-5 text-center bg-white">
                <label
                  className="cursor-pointer"
                  htmlFor="fileSelect"
                  {...getRootProps()}
                >
                  <progress
                    className="progress bg-gray50 absolute left-0 top-0 -z-10 rounded-xl h-full w-[70%] hidden"
                    value="70"
                    max="100"
                  ></progress>
                  <div className="text-grayLight600 text-xs font-normal">
                    <p>
                      <span className="text-[#2422DD] font-semibold pr-1">
                        Click to upload{' '}
                      </span>
                      or drag and drop
                    </p>
                    <p className="text-[11px] leading-[18px]">
                      SVG, PNG or JPG (max. 800x800px)
                    </p>
                  </div>
                </label>
                <input
                  type="file"
                  name="image"
                  id="fileSelect11"
                  className="hidden"
                  {...getInputProps()}
                />
              </div>
            </div>

            {!validForm.profile_image && (
              <ErrorMsg errorText={'Profile Image is required.'} />
            )}
            {!validForm.profile_image_size_valid && (
              <ErrorMsg
                errorText={
                  'Image dimensions must be less than or equal to 800 x 800'
                }
              />
            )}
          </div>

          <div>
            <InputText
              label="Full Name"
              labelClassName="block mb-1.5"
              inputName="name"
              className={`input_text_auth${
                !validForm.name ? ' !border-[#FDA29B] ' : ' !border-[#D0D5DD] '
              }`}
              placeholder="Enter your full name"
              value={formData?.name}
              onChangeFunc={handleInputChange}
            />
            {!validForm.name && <ErrorMsg errorText="Name is required" />}
          </div>

          <div>
            <InputText
              label="Title"
              labelClassName="block mb-1.5"
              inputName="title"
              className={`input_text_auth${
                !validForm.title ? ' !border-[#FDA29B] ' : ' !border-[#D0D5DD] '
              }`}
              placeholder="Enter your title"
              value={formData?.title}
              onChangeFunc={handleInputChange}
            />
            {!validForm.title && <ErrorMsg errorText="Title is required" />}
          </div>
          <div className="">
            <div className="md:flex flex-col">
              <label className="form_label ">Phone Number</label>
              <PhoneInputCmp
                phoneInputWrapClass=""
                // country={'ca'}
                value={formData?.contact_no || ''}
                onChange={handleNumberChange}
                isFormSubmitted={isFormSubmitted}
                errorMessage={
                  !validForm.contact_no
                    ? 'Phone number is required.'
                    : !validForm.contact_no_valid
                    ? 'Invalid phone number'
                    : ''
                }
              />
            </div>
          </div>
          <div className="">
            <div className="md:flex flex-col">
              <label className="form_label ">Select Language</label>
              <SelectBox
                // parentClassName="w-[110px]"
                name="language"
                placeholder="Select Language"
                className={`form_control cursor-pointer w-screen`}
                // labelClassname="text-xs text-[#344054] font-bold"
                options={languages}
                isSearchable={true}
                id={`language`}
                onChangeFunc={handleDropdownChange}
                value={languages?.filter(
                  (val: any) => formData?.language === val?.value
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </AuthWrapper>
  );
};

export default SaveBasicDetails;
