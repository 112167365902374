import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const listUpcomingOrder = async (payload: any) => {
  const response = await axiosInterceptor.get(
    `${API.DASHBOARD.UPCOMING}?${payload}`
  );

  return response;
};

export const listRecentActivity = async () => {
  const response = await axiosInterceptor.get(
    `${API.DASHBOARD.RECENT_ACTIVITY}`
  );

  return response;
};
