import {
  ImagePlus,
  X,
  Mail01,
  Plus,
  Truck01,
  Train,
  Plane,
  Waves,
  Container,
} from '@untitled-ui/icons-react/build/cjs';
import { find, findIndex, isEqual, map, maxBy } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import CheckBox from 'src/components/CheckBox';
import Radio from 'src/components/Radio/Radio';
import { onError } from 'src/utils/CommonFunctions';

import AutoCompleteGoogle from '../../components/AutoCompleteGoogle/AutoCompleteGoogle';
import ButtonCmp from '../../components/ButtonCmp';
import ErrorMsg from '../../components/errorMsg';
import InputText from '../../components/InputText/InputText';
import PhoneInputCmp from '../../components/PhoneInputCmp/PhoneInputCmp';
import SelectBox from '../../components/SelectBox/SelectBox';
import { languages, scriptId } from '../../constants/common';
import { ROUTES } from '../../constants/routes';
import { AuthContext } from '../../context/AuthContext';
import { BasicContext } from '../../context/BasicContext';
import { getDetail, onboardSetDetails } from '../../services/AuthService';
import AuthWrapper from '../AppLayout/AuthWrapper';

// Custom validation functions
const validateEmail = (email: string) => /\S+@\S+\.\S+/.test(email);
// const validatePhone = (phone: string) => /^\d{10}$/.test(phone);
const validateRadio = (value: string) => value.trim() !== '';
const validateMultiSelect = (value: string[]) => value.length > 0;

const stepTitle = {
  step1: {
    title: 'Create your account',
    subtitle: 'Fill Your Personal Details.',
  },
  step2: {
    title: 'Set Up Your Organization',
    subtitle: 'Share your business details to get started.',
  },
  step3: {
    title: 'Get Started',
    subtitle: 'Help us tailor the experience to your shipping requirements.',
  },
} as any;

const SetDetails = () => {
  const { googleKey } = useContext(BasicContext);
  const { setIsAuthenticated, currentUser, setIsUpdateProfile, isMainUser } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const formRef = useRef<any>();
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  window.initMap = () => {
    setStatus(true);
  };
  const [profileImage, setProfileImage] = useState<any>(null);
  const [isFormSubmitLoading, setIsFormSubmitLoading] = useState<any>(false);
  const [companyLogo, setCompanyLogo] = useState<any>(null);
  const [address, setAddress] = useState<any>(null);
  const [fullAddress, setFullAddress] = useState<any>(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState<any>(false);

  const IconArray = {
    road: <Truck01 className="w-4 h-4" />,
    rail: <Train className="w-4 h-4" />,
    air: <Plane className="w-4 h-4" />,
    sea: <Waves className="w-4 h-4" />,
    other: <Container className="w-4 h-4" />,
  } as any;

  const [formData, setFormData] = useState<any>({
    step1: {
      name: currentUser?.name,
      profile_image: '',
      title: currentUser?.title,
      contact_email: currentUser?.email,
      contact_no: currentUser?.phone,
      phone_country_code: '',
      language: 'en',
    },
    step2: {
      company_name: '',
      company_logo: null,
      members: [],
      company_no: '',
    },
    step3: {
      shipping_manage_each_month: '',
      shipping_geo_type: [],
      shipment_transport_type: [],
    },
  });

  useEffect(() => {
    if (currentUser?.isSetBasicDetail) {
      navigate(ROUTES.HOME);

      return;
    }

    if (!isMainUser) {
      navigate(ROUTES.SET_BASIC_DETAILS);

      return;
    }
    const defaultProfileImage = currentUser?.image
      ? `${currentUser?.imageUrl}${currentUser?.image}`
      : null;
    setProfileImage(defaultProfileImage || null);

    setFormData({
      ...formData,
      step1: {
        // Replace/Update the step1 object
        ...formData.step1, // Preserve existing properties in step1 (if any)
        name: currentUser?.name,
        profile_image: defaultProfileImage,
        title: currentUser?.title,
        contact_email: currentUser?.email,
        contact_no: currentUser?.phone,
        phone_country_code: '',
      },
    });
    getDetail(currentUser?.customerId?.id)
      .then((response) => {
        const defaultCompanyImage = response?.data.image
          ? `${response?.data.imageUrl}${response?.data.image}`
          : null;

        setFormData({
          ...formData,
          step1: {
            // Replace/Update the step1 object
            ...formData.step1, // Preserve existing properties in step1 (if any)
            name: currentUser?.name,
            profile_image: defaultProfileImage,
            title: currentUser?.title,
            contact_email: currentUser?.email,
            contact_no: currentUser?.phone,
            phone_country_code: '',
          },
          [`step2` as any]: {
            ...formData[`step2` as any],
            ['company_name']: response?.data?.name,
            ['company_logo']: defaultCompanyImage,
            ['company_no']: response?.data.companyPhoneNumber,
            // ['company_address']: response?.data.company_address,
          },
        });
        setFullAddress(response?.data?.company_address);

        setCompanyLogo(defaultCompanyImage || null);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [currentUser]);

  const [validForm, setValidForm] = useState<any>({
    step1: {
      name: true,
      title: true,
      contact_email: true,
      contact_no: true,
      contact_no_valid: true,
      profile_image: true,
      profile_image_size_valid: true,
    },
    step2: {
      company_name: true,
      company_address: true,
      company_no: true,
      company_no_valid: true,
      company_logo: true,
      company_logo_size_valid: true,
    },
    step3: {
      shipping_manage_each_month: true,
      shipping_geo_type: true,
      shipment_transport_type: true,
    },
  });
  const addMoreArr = [
    {
      id: 1,
      email: '',
      role: null,
      emailErrorMsg: '',
      roleErrorMsg: '',
    },
  ];
  const userRole = [
    { label: 'User', value: 'user' },
    { label: 'Admin', value: 'admin' },
  ];

  const [currentStep, setCurrentStep] = useState(1);
  const [addMoreInvites, setAddMoreInvites] = useState(addMoreArr);
  // const [fullAddress, setFullAddress] = useState('');

  const validMemberForm = () => {
    let valid = true;

    if (addMoreInvites?.length > 0) {
      const arr = addMoreInvites;
      addMoreInvites?.map((v: any, index: any) => {
        const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;
        arr[index].emailErrorMsg = '';
        arr[index].roleErrorMsg = '';

        if (
          (v?.email === '' || v?.email === null) &&
          (v?.role === '' || v?.role === null)
        ) {
          return valid; // No validation errors if both are empty
        }

        if (v?.email === undefined || v?.email === '' || v?.email === null) {
          arr[index].emailErrorMsg = 'Email is required';
          valid = false;
        } else if (
          addMoreInvites[index]?.email &&
          !emailRegex.test(addMoreInvites[index]?.email)
        ) {
          arr[index].emailErrorMsg = 'Please enter valid email address';
          valid = false;
        }

        if (v?.role === undefined || v?.role === '' || v?.role === null) {
          arr[index].roleErrorMsg = 'Role is required';
          valid = false;
        }

        return valid;
      });

      map(addMoreInvites, function (o: any, i) {
        var eq = find(addMoreInvites, function (e: any, ind) {
          if (i > ind) {
            if (i > ind && e.email && o.email) {
              // Check both emails for null or undefined
              return isEqual(e.email, o.email);
            }
          }
        });

        if (eq) {
          if (o.emailErrorMsg === '') {
            o.emailErrorMsg = 'This email address is already added in the list';
            valid = false;
          }

          return o;
        } else {
          return o;
        }
      });

      // addMoreInvites.filter(function (o1) {
      //     return orgMember.some(function (o2: any) {
      //         if (o1.email === o2.member_email) {
      //             o1.emailErrorMsg = 'This email address is already invited';
      //             valid = false;
      //         }
      //         return valid
      //     });
      // })

      setAddMoreInvites([...arr]);
    }

    return valid;
  };

  // Validation function for each step
  const validateStep = (step = 1) => {
    let isValid = true;
    const newValidForm = { ...validForm };

    // Step 1 Validation
    if (step === 1) {
      if (formData?.step1?.name.trim() === '') {
        newValidForm.step1.name = false;
        isValid = false;
      } else {
        newValidForm.step1.name = true;
      }

      if (formData?.step1?.title.trim() === '') {
        newValidForm.step1.title = false;
        isValid = false;
      } else {
        newValidForm.step1.title = true;
      }

      if (!validateEmail(formData?.step1?.contact_email)) {
        newValidForm.step1.contact_email = false;
        isValid = false;
      } else {
        newValidForm.step1.contact_email = true;
      }

      if (!formData?.step1?.profile_image) {
        newValidForm.step1.profile_image = false;
        isValid = false;
      } else {
        newValidForm.step1.profile_image = true;
      }

      if (
        formData?.step1?.contact_no === null ||
        formData?.step1?.contact_no === ''
      ) {
        newValidForm.step1.contact_no = false;
        isValid = false;
      } else {
        newValidForm.step1.contact_no = true;
      }
      setValidForm({
        ...validForm,
        [`step${step}`]: newValidForm?.step1,
      });

      return isValid;
    }

    // Step 2 Validation
    if (step === 2) {
      if (formData?.step2?.company_name.trim() === '') {
        newValidForm.step2.company_name = false;
        isValid = false;
      } else {
        newValidForm.step2.company_name = true;
      }

      if (formData?.step2?.company_logo === null) {
        newValidForm.step2.company_logo = false;
        isValid = false;
      } else {
        newValidForm.step2.company_logo = true;
      }

      if (
        formData?.step2?.company_no === null ||
        formData?.step2?.company_no === ''
      ) {
        newValidForm.step2.company_no = false;
        isValid = false;
      } else {
        newValidForm.step2.company_no = true;
      }

      if (fullAddress === null || fullAddress === '') {
        newValidForm.step2.company_address = false;
        isValid = false;
      } else {
        newValidForm.step2.company_address = true;
      }

      if (!validMemberForm()) {
        isValid = false;
      } else {
        isValid = true;
      }
      setValidForm({
        ...validForm,
        [`step${step}`]: newValidForm?.step2,
      });

      return isValid;
    }

    // Step 3 Validation
    if (step === 3) {
      if (!validateRadio(formData?.step3?.shipping_manage_each_month)) {
        newValidForm.step3.shipping_manage_each_month = false;
        isValid = false;
      } else {
        newValidForm.step3.shipping_manage_each_month = true;
      }

      if (!validateMultiSelect(formData?.step3?.shipping_geo_type)) {
        newValidForm.step3.shipping_geo_type = false;
        isValid = false;
      } else {
        newValidForm.step3.shipping_geo_type = true;
      }

      if (!validateMultiSelect(formData?.step3?.shipment_transport_type)) {
        newValidForm.step3.shipment_transport_type = false;
        isValid = false;
      } else {
        newValidForm.step3.shipment_transport_type = true;
      }

      setValidForm({
        ...validForm,
        [`step${step}`]: newValidForm?.step3,
      });

      return isValid;
    }
  };

  // Handle input change
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    const step = currentStep;
    // console.log(fullAddress);
    // Update the specific field in the current step
    setFormData({
      ...formData,
      [`step${step}`]: {
        ...formData[`step${step}`],
        [name]: value,
      },
    });

    // Live validation for the current field
    setValidForm({
      ...validForm,
      [`step${step}`]: {
        ...validForm[`step${step}`],
        [name]: value.trim() !== '',
      },
    });
  };

  const hasAnyFormValueFalse = (object: any) => {
    for (let key in object) {
      if (!object[key]) {
        return false;
      }
    }

    return true;
  };

  // Handle the next step
  const handleNext = (event: any) => {
    event.preventDefault();
    setIsFormSubmitted(true);

    if (
      !validateStep(currentStep) ||
      !hasAnyFormValueFalse(validForm[`step${currentStep}`])
    ) {
      return;
    } else {
      setIsFormSubmitted(false);
      setCurrentStep(currentStep + 1);
    }
  };

  // Handle the previous step
  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const convertToFormDataWithImages = async (formDataStructure: any) => {
    const formValueData = new FormData();

    // Function to fetch the image and convert it to a Blob
    // const fetchImageAsBlob = async (imagePath: any) => {
    //   const response = await fetch(imagePath);
    //   const imageBlob = await response.blob();

    //   return imageBlob;
    // };

    // Convert structure to FormData
    const appendStepData = async (stepData: any, stepName: any) => {
      for (const field in stepData) {
        const value = stepData[field];

        if (field === 'profile_image' || field === 'company_logo') {
          continue;
        } else if (typeof value === 'object' && !Array.isArray(value)) {
          // Handle nested objects (like "address")
          for (const nestedField in value) {
            formValueData.append(
              `${stepName}[${field}][${nestedField}]`,
              value[nestedField]
            );
          }
        } else if (Array.isArray(value)) {
          formValueData.append(`${stepName}[${field}]`, value?.join(','));
        } else {
          // Regular fields (like name, contact_email)
          formValueData.append(`${stepName}[${field}]`, value);
        }
      }
    };

    // Iterate through each step and append its fields to FormData
    for (const step in formDataStructure) {
      await appendStepData(formDataStructure[step], step);
    }

    return formValueData;
  };

  // Final form submission
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (
      !validateStep(currentStep) ||
      !hasAnyFormValueFalse(formData[`step${currentStep}`])
    )
      return;
    // if (!validateStep()) return;
    const payload = await convertToFormDataWithImages(formData);
    const invites = addMoreInvites.filter(
      (member: any) => member?.email !== ''
    );
    payload.append(`step2[members]`, JSON.stringify(invites) as any);
    payload.append(`step2[address]`, JSON.stringify(address) as any);
    payload.append(`step2[fullAddress]`, fullAddress as any);
    payload.append(`customer_logo`, formData?.step1?.profile_image as any);
    payload.append(`company_logo`, formData?.step2?.company_logo as any);
    setIsFormSubmitLoading(true);

    onboardSetDetails(payload)
      .then((response: any) => {
        if (response?.status) {
          setIsAuthenticated(true);
          setIsUpdateProfile(true);
          navigate(ROUTES.HOME);
        }
        setIsFormSubmitLoading(false);
      })
      .catch((error: any): any => {
        setIsFormSubmitLoading(false);
        console.log('🚀 ~ handleSubmit ~ error:', error);
      });
  };

  const handleMultiSelectChange = (name: string, option: string) => {
    const currentValues = formData[`step${currentStep}`][name];
    const newValues = currentValues.includes(option)
      ? currentValues.filter((item: string) => item !== option) // Remove if already selected
      : [...currentValues, option]; // Add if not selected

    setFormData({
      ...formData,
      step3: {
        ...formData.step3,
        [name]: newValues,
      },
    });

    setValidForm({
      ...validForm,
      [`step${currentStep}`]: {
        ...validForm[`step${currentStep}`],
        [name]: newValues.length > 0,
      },
    });
  };

  // const handleNumberChange = (
  //   country: any,
  //   phone: string,
  //   code: string,
  //   isValid: boolean
  // ) => {
  //   setFormData({
  //     ...formData,
  //     [`step${currentStep}`]: {
  //       ...formData[`step${currentStep}`],
  //       [currentStep === 1 ? 'contact_no' : 'company_no']:
  //         phone === '' || phone === null ? '' : `+${code}${phone}`,
  //       ['phone_country_code']: country,
  //     },
  //   });

  //   if ((phone !== '' || phone !== null) && isValid) {
  //     setValidForm({
  //       ...validForm,
  //       [`step${currentStep}`]: {
  //         ...validForm[`step${currentStep}`],
  //         [currentStep === 1 ? 'contact_no' : 'company_no']: true,
  //         [currentStep === 1 ? 'contact_no_valid' : 'company_no_valid']:
  //           isValid,
  //       },
  //     });
  //   } else {
  //     setValidForm({
  //       ...validForm,
  //       [`step${currentStep}`]: {
  //         ...validForm[`step${currentStep}`],
  //         [currentStep === 1 ? 'contact_no_valid' : 'company_no_valid']:
  //           isValid,
  //       },
  //     });
  //   }
  // };

  const handleNumberChange = (phone: any, isValid: any) => {
    setFormData({
      ...formData,
      [`step${currentStep}`]: {
        ...formData[`step${currentStep}`],
        [currentStep === 1 ? 'contact_no' : 'company_no']:
          phone === '' || phone === null ? '' : `${phone}`,
      },
    });

    setValidForm({
      ...validForm,
      [`step${currentStep}`]: {
        ...validForm[`step${currentStep}`],
        [currentStep === 1 ? 'contact_no_valid' : 'company_no_valid']: isValid,
      },
    });
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const step = currentStep;

      if (step === 1) {
        setProfileImage(null);
      } else {
        setCompanyLogo(null);
      }

      if (file.type.match('image')) {
        setFormData({
          ...formData,
          [`step${step}`]: {
            ...formData[`step${step}`],
            [step === 1 ? 'profile_image' : 'company_logo']: file,
          },
        });
      }

      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const img = new Image();

          img.onload = () => {
            if (img.width > 800 || img.height > 800) {
              setValidForm({
                ...validForm,
                [`step${currentStep}`]: {
                  ...validForm[`step${currentStep}`],
                  [currentStep === 1
                    ? 'profile_image_size_valid'
                    : 'company_logo_size_valid']: false,
                  [step === 1 ? 'profile_image' : 'company_logo']: true,
                },
              });
            } else {
              setValidForm({
                ...validForm,
                [`step${currentStep}`]: {
                  ...validForm[`step${currentStep}`],
                  [currentStep === 1
                    ? 'profile_image_size_valid'
                    : 'company_logo_size_valid']: true,
                  [step === 1 ? 'profile_image' : 'company_logo']: true,
                },
              });

              if (currentStep === 1) {
                setProfileImage(img.src);
              } else {
                setCompanyLogo(img.src);
              }
            }
          };
          img.src = e.target!.result as string;
        };
        reader.readAsDataURL(file);
      }
    },
    [formData, currentStep, validForm]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/svg+xml': ['.svg'],
    },
  });

  const addNewInvites = () => {
    const maxId = maxBy(addMoreInvites, 'id') as any;
    const newObj = {
      id: maxId?.id + 1 || 1,
      email: '',
      role: '',
      errorMsg: '',
    };
    setAddMoreInvites((s: any) => [...s, newObj]);
  };

  const handleRemoveMember = (id: any) => {
    const newObj = addMoreInvites.filter((member: any) => member?.id !== id);
    setAddMoreInvites(newObj);
  };

  const handleMultiInputChange = (e: any, index: any) => {
    const ind = findIndex(addMoreInvites, { id: parseInt(index) });
    const newUpdate = JSON.parse(JSON.stringify(addMoreInvites));
    newUpdate[ind].email = e.target.value;
    setAddMoreInvites(newUpdate);
  };

  const handleSelectChange = (e: any, index: any) => {
    const ind = findIndex(addMoreInvites, { id: parseInt(index) });
    const newUpdate = JSON.parse(JSON.stringify(addMoreInvites));
    newUpdate[ind].role = e?.value || '';
    setAddMoreInvites(newUpdate);
  };

  const handleDropdownChange = (e: any) => {
    setFormData({
      ...formData,
      [`step${currentStep}`]: {
        ...formData[`step${currentStep}`],
        ['language']: e?.value,
      },
    });
  };

  const handleSelected = (id: any) => {
    const ind = findIndex(addMoreInvites, { id: parseInt(id) });

    return userRole?.find(
      (val: any) => val.value === addMoreInvites?.[ind]?.role
    );
  };

  const externalSubmitHandler = () => {
    // Programmatically trigger the form submission
    formRef.current.requestSubmit();
  };

  return (
    <AuthWrapper
      authHeading={stepTitle[`step${currentStep}`]?.title}
      authSubTitle={stepTitle[`step${currentStep}`]?.subtitle}
      displayLogo={false}
      stepNavigation={
        <>
          {/* Navigation Buttons */}
          <div className="flex items-center justify-between pt-2  bottom-0 bg-white z-[1] sticky max-w-[380px] mx-auto w-full pb-3">
            <div>
              <ul className="flex items-center gap-x-4">
                <li
                  className={`w-2.5 h-2.5 rounded-full ${
                    currentStep === 1 ? 'bg-primary' : 'bg-utilityGray200'
                  }`}
                ></li>
                <li
                  className={`w-2.5 h-2.5 rounded-full ${
                    currentStep === 2 ? 'bg-primary' : 'bg-utilityGray200'
                  }`}
                ></li>
                <li
                  className={`w-2.5 h-2.5 rounded-full ${
                    currentStep === 3 ? 'bg-primary' : 'bg-utilityGray200'
                  }`}
                ></li>
                <li className="text-gray500 text-xs font-medium">
                  Step {currentStep} of 3
                </li>
              </ul>
            </div>
            <div className="flex gap-x-2.5">
              {currentStep > 1 && (
                <ButtonCmp
                  type="button"
                  className="btn_secondary_black"
                  onClick={handleBack}
                >
                  Back
                </ButtonCmp>
              )}

              {currentStep < 3 ? (
                <ButtonCmp
                  type="button"
                  className="btn-outline-primary"
                  onClick={handleNext}
                >
                  Continue
                </ButtonCmp>
              ) : (
                <ButtonCmp
                  type="submit"
                  className="btn-outline-primary"
                  onClick={externalSubmitHandler}
                  loading={isFormSubmitLoading}
                >
                  Create
                </ButtonCmp>
              )}
            </div>
          </div>
        </>
      }
    >
      <>
        <form ref={formRef} onSubmit={handleSubmit} className="">
          <div className="flex flex-col gap-y-4 ">
            {/* Step 1 - Personal Information */}
            {currentStep === 1 && (
              <>
                <div>
                  <label className="form_label block mb-1.5">
                    Profile Image
                  </label>

                  <div className="flex gap-2 md:flex-row flex-col">
                    {profileImage ? (
                      <img
                        className="w-[58px] h-[58px] rounded-lg border border-borderPrimary bg-utilityGray100 object-cover"
                        src={profileImage}
                        alt={formData.firstName}
                        title={formData.firstName}
                        onError={onError}
                      />
                    ) : (
                      <label className="w-[58px] h-[58px] rounded-lg border border-borderPrimary bg-utilityGray100 flex justify-center items-center">
                        <ImagePlus className="text-grayLight600" />
                      </label>
                    )}
                    <div className="relative z-[1] flex-grow md:w-auto w-full cursor-pointer flex-auto rounded-xl border border-utilityGray200 py-3 px-5 text-center bg-white">
                      <label
                        className="cursor-pointer"
                        htmlFor="fileSelect"
                        {...getRootProps()}
                      >
                        <progress
                          className="progress bg-gray50 absolute left-0 top-0 -z-10 rounded-xl h-full w-[70%] hidden"
                          value="70"
                          max="100"
                        ></progress>
                        <div className="text-grayLight600 text-xs font-normal">
                          <p>
                            <span className="text-[#2422DD] font-semibold pr-1">
                              Click to upload{' '}
                            </span>
                            or drag and drop
                          </p>
                          <p className="text-[11px] leading-[18px]">
                            SVG, PNG or JPG (max. 800x800px)
                          </p>
                        </div>
                      </label>
                      <input
                        type="file"
                        name="image"
                        id="fileSelect11"
                        className="hidden"
                        {...getInputProps()}
                      />
                    </div>
                  </div>

                  {!validForm?.step1?.profile_image && (
                    <ErrorMsg errorText={'Profile Image is required.'} />
                  )}
                  {!validForm?.step1?.profile_image_size_valid && (
                    <ErrorMsg
                      errorText={
                        'Image dimensions must be less than or equal to 800 x 800'
                      }
                    />
                  )}
                </div>

                <div>
                  <InputText
                    label="Full Name"
                    labelClassName="block mb-1.5"
                    inputName="name"
                    className={`input_text_auth${
                      !validForm?.step1?.name
                        ? ' !border-[#FDA29B] '
                        : ' !border-[#D0D5DD] '
                    }`}
                    placeholder="Enter your full name"
                    value={formData?.step1?.name}
                    onChangeFunc={handleInputChange}
                  />
                  {!validForm?.step1?.name && (
                    <ErrorMsg errorText="Name is required" />
                  )}
                </div>

                <div>
                  <InputText
                    label="Title"
                    labelClassName="block mb-1.5"
                    inputName="title"
                    className={`input_text_auth${
                      !validForm?.step1?.title
                        ? ' !border-[#FDA29B] '
                        : ' !border-[#D0D5DD] '
                    }`}
                    placeholder="Enter your title"
                    value={formData?.step1?.title}
                    onChangeFunc={handleInputChange}
                  />
                  {!validForm?.step1?.title && (
                    <ErrorMsg errorText="Title is required" />
                  )}
                </div>

                <div className="">
                  <div className="md:flex flex-col">
                    <label className="form_label ">Phone Number</label>
                    {/* <PhoneInput
                      onNumberChange={handleNumberChange}
                      name="contact_no"
                      errors={
                        !validForm?.step1?.contact_no
                          ? 'Phone number is required'
                          : !validForm?.step1?.contact_no_valid &&
                            isFormSubmitted
                          ? 'Invalid Number'
                          : null
                      }
                      value={formData?.step1?.contact_no || ''}
                    /> */}
                    <PhoneInputCmp
                      phoneInputWrapClass=""
                      value={formData?.step1?.contact_no || ''}
                      onChange={handleNumberChange}
                      isFormSubmitted={isFormSubmitted}
                      errorMessage={
                        !validForm?.step1?.contact_no
                          ? 'Phone number is required'
                          : !validForm?.step1?.contact_no_valid &&
                            isFormSubmitted
                          ? 'Invalid Number'
                          : ''
                      }
                    />
                  </div>
                </div>
                <div className="">
                  <div className="md:flex flex-col">
                    <label className="form_label ">Select Language</label>
                    <SelectBox
                      // parentClassName="w-[110px]"
                      name="language"
                      placeholder="Select Language"
                      className={`form_control cursor-pointer w-screen`}
                      // labelClassname="text-xs text-[#344054] font-bold"
                      options={languages}
                      isSearchable={true}
                      id={`language`}
                      onChangeFunc={handleDropdownChange}
                      value={languages?.filter(
                        (val: any) => formData?.step1?.language === val?.value
                      )}
                    />
                  </div>
                </div>
              </>
            )}
            {/* Step 2 - Company Details */}
            {currentStep === 2 && (
              <>
                <div>
                  <InputText
                    label="Company Name"
                    labelClassName="block mb-1.5"
                    inputName="company_name"
                    className={`input_text_auth${
                      !validForm?.step2?.company_name
                        ? ' !border-[#FDA29B] '
                        : ' !border-[#D0D5DD] '
                    }`}
                    placeholder="Company Name"
                    value={formData?.step2?.company_name}
                    onChangeFunc={handleInputChange}
                  />
                  {!validForm?.step2?.company_name && (
                    <ErrorMsg errorText="Company name is required" />
                  )}
                </div>

                <div className="flex flex-col">
                  <label className="form_label  block mb-1.5">
                    Company Logo
                  </label>

                  <div className="flex gap-2 md:flex-row flex-col">
                    {companyLogo ? (
                      <img
                        className="w-[52px] h-[52px] rounded-lg border border-borderPrimary bg-utilityGray100"
                        src={companyLogo}
                        onError={onError}
                      />
                    ) : (
                      <label className="w-[52px] h-[52px] rounded-lg border border-borderPrimary bg-utilityGray100 flex justify-center items-center">
                        <ImagePlus className="text-grayLight600" />
                      </label>
                    )}
                    <div className="relative z-[1] flex-grow md:w-auto w-full cursor-pointer flex-auto rounded-xl border border-utilityGray200 py-3.5 px-5 text-center bg-white">
                      <label
                        className="cursor-pointer"
                        htmlFor="fileSelect"
                        {...getRootProps()}
                      >
                        <progress
                          className="progress bg-gray50 absolute left-0 top-0 -z-10 rounded-xl h-full w-[70%] hidden"
                          value="70"
                          max="100"
                        ></progress>
                        <div className="text-grayLight600 text-xs font-normal">
                          <p>
                            <span className="text-[#2422DD] font-semibold pr-1">
                              Click to upload{' '}
                            </span>
                            or drag and drop
                          </p>
                          <p className="text-[11px] leading-[18px]">
                            SVG, PNG or JPG (max. 800x800px)
                          </p>
                        </div>
                      </label>
                      <input
                        type="file"
                        name="image"
                        id="fileSelect11"
                        className="hidden"
                        {...getInputProps()}
                      />
                    </div>
                  </div>

                  {!validForm?.step2?.company_logo && (
                    <ErrorMsg errorText={'Company logo is required.'} />
                  )}
                  {!validForm?.step2?.company_logo_size_valid && (
                    <ErrorMsg
                      errorText={
                        'Image dimensions must be less than or equal to 800 x 800'
                      }
                    />
                  )}
                </div>

                {status && (
                  <AutoCompleteGoogle
                    inputName="company_address"
                    placeholder="Enter company address"
                    className="form_control truncate"
                    label="Company Address"
                    labelClassName="mb-1.5 block"
                    onChangeFunc={(e: any) => {
                      if (e) {
                        setFullAddress(e.fullAddress);
                        setAddress(e);
                        setValidForm({
                          ...validForm,
                          [`step${currentStep}`]: {
                            ...validForm[`step${currentStep}`],
                            ['company_address']: fullAddress?.trim() !== '',
                          },
                        });
                      }
                    }}
                    onAddressInputChange={(e: any) => {
                      setFullAddress(e);
                    }}
                    errorText={
                      !validForm?.step2?.company_address
                        ? 'Company Address is required'
                        : null
                    }
                    value={fullAddress}
                  />
                )}
                <div className="">
                  <div className="md:flex flex-col">
                    <label className="form_label ">
                      Company Contact Number
                    </label>
                    {/* <PhoneInput
                      onNumberChange={handleNumberChange}
                      name="phone"
                      errors={
                        !validForm?.step2?.company_no
                          ? 'Phone number is required'
                          : !validForm?.step2?.company_no_valid &&
                            isFormSubmitted
                          ? 'Invalid Number'
                          : null
                      }
                      value={formData.step2.company_no || ''}
                    /> */}

                    <PhoneInputCmp
                      phoneInputWrapClass=""
                      value={formData?.step2?.company_no || ''}
                      onChange={handleNumberChange}
                      isFormSubmitted={isFormSubmitted}
                      errorMessage={
                        !validForm?.step2?.company_no
                          ? 'Phone number is required'
                          : !validForm?.step2?.company_no_valid &&
                            isFormSubmitted
                          ? 'Invalid Number'
                          : ''
                      }
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-y-1.5">
                  <h6 className="form_label mb-1 block">
                    Invite Additional Team Members
                  </h6>
                  {addMoreInvites?.map((item, i) => (
                    <div className="flex gap-x-1.5 relative" key={i}>
                      <InputText
                        parentClassName="flex-1"
                        inputName="email"
                        className={`pl-8 ${
                          item.emailErrorMsg
                            ? ' border border-red-500 rounded-lg '
                            : ''
                        }`}
                        placeholder="Enter email address"
                        value={item?.email || ''}
                        onChangeFunc={(e) =>
                          handleMultiInputChange(e, item?.id)
                        }
                        id={`email_${item?.id}`}
                        errorText={
                          item?.emailErrorMsg ? item?.emailErrorMsg : null
                        }
                        icon={
                          <Mail01 className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                        }
                      />

                      <SelectBox
                        parentClassName="w-[110px]"
                        name="role"
                        placeholder="Select Role"
                        className={`form_control cursor-pointer w-screen`}
                        // labelClassname="text-xs text-[#344054] font-bold"
                        options={userRole}
                        id={`role_${item?.id}`}
                        classComp={`${
                          item.roleErrorMsg && !item?.role
                            ? 'border border-red-500 rounded-lg'
                            : ' input_border drop-down-border rounded-lg'
                        }`}
                        onChangeFunc={(e) => handleSelectChange(e, item?.id)}
                        value={handleSelected(item?.id)}
                        isClearable={true}
                        errorText={
                          item.roleErrorMsg && !item?.role
                            ? item.roleErrorMsg
                            : null
                        }
                      />

                      {i > 0 && (
                        <ButtonCmp className="!shadow-none !border-transparent !px-0 !bg-transparent w-fit cursor-default absolute -right-6">
                          <X
                            className={`w-4 h-4 text-primary ${
                              addMoreInvites?.length <= 1
                                ? 'cursor-not-allowed'
                                : 'cursor-pointer'
                            }`}
                            onClick={() => handleRemoveMember(item?.id)}
                          />
                        </ButtonCmp>
                      )}
                    </div>
                  ))}
                  <ButtonCmp
                    className="btn-outline-primary !p-0 !shadow-none !border-0 !bg-transparent w-fit"
                    icon={<Plus className="w-4 h-4 " />}
                    onClick={() => addNewInvites()}
                  >
                    Add new
                  </ButtonCmp>
                </div>
              </>
            )}
            {/* Step 3 - Shipping Information */}
            {currentStep === 3 && (
              <>
                <div className="mb-4">
                  <label className="form_label mb-2">
                    How many shipments do you do each month?
                  </label>
                  <div className="flex flex-wrap gap-2 ">
                    {['1-10', '5-10', '10-25', '25+'].map((option) => (
                      <Radio
                        key={option}
                        radioWrapClass="min-w-[21%] justify-center"
                        onChangeFunc={handleInputChange}
                        inputName="shipping_manage_each_month"
                        value={option}
                        radioLabel={option}
                        radioBox={true}
                        checked={
                          formData?.step3?.shipping_manage_each_month === option
                        }
                      />
                    ))}
                  </div>
                  {!validForm?.step3?.shipping_manage_each_month && (
                    <ErrorMsg errorText="Please select an option" />
                  )}
                </div>

                <div className="mb-4">
                  <label className="form_label mb-2">Where do you ship?</label>
                  <div className="flex flex-wrap gap-2 ">
                    {['Canada Domestic', 'Cross-Border', 'USA Domestic'].map(
                      (option) => (
                        <CheckBox
                          key={option}
                          name="shipping_geo_type"
                          onChangeFunc={() =>
                            handleMultiSelectChange('shipping_geo_type', option)
                          }
                          checked={formData?.step3?.shipping_geo_type.includes(
                            option
                          )}
                          checkboxBox={true}
                          value={option}
                          label={option}
                        />
                      )
                    )}
                  </div>
                  {!validForm?.step3?.shipping_geo_type && (
                    <ErrorMsg errorText="Please select at least one option" />
                  )}
                </div>

                <div className="mb-4">
                  <label className="form_label mb-2">
                    What types of shipments do you handle?
                  </label>
                  <div className="flex flex-wrap gap-2 ">
                    {['Road', 'Rail', 'Air', 'Sea', 'Other'].map((option) => (
                      <CheckBox
                        parentClassName="min-w-[31%] justify-center"
                        key={option}
                        name="shipment_transport_type"
                        onChangeFunc={() =>
                          handleMultiSelectChange(
                            'shipment_transport_type',
                            option
                          )
                        }
                        checked={formData?.step3?.shipment_transport_type.includes(
                          option
                        )}
                        checkboxBox={true}
                        value={option}
                        label={option}
                        labelIcon={IconArray?.[option?.toLowerCase()] as any}
                      />
                    ))}
                  </div>
                  {!validForm?.step3?.shipment_transport_type && (
                    <ErrorMsg errorText="Please select at least one option" />
                  )}
                </div>
              </>
            )}
          </div>
        </form>
      </>
    </AuthWrapper>
  );
};

export default SetDetails;
