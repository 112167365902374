import React from 'react';

import Loginlogo from '../../assets/img/logo2.svg';

const AuthWrapper = ({
  children,
  authHeading,
  authSubTitle,
  stepNavigation,
  authLogoClass,
  displayLogo = true,
}: any) => (
  <>
    <ul className="flex h-full bg-white">
      <li
        className={`w-[35%] px-4 my-4 overflow-auto custom-scrollbar-v2 flex flex-col`}
      >
        <div
          className={`max-w-[380px] mx-auto flex flex-col relative justify-start mt-[10vh] flex-1 w-full`}
        >
          {displayLogo && (
            <div
              className={`rounded border border-utilityGray200 bg-gray50 inline-block px-1.5 pt-[12px] pb-[9px] w-fit ${authLogoClass}`}
            >
              <img src={Loginlogo} alt="logo-dark" className="" />
            </div>
          )}
          <h6 className="text-utilityBlack600 text-3xl font-semibold mt-6">
            {authHeading}
          </h6>
          <p className="text-utilityTertiary600 text-sm font-normal mt-1 mb-8">
            {authSubTitle}
          </p>
          {children}
        </div>
        {stepNavigation}
      </li>
      <li className="flex-1 bg-[url('../../assets/img/auth-truck.webp')] bg-cover bg-center bg-no-repeat rounded-[30px] flex items-end relative overflow-hidden after:content-[''] after:bg-[linear-gradient(180deg,_rgba(0,_0,_0,_0.00)_5.12%,_rgba(0,_0,_0,_0.50)_83.55%)] after:h-full after:w-full after:absolute after:top-0 after:left-0 h-full">
        <div className="text-white p-12 z-[1]">
          <h5 className="mb-6 text-4xl font-semibold tracking-[-0.924px]">
            Welcome to the Western Alliance <br />
            Customer Portal
          </h5>
          <p className="text-base font-medium">
            Access your accounts, manage services, and explore personalized
            solutions with ease.
          </p>
        </div>
      </li>
    </ul>
  </>
);

export default AuthWrapper;
