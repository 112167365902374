import { Check, Star06 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactSelect, { components, NoticeProps } from 'react-select';
import { BasicContext } from 'src/context/BasicContext';
import { getShortName, onError } from 'src/utils/CommonFunctions';

import ActionTooltip from '../ActionTooltip';
import ErrorMsg from '../errorMsg';
import TooltipCmp from '../TooltipCmp';

import { ISelectBoxProps } from './SelectBox.interface';

const SelectBox = ({
  errorText = '',
  label = '',
  options,
  menuPlaceOption,
  noOptionMessage = 'No Option',
  classNamePrefix,
  tabIndex,
  isSearchable = false,
  isMultipleSelection,
  labelClassName = '',
  imageClassName,
  isDisabled,
  isError,
  value,
  defaultValue,
  onChangeFunc,
  onInputChange,
  isClearable,
  isLoading,
  placeholder,
  classComp = '',
  dropdownImageClassName,
  selectValueWrapClass,
  selectValueClass,
  isCloseOnScroll = true,
  icon,
  parentClassName,
  menuIsOpen,
  selectoptiontext,
  required,
  onMenuOpen,
  placeholderIcon,
  isShowAiStar,
  labelRight,
  labelWrapClassName,
  inlineSelect = false,
}: ISelectBoxProps) => {
  const selectRef = useRef<any>(null);
  const [menuIsOpenList, setMenuIsOpenList] = useState(menuIsOpen);
  const [isFocused, setIsFocused] = useState(false);
  const { setTooltipGlobalOpen } = useContext(BasicContext);

  const CustomOption = ({ ...props }: any) => {
    const { ...rest } = props.innerProps;
    const newProps = { ...props, innerProps: rest };

    return (
      <components.Option {...newProps}>
        <div className="flex justify-between items-center align-middle">
          <div
            className={`flex justify-center items-center w-full flex-1 max-w-[calc(100%_-_15px)] ${selectValueWrapClass}`}
          >
            {props.data.icon ? (
              <span className="mr-2">{props.data.icon}</span>
            ) : props.data.image ? (
              <img
                alt=""
                className={`rounded-full w-5 h-5 mr-2 flex-none ${dropdownImageClassName}`}
                src={props.data.image}
                onError={onError}
              />
            ) : (
              props.data.isImage && (
                <div className="w-[18px] h-[18px] leading-[18px] mr-2 bg-primary text-[8px] rounded-full font-bold text-white flex items-center justify-center uppercase flex-none">
                  <span>{getShortName(props.data.label)}</span>
                </div>
              )
            )}
            {props.data.value === 'all_user' ? (
              <div className="w-5 h-5 mr-2"></div>
            ) : (
              ''
            )}
            <div className={`flex-1 flex flex-wrap  truncate`}>
              {props.data.datetime && (
                <TooltipCmp
                  message={`${props.data.datetime ? props.data.datetime : ''}`}
                  parentClassName=""
                >
                  <span
                    className={`truncate font-medium mr-2 ${selectoptiontext}`}
                  >
                    {props.data.label}
                  </span>
                </TooltipCmp>
              )}
              {!props.data.datetime && (
                <span
                  className={`truncate font-medium mr-2 ${selectoptiontext}`}
                >
                  {props.data.label}
                </span>
              )}
              {props.data.subLabel ? (
                <span className="flex justify-center items-center font-medium sub-label">
                  {props.data.subLabel}
                </span>
              ) : (
                ''
              )}
              {props.data.email ? (
                <span className="flex justify-center items-center font-medium text-grayLight900 underline">
                  {props.data.email}
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
          {props.isSelected ? (
            <Check className="w-3 h-3 text-primary flex-none" />
          ) : (
            ''
          )}{' '}
        </div>
      </components.Option>
    );
  };

  const CustomNoOptionsMessage = (props: NoticeProps<any>) => (
    <components.NoOptionsMessage {...props}>
      <p className="text-[11px] text-textSecondary font-bold -my-[1px]">
        {noOptionMessage}
      </p>{' '}
      {/* Customize this message as needed */}
    </components.NoOptionsMessage>
  );

  useEffect(() => {
    setMenuIsOpenList(menuIsOpen);
  }, [menuIsOpen]);

  useEffect(() => {
    if (isCloseOnScroll) {
      const handleScroll = () => {
        setMenuIsOpenList(false);
      };

      const scrollableContainer = document.querySelector(
        '.main__layout_section'
      );

      if (scrollableContainer) {
        scrollableContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (scrollableContainer) {
          scrollableContainer.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (menuIsOpenList && selectRef.current) {
      selectRef.current.focus();
    }
  }, [menuIsOpenList]);

  useEffect(() => {
    if (
      !isMultipleSelection &&
      selectRef.current &&
      selectRef.current.state.focusedOption &&
      selectRef.current?.state.focusedOption?.value !== value?.value
    ) {
      selectRef.current.state.focusedOption = '';
    }
  }, [menuIsOpen]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (newValue: any, actionMeta: any) => {
    setIsFocused(false);

    if (newValue === null && actionMeta.action === 'clear') {
      if (onChangeFunc) onChangeFunc(null, actionMeta);
      setTimeout(() => {
        if (selectRef.current) {
          selectRef.current.blur();
        }
      }, 400);
    } else {
      if (onChangeFunc) onChangeFunc(newValue, actionMeta); // Normal change handling
    }
  };

  return (
    <>
      <div
        className={`${parentClassName} ${
          isShowAiStar ? 'ai-gradient-border-wrap' : ''
        }`}
      >
        <div className={`flex justify-between gap-x-2 ${labelWrapClassName}`}>
          {label && (
            <div className="flex">
              <label className={labelClassName}>{label}</label>
              {required && (
                <span className="text-red-600 ms-[2px] leading-4 text-xs">
                  *
                </span>
              )}
            </div>
          )}
          {labelRight && <>{labelRight}</>}
        </div>
        <div
          className={`wal-hq-select relative ${
            isLoading ? 'custom-loading rounded-lg' : ''
          } ${errorText || isError ? 'is-invalid' : ''}`}
        >
          <ReactSelect
            ref={selectRef}
            value={value}
            defaultValue={defaultValue}
            onChange={handleChange}
            onInputChange={onInputChange}
            options={options}
            menuPlacement={menuPlaceOption}
            formatOptionLabel={(option) => (
              <div className="flex justify-between">
                <div
                  className={`flex items-center w-full ${selectValueClass} `}
                >
                  {option.customLabel ? (
                    <span className="font-medium ">
                      {option.customLabel}&nbsp;
                    </span>
                  ) : (
                    ''
                  )}
                  {option?.icon ? (
                    <span className="mr-2">{option.icon}</span>
                  ) : option?.image ? (
                    <img
                      alt=""
                      className={`rounded-full w-5 h-5 mr-2 ${imageClassName}`}
                      src={option.image}
                      onError={onError}
                    />
                  ) : (
                    option.isImage && (
                      <div className="w-[18px] h-[18px] leading-[18px] bg-primary text-[8px] mr-2 rounded-full font-bold text-white flex items-center justify-center uppercase flex-none">
                        <span>{getShortName(option.label)}</span>
                      </div>
                    )
                  )}
                  <span className="truncate font-medium">
                    {option.datetime && (
                      <ActionTooltip
                        message={option.datetime}
                        openOnHover={true}
                        isOpenChildTopPosOnTop={true}
                        type="dark"
                        tooltipPosition="center"
                        parentClassName="w-max"
                      >
                        {option.label}
                      </ActionTooltip>
                    )}
                    {!option.datetime && option.label}
                  </span>

                  {option.email ? (
                    <span className="flex justify-center items-center font-medium underline ml-2">
                      {option.email}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            )}
            hideSelectedOptions={false}
            tabIndex={tabIndex}
            classNamePrefix={classNamePrefix ? classNamePrefix : 'select'}
            isSearchable={
              value?.length || Boolean(value && value.value)
                ? false
                : isSearchable
            }
            isClearable={isClearable}
            isDisabled={isDisabled}
            isMulti={isMultipleSelection}
            placeholder={placeholder}
            className={`${classComp}`}
            components={{
              ...(isDisabled && isMultipleSelection
                ? { MultiValueRemove: () => null }
                : {}),
              Option: CustomOption,
              NoOptionsMessage: CustomNoOptionsMessage,
              Placeholder: (props: any) => (
                <components.Placeholder {...props}>
                  {isFocused && isSearchable ? false : placeholderIcon}
                  {isFocused && isSearchable ? false : props.children}
                </components.Placeholder>
              ),
            }}
            menuIsOpen={menuIsOpenList}
            menuPortalTarget={inlineSelect ? null : document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 1111 }) }}
            classNames={{
              menuPortal: () => 'wal-hq-select',
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onMenuOpen={() => {
              setTooltipGlobalOpen(false);
              setMenuIsOpenList(true);

              if (onMenuOpen) {
                onMenuOpen();
              }
            }}
            onMenuClose={() => {
              if (isCloseOnScroll) {
                setMenuIsOpenList(false);
              }
            }}
          />
          {icon && icon}
          {isShowAiStar && (
            <div className="absolute top-1/2 -translate-y-1/2 left-2 w-4 h-4 mt-px">
              <ActionTooltip
                message={'Detected with AI'}
                isOpenChildTopPosOnTop={true}
                tooltipPosition="center"
                parentClassName="min-w-[120px]"
                openOnHover={true}
              >
                <Star06 className="w-4 h-4 text-primary " />
              </ActionTooltip>
            </div>
          )}
        </div>
        <div className="[&:empty]:mt-0 mt-1.5">
          {errorText && <ErrorMsg errorText={errorText}></ErrorMsg>}
        </div>
      </div>
    </>
  );
};

export default SelectBox;
