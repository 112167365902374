import React, { useEffect, useState } from 'react';
import {
  customFromNow,
  getBadgeTypeByMinutes,
} from 'src/utils/CommonFunctions';

import BadgeCmp from '../BadgeCmp';

interface IProps {
  badgeDate: string;
  componentClass?: string;
  onlyTimer?: boolean;
}

const TimerBadge = ({
  badgeDate,
  onlyTimer = false,
  componentClass = '',
}: IProps) => {
  const [, forceUpdate] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      forceUpdate((count) => count + 1);
    }, 100000);

    return () => clearInterval(interval);
  }, []);

  return !onlyTimer ? (
    <BadgeCmp
      style="pill"
      mainClassName="rounded-md min-w-[65px] justify-center !px-1 !border-borderPrimary shadow !bg-white"
      type={getBadgeTypeByMinutes(badgeDate)?.type}
    >
      <p className={`mr-1.5 h-1.5 w-1.5 bg-current rounded-full`}></p>
      <span className="flex text-textSecondary">
        {customFromNow(badgeDate)}
      </span>
    </BadgeCmp>
  ) : (
    <span className={`${componentClass}`}>{customFromNow(badgeDate)}</span>
  );
};

export default TimerBadge;
