import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import { TABLE_IDS } from 'src/constants/common';
import {
  ORDER_STATUS_TAB,
  ORDER_LIST_HEADER,
} from 'src/constants/orders-constant';
import { getOrderList } from 'src/services/OrdersService';
import { isValidJSON } from 'src/utils/CommonFunctions';

import OrderLoaderRow from './components/OrderLoaderRow';
import OrderRow from './components/OrderRow';

const recordsPerPageArray = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
];

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 10,
  orderStatus: 'all',
};

const Orders = () => {
  const [params, setParams] = useState(() => {
    const storedParams = localStorage.getItem(TABLE_IDS.ORDER_LIST);

    return storedParams && isValidJSON(storedParams)
      ? JSON.parse(storedParams)
      : initParams;
  });
  const navigate = useNavigate();
  // const [action, setAction] = useState<any>(initAction);
  const [isOrdersListLoading, setIsOrdersListLoading] = useState(false);
  const [search, setSearch] = useState(params?.search ?? '');
  const [totals, setTotals] = useState(0);
  const [orders, setOrders] = useState<any[]>([]);

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old: any) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchCustomer') {
      setSearch(value);
      searchDebounce(value);
    }
  };

  const getNewOrderData = (newParam: any) => {
    setIsOrdersListLoading(true);
    getOrderList(newParam)
      .then((response: any) => {
        setOrders(response.data ?? []);
        setTotals(response?.total ?? 0);
        setIsOrdersListLoading(false);
      })
      .catch((e: any) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsOrdersListLoading(false);
      });
  };

  useEffect(() => {
    const orderListApiController = new AbortController();

    localStorage.setItem(TABLE_IDS.ORDER_LIST, JSON.stringify(params));

    setIsOrdersListLoading(true);
    setOrders([]);

    getNewOrderData(params);

    return () => {
      orderListApiController.abort();
    };
  }, [params]);

  return (
    <PageSectionLayout
      header={
        <Header
          title="My Orders"
          desc="Stay updated on your orders from pickup to their final delivery."
          isShowNotificationBox
        />
      }
    >
      <>
        <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col  flex-1">
          <div className="table-top-header ">
            <div className="table-left-wrap sm:w-auto w-full">
              <div className="table-title-wrap">
                <h5 className="table-title">All Orders</h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={isOrdersListLoading ? 'custom-loading' : ''}
                >
                  {totals} {totals > 1 ? 'Orders' : 'Order'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                Manage and track all your orders in one place..
              </p>
            </div>
          </div>
          <div className="table-bottom-header">
            <div className="table-header-bottom-left flex flex-row xxl:gap-4 gap-3 justify-between w-full sm:items-center">
              <TabButton
                className="table-tab max-xxl:!px-3"
                activeClassName="!bg-utilityGray100"
                tabArray={ORDER_STATUS_TAB}
                parentClassName="table-tabs"
                isActive={params.orderStatus}
                handleOnClick={(e: any) => {
                  setParams((old: any) => ({
                    ...old,
                    orderStatus: e.target.dataset.value,
                  }));
                }}
              />

              <InputText
                inputName="searchCustomer"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={search}
                inputType="text"
                onChangeFunc={handleFilter}
                // parentClassName="table-searchInput !xxl:w-[19rem] "
                isClearable={true}
              />
            </div>
            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === params.limit
                )}
              />
            </div>
          </div>
          <div className="h-full lg:min-h-[122px] w-full border-t border-gray100 flex flex-col ">
            {/* {orders.length > 0 && ( */}
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide flex-1">
              <TableCmp
                headCells={ORDER_LIST_HEADER}
                params={params}
                setParams={setParams}
                tableDataArr={orders}
                TableLoaderRowCmp={OrderLoaderRow}
                TableRowCmp={OrderRow}
                numberOfSkeletonRows={15}
                tableHeaderClass=""
                tableRowCmpProps={{
                  navigate,
                  // setIsOrderDelete,
                  // setDeletOrderId,
                }}
                isTableDataLoading={isOrdersListLoading}
                isTableRowClickable={true}
              />
            </div>
            {/* // )} */}
            {!isOrdersListLoading && !orders.length && (
              <NotFoundUI
                title="No Order Found"
                desc="There are no order found."
                containerClassName="min-h-[unset]"
              />
            )}
          </div>

          <div className="w-full bg-white rounded-b-xl">
            <CustomPagination
              recordsPerPage={params.limit}
              totalRecords={totals}
              currentPage={params.page}
              handlePagination={(page: number) => {
                setParams((old: any) => ({ ...old, page }));
              }}
            />
          </div>
        </div>
      </>
    </PageSectionLayout>
  );
};
export default Orders;
