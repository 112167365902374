export const APP_ROOT = '/';

export const ROUTES = {
  HOME: `${APP_ROOT}`,
  LOGIN: `${APP_ROOT}login`,
  SET_DETAILS: `${APP_ROOT}set-details`,
  SIGN_UP: `${APP_ROOT}signup`,
  CALLBACK: `${APP_ROOT}callback`,
  MICROSOFT_CALLBACK: `${APP_ROOT}microsoft_callback`,
  SET_PASSWORD: `${APP_ROOT}set-password`,
  CREATE_PASSWORD: `${APP_ROOT}create-password`,
  VERIFICATION: `${APP_ROOT}verification`,
  FORGOTPASSWORD: `${APP_ROOT}forogot-password`,
  RESET_PASSWORD: `${APP_ROOT}reset-password`,
  VERIFY_PASSWORD: `${APP_ROOT}verify-password`,
  MY_QUOTE: `${APP_ROOT}my-quote`,
  ADDRESS_DETAILS: `${APP_ROOT}my-quotes/address-details/:id?`,
  DIMENSIONS: `${APP_ROOT}my-quotes/dimensions/:id?`,
  QUOTE_DETAIL: `${APP_ROOT}my-quotes/:id?`,
  SET_BASIC_DETAILS: `${APP_ROOT}set-basic-details`,
  ORGANIZATION: `${APP_ROOT}organization`,
  DOCUMENTATION_HUB: `${APP_ROOT}documentation-hub`,
  ORDERS: `${APP_ROOT}orders`,
  ORDER_DETAILS_VIEW: `${APP_ROOT}orders/:id?`,
  INTEGRATION: `${APP_ROOT}integrations`,
  SETTING: `${APP_ROOT}settings`,
  RADAR: `${APP_ROOT}radar`,
  RADAR_ORDER_DETAIL: `${APP_ROOT}radar-order-detail`,
};
