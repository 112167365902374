import React from 'react';
import { BrowserRouter, Route, Routes as ReactRoutes } from 'react-router-dom';
import Dashboard from 'src/app/Dashboard';
import DocumentHub from 'src/app/DocumentHub';
import Integration from 'src/app/Integration';
import ForgotPassword from 'src/app/Login/ForgotPassword';
import VerifyPassword from 'src/app/Login/VerifyPassword';
import MyQuote from 'src/app/MyQuote';
import AddressDetailsForm from 'src/app/MyQuote/AddressDetails/AddressDetailsForm';
import Dimensions from 'src/app/MyQuote/Dimensions';
import QuoteDetail from 'src/app/MyQuote/Quote';
import Orders from 'src/app/Orders';
import OrderView from 'src/app/Orders/OrderView';
import Organization from 'src/app/Organization';
import Radar from 'src/app/Radar';
import RadarOrderDetail from 'src/app/Radar/RadarOrderDetail';
import SetDetails from 'src/app/SetDetails';
import SaveBasicDetails from 'src/app/SetDetails/SaveBasicDetails';
import SetPassword from 'src/app/SetPassword';
import CreatePassword from 'src/app/SetPassword/CreatePassword';
import AccountSettings from 'src/app/Settings';
import Verification from 'src/app/Verification';

import AppLayout from '../app/AppLayout';
import Callback from '../app/Callback';
import PageNotFound from '../app/Errors/404';
import Login from '../app/Login';
import MicrosoftCallback from '../app/MicrosoftCallback';
import { PERMISSION } from '../constants/common';
import { ROUTES } from '../constants/routes';

import OnboardingRoute from './OnboardingRoute';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Routes = () => (
  <BrowserRouter>
    <ReactRoutes>
      <Route path={ROUTES.HOME} element={<AppLayout />}>
        <Route
          path={ROUTES.LOGIN}
          element={<PublicRoute component={Login} title="Login" />}
        />
        <Route path={ROUTES.CALLBACK} element={<Callback />} />
        {/* <Route
          path={ROUTES.MICROSOFT_CALLBACK}
          element={<PublicRoute component={MicrosoftCallback} title="Login" />}
        /> */}
        <Route
          path={ROUTES.SET_PASSWORD}
          element={<PublicRoute component={SetPassword} title="SetPassword" />}
        />
        <Route
          path={ROUTES.VERIFICATION}
          element={
            <PublicRoute component={Verification} title="Verification" />
          }
        />

        <Route
          path={ROUTES.FORGOTPASSWORD}
          element={
            <PublicRoute component={ForgotPassword} title="Forgot Password" />
          }
        />

        <Route
          path={ROUTES.SET_DETAILS}
          element={
            <OnboardingRoute component={SetDetails} title="Set Details" />
          }
        />
        <Route
          path={ROUTES.SET_BASIC_DETAILS}
          element={
            <OnboardingRoute component={SaveBasicDetails} title="Set Details" />
          }
        />
        <Route
          path={ROUTES.VERIFY_PASSWORD}
          element={
            <PublicRoute component={VerifyPassword} title="Verify Password" />
          }
        />

        <Route
          path={ROUTES.CREATE_PASSWORD}
          element={
            <PublicRoute component={CreatePassword} title="create Password" />
          }
        />
        <Route
          index
          path={ROUTES.HOME}
          element={<PrivateRoute component={Dashboard} title="Dashboard" />}
        />
        <Route
          index
          path={ROUTES.ORGANIZATION}
          element={
            <PrivateRoute component={Organization} title="Organization" />
          }
        />
        <Route
          index
          path={ROUTES.DOCUMENTATION_HUB}
          element={
            <PrivateRoute component={DocumentHub} title="Document Hub" />
          }
        />
        <Route
          index
          path={ROUTES.MY_QUOTE}
          element={
            <PrivateRoute
              component={MyQuote}
              title="Smart Quote"
              permission={PERMISSION.VIEW_SMART_QUOTE}
            />
          }
        />
        <Route
          index
          path={ROUTES.ADDRESS_DETAILS}
          element={
            <PrivateRoute
              component={AddressDetailsForm}
              title="Address Details"
              permission={PERMISSION.CREATE_SMART_QUOTE}
            />
          }
        />

        <Route
          path={ROUTES.DIMENSIONS}
          element={
            <PrivateRoute
              component={Dimensions}
              title="Dimensions"
              permission={PERMISSION.CREATE_SMART_QUOTE}
            />
          }
        />

        <Route
          path={ROUTES.QUOTE_DETAIL}
          element={
            <PrivateRoute
              component={QuoteDetail}
              title="Quote Detail"
              permission={PERMISSION.CREATE_SMART_QUOTE}
            />
          }
        />
        <Route
          path={ROUTES.SETTING}
          element={
            <PrivateRoute
              component={AccountSettings}
              title="Account Setting"
              permission={true}
            />
          }
        />
        <Route
          index
          path={ROUTES.ORDERS}
          element={
            <PrivateRoute component={Orders} title="Orders" permission={true} />
          }
        />
        <Route
          path={ROUTES.ORDER_DETAILS_VIEW}
          element={
            <PrivateRoute
              component={OrderView}
              title="Order View"
              permission={true}
            />
          }
        />
        <Route
          path={ROUTES.RADAR}
          element={
            <PrivateRoute
              component={Radar}
              title="Radar"
              // permission={PERMISSION.CARRIER_VIEW}
              permission={PERMISSION.RADAR}
            />
          }
        />
        <Route
          path={ROUTES.INTEGRATION}
          element={<PrivateRoute component={Integration} title="Integration" />}
        />
        <Route
          path={`${ROUTES.RADAR_ORDER_DETAIL}/:id?`}
          element={
            <PrivateRoute
              component={RadarOrderDetail}
              title="Order"
              // permission={PERMISSION.CARRIER_VIEW}
              permission={true}
            />
          }
        />
      </Route>

      <Route path={'/'}>
        <Route
          index
          path={ROUTES.MICROSOFT_CALLBACK}
          element={<MicrosoftCallback />}
        />
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </ReactRoutes>
  </BrowserRouter>
);

export default Routes;
