import { FileX01 } from '@untitled-ui/icons-react/build/cjs';
import React, { memo, useState } from 'react';
import InputText from 'src//components/InputText/InputText';
import CommonModal from 'src/components/CommonModal';

const CloseQuoteConfirm = ({
  handleClose,
  handleSuccess,
  isQuoteCloseLoading,
}: any) => {
  const [reason, setReason] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleConfirm = () => {
    if (!reason.trim()) {
      setError('Please provide a reason for not proceeding with the quote.');
    } else {
      setError('');
      handleSuccess({
        cancelReason: reason,
      });
    }
  };

  return (
    <CommonModal
      title="No Quote"
      titleDesc={`Add description for no quote`}
      headerIcon={<FileX01 />}
      size="max-w-[600px]"
      handleClose={() => handleClose()}
      primaryBtnOnClick={handleConfirm}
      secondaryBtnOnClick={() => handleClose()}
      secondaryBtnText="Cancel"
      primaryBtnText="Confirm"
      primaryBtnLoading={isQuoteCloseLoading}
      primaryBtnDisabled={isQuoteCloseLoading}
    >
      <form action="" className="p-5">
        <InputText
          asTextarea={'textarea'}
          rows={10}
          inputName="reason"
          label="Reason for no quote"
          labelClassName="mb-1.5 block"
          className="h-20"
          placeholder="Please provide the reason for not proceeding with the quote."
          value={reason}
          errorText={error}
          onChangeFunc={(event: any) => setReason(event?.target?.value)}
        />
      </form>
    </CommonModal>
  );
};

export default memo(CloseQuoteConfirm);
