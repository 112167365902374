import React from 'react';

const LineItemRow = () => (
  <>
    <td className="px-5 py-4 ">
      <span>2</span>
    </td>
    <td className="px-5 py-4 ">
      <span>Pallet</span>
    </td>
    <td className="px-5 py-4 ">
      <span>90 X 50 X 40</span>
    </td>

    <td className="px-5 py-4 ">
      <span>20 (lbs)</span>
    </td>
    <td className="px-5 py-4 ">
      <span>55KDLF351</span>
    </td>
  </>
);

export default LineItemRow;
