import { Check } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { Link } from 'react-router-dom';
import AccordionCmp from 'src/components/AccordionCmp';

const shipper = {
  address: '705 Rue Sainte-Catherine, Montréal, QC H3B 4G5, Canada',
  contactName: 'Olivia Rhye',
  phoneNumber: '703-701-9964',
  email: 'olivia@untitled.com',
};
const consignee = {
  address: 'St Lawrence-East Bayfront-The Islands, ON, Canada',
  contactName: 'Olivia Rhye',
  phoneNumber: '703-701-9964',
  email: 'olivia@untitled.com',
};

const AddressDetails = () => {
  const renderContactDetails = (contactData: any, type: string) => (
    <div>
      <h6 className="form_label block mb-1.5">{type}</h6>
      <div className="rounded-[10px] border border-utilityGray200 shadow-sm p-4 bg-white flex gap-x-6 flex-wrap">
        <div className="flex-1">
          <h6 className="text-grayLight600 text-xs font-normal mb-1">
            Address
          </h6>
          <p className={`text-textSecondary text-sm font-medium `}>
            {contactData?.address}
          </p>
        </div>
        <div className="flex-1">
          <h6 className="text-grayLight600 text-xs font-normal mb-1">
            Contact Name
          </h6>
          <p className={`text-textSecondary text-sm font-medium `}>
            {contactData?.contactName}
          </p>
        </div>
        <div className="flex-1">
          <h6 className="text-grayLight600 text-xs font-normal mb-1">
            Phone Number
          </h6>
          <div className="text-sm">
            <Link
              to={`tel:${contactData?.phoneNumber}`}
              className={`text-textSecondary text-sm font-medium `}
            >
              {contactData?.phoneNumber}
            </Link>
          </div>
        </div>
        <div className="flex-1">
          <h6 className="text-grayLight600 text-xs font-normal mb-1">
            Contact Email
          </h6>
          <div className="text-sm">
            <Link
              to={`mailto:${contactData?.email}`}
              className={`text-textSecondary text-sm font-medium`}
            >
              {contactData?.email}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  const renderAdditionalService = (additionalService: any, type: string) => (
    <>
      {additionalService?.length > 0 && (
        <div>
          <h6 className="form_label block mb-1.5">{type}</h6>
          <div className="rounded-xl border border-utilityGray200 bg-white shadow-sm px-4 py-1.5">
            <div className="flex flex-wrap -mx-5">
              {additionalService?.map((service: any, index: number) => (
                <div
                  className="flex gap-2 text-grayLight900 truncate px-5 py-2.5 w-1/4"
                  key={index}
                >
                  <Check className="w-4 h-4 text-primary flex-none" />
                  <h6 className="truncate text-xs"> {service?.name}</h6>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );

  const pickupAdditionalServices: any = [
    {
      name: 'Inside Pickup',
    },
    {
      name: 'Tailgate Pickup',
    },
    {
      name: 'Pickup Appointment Required',
    },
  ];

  const deliveryAdditionalServices: any = [
    {
      name: 'Inside Delivery',
    },
    {
      name: 'Tailgate Delivery',
    },
    {
      name: 'Construction Site Delivery',
    },
  ];

  return (
    <div>
      <h6 className="text-textSecondary text-xs font-medium mb-1 leading-[1.5]">
        Address Details
      </h6>
      <AccordionCmp
        title={
          <div className="flex items-start flex-1">
            <div className="w-1/2 pr-1">
              <h6 className="text-grayLight600 text-xs font-normal mb-1">
                Shipper Address
              </h6>
              <p
                className={`text-grayLight600 text-sm font-normal line-clamp-1 `}
              >
                Western Alliance HQ Montréal, QC H3B 4G5, Canada
              </p>
            </div>
            <div className="w-1/2 pl-1">
              <h6 className="text-grayLight600 text-xs font-normal mb-1">
                Consignee Address
              </h6>
              <p
                className={`text-grayLight600 text-sm font-normal line-clamp-1 `}
              >
                Bernier Group St Lawrence-East Bayfront-The Islands, ON, Canada
              </p>
            </div>
          </div>
        }
        className="!bg-gray25 !p-5 !rounded-xl !shadow-xs"
        contentClassName="!bg-gray25 flex flex-col gap-y-4"
        contentParentClass="!border-utilityGray200"
      >
        <>
          {renderContactDetails(shipper, 'Shipper')}

          {pickupAdditionalServices?.length > 0 &&
            renderAdditionalService(
              pickupAdditionalServices,
              'Pickup Additional Services'
            )}

          {renderContactDetails(consignee, 'Consignee')}

          {deliveryAdditionalServices?.length > 0 &&
            renderAdditionalService(
              deliveryAdditionalServices,
              'Delivery Additional Services'
            )}
        </>
      </AccordionCmp>
    </div>
  );
};

export default AddressDetails;
