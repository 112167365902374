import mapboxgl from 'mapbox-gl';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
// import StatusDotProfileCmp from 'src/components/StatusDotProfileCmp';
// import { getRadarOrderList } from 'src/services/OrderService';

import OrderDetailLeftSidebar from './OrderDetailLeftSidebar';

const initParams = {
  search: '',
  sortType: 'asc',
  sortField: 'createdAt',
  filter: 'booked',
  orderDate: moment().format('YYYY-MM-DD'),
};

const RadarOrderDetail = () => {
  mapboxgl.accessToken = window?.MAPBOX_ACCESSTOKEN!;
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const [orders, setOrders] = useState([]);
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [params, setParams] = useState(initParams);
  const [orderId, setOrderId] = useState<string>('');
  const [order, setOrder] = useState<any>(null);
  const [pickupOrderCount, setPickupOrderCount] = useState<number>(0);
  const [deliveredOrderCount, setDeliveredOrderCount] = useState<number>(0);
  const [inTransitOrderCount, setInTransitOrderCount] = useState<number>(0);
  const [orderMarkersPin, SetOrderMarkersPin] = useState<any[]>();

  const initializeMap = useCallback(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [-95, 55],
        zoom: 4,
        pitch: 0,
        bearing: 0,
        boxZoom: true,
        antialias: true,
      });

      mapRef.current.addControl(new mapboxgl.NavigationControl());
    }

    return () => {
      if (mapRef.current) mapRef.current.remove();
    };
  }, []);

  const geocodeAddress = async (
    address: string
  ): Promise<[number, number, string]> => {
    const query = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address
      )}.json?access_token=${mapboxgl.accessToken}`
    );
    const json = await query.json();

    if (!json.features || json.features.length === 0) {
      throw new Error(`Unable to geocode address: ${address}`);
    }

    const coordinates = json.features[0].center as [number, number];
    const placeId = json.features[0].id as string;

    return [coordinates[0], coordinates[1], placeId];
  };

  const removeCustomerMarkers = () => {
    if (mapRef.current?.getLayer('route-line')) {
      mapRef.current?.removeLayer('route-line');
    }

    if (mapRef.current?.getSource('route')) {
      mapRef.current?.removeSource('route');
    }

    orderMarkersPin?.forEach((marker: any) => {
      marker.remove();
    });
    SetOrderMarkersPin([]);
  };

  const setOrderMarker = async () => {
    let orderMarkers: mapboxgl.Marker[] = [];

    for (let i = 0; i < orders.length; i++) {
      const orderObj: any = orders[i];
      const cityCoords = await geocodeAddress(orderObj.shipperFullAddress);

      if (!cityCoords || cityCoords.length < 2) {
        console.warn(`Could not find coordinates for address`);
        continue;
      }

      // Create marker container
      const parentDiv = document.createElement('div');
      parentDiv.className = 'parent-marker';
      parentDiv.id = orderObj.id || '';

      // Create marker element
      const el = document.createElement('div');
      el.className = 'marker';
      el.id = orderObj.id || '';

      // Create customer image container
      const customerImageDiv = document.createElement('div');
      customerImageDiv.className = 'customer-image';
      customerImageDiv.id = orderObj.orderId;
      customerImageDiv.innerHTML = `
        <div class="bg-utilityGray100 rounded border border-utilityGray200 shadow-xs  mt-0.5 w-5 h-5 flex justify-center items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24" className="link_icon active_icon">
            <path fill="transparent" fillOpacity="0.01" d="M11.223 2.4318c.2836-.1576.4254-.2364.5756-.2673a1.0002 1.0002 0 0 1 .4029 0c.1501.031.2919.1097.5755.2673l7.4 4.111c.2996.1665.4493.2497.5584.368a1 1 0 0 1 .2141.364c.0505.1528.0505.324.0505.6667v8.1171c0 .3427 0 .514-.0505.6668a.999.999 0 0 1-.2141.3639c-.1091.1184-.2588.2016-.5584.368l-7.4 4.1111c-.2836.1576-.4254.2363-.5755.2672a.9977.9977 0 0 1-.4029 0c-.1502-.0309-.292-.1096-.5756-.2672l-7.4-4.1111c-.2995-.1664-.4493-.2496-.5584-.368a.999.999 0 0 1-.2141-.3639C3 16.5726 3 16.4013 3 16.0586v-8.117c0-.3427 0-.514.0505-.6668a1.0002 1.0002 0 0 1 .2141-.364c.109-.1183.2588-.2015.5584-.368l7.4-4.111Z"
            ></path>
            <path stroke="#444CE7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.5 7.2778 12 12.0001m0 0L3.5 7.2778m8.5 4.7223v9.5m9-5.4415v-8.117c0-.3427 0-.514-.0505-.6668a1 1 0 0 0-.2141-.364c-.1091-.1183-.2588-.2015-.5584-.368l-7.4-4.111c-.2836-.1576-.4254-.2364-.5755-.2673a1.0002 1.0002 0 0 0-.4029 0c-.1502.031-.292.1097-.5756.2673l-7.4 4.111c-.2995.1665-.4493.2497-.5584.368a1.0002 1.0002 0 0 0-.2141.364C3 7.4276 3 7.5988 3 7.9415v8.1171c0 .3427 0 .514.0505.6668a.999.999 0 0 0 .2141.3639c.109.1184.2588.2016.5584.368l7.4 4.1111c.2836.1576.4254.2363.5756.2672a.9977.9977 0 0 0 .4029 0c.1501-.0309.2919-.1096.5755-.2672l7.4-4.1111c.2996-.1664.4493-.2496.5584-.368a.999.999 0 0 0 .2141-.3639C21 16.5726 21 16.4013 21 16.0586ZM16.5 9.5l-9-5"
            ></path>
          </svg>
        </div>`;

      parentDiv.appendChild(customerImageDiv);

      // Create and configure the Mapbox marker
      const marker = new mapboxgl.Marker(parentDiv)
        .setLngLat([cityCoords[0], cityCoords[1]])
        .addTo(mapRef.current!);

      // Add click event listener to the marker
      marker.getElement().addEventListener('click', () => {
        console.log('Clicked marker:', orderObj.orderId);
        setOrderId(orderObj.orderTableId);
      });

      orderMarkers.push(marker);

      // Center the map and adjust zoom after placing the last marker
      if (i === orders.length - 1) {
        mapRef?.current?.setCenter([cityCoords[0], cityCoords[1]]);
        mapRef?.current?.setZoom(4);
      }
    }

    SetOrderMarkersPin(orderMarkers);
  };

  const getOrderLegCoordinates = async (orderLegs: any) => {
    const coordinates: any = [];

    for (let i = 0; i < orderLegs?.length; i++) {
      const orderLeg: any = orderLegs[i];
      const fromAddressCoords = await geocodeAddress(
        orderLeg.shipperFullAddress
      );

      if (!fromAddressCoords || fromAddressCoords.length < 2) {
        console.warn(`Could not find coordinates for address`);

        return coordinates;
      }
      coordinates.push([fromAddressCoords[0], fromAddressCoords[1]]);

      if (i === orderLegs?.length - 1) {
        const toAddressCoords = await geocodeAddress(
          orderLeg.consigneeFullAddress
        );

        if (!toAddressCoords || toAddressCoords.length < 2) {
          console.warn(`Could not find coordinates for address`);

          return coordinates;
        }
        coordinates.push([toAddressCoords[0], toAddressCoords[1]]);
      }
    }

    return coordinates;
  };

  // Function to calculate the angle between two points
  // const calculateAngle = (
  //   pointALat: number,
  //   pointALng: number,
  //   pointBLat: number,
  //   pointBLng: number
  // ) => {
  //   const angle =
  //     (Math.atan2(pointBLat - pointALat, pointBLng - pointALng) * 180) /
  //     Math.PI;
  //   console.log('angle', angle);

  //   return angle;
  // };

  const setOrderDetailMarker = async () => {
    const coordinates: any = await getOrderLegCoordinates(order?.orderLegs);

    if (mapRef.current?.getLayer('route-line')) {
      mapRef.current?.removeLayer('route-line');
    }

    if (mapRef.current?.getSource('route')) {
      mapRef.current?.removeSource('route');
    }

    mapRef.current?.addSource('route', {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: coordinates,
        },
        properties: {},
      },
    });

    mapRef.current?.addLayer({
      id: 'route-line',
      type: 'line',
      source: 'route',
      layout: {},
      paint: {
        'line-color': '#4264fb',
        'line-width': 4,
      },
    });

    let orderMarkers: mapboxgl.Marker[] = [];
    console.log('coordinates', coordinates);

    coordinates.forEach((coord: any, index: number) => {
      const parentDiv = document.createElement('div');
      parentDiv.className = 'parent-marker';
      parentDiv.id = `${index}` || '';

      if (index === 0) {
        // Create customer image container
        const arrowElement = document.createElement('div');
        arrowElement.className = 'arrow-marker';
        arrowElement.innerHTML = `<svg width="40" height="40" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.5" cx="58.5848" cy="58.5848" r="47.1" transform="rotate(-75.3409 58.5848 58.5848)" stroke="#079455" stroke-width="1.8"/>
            <path d="M50.4894 29.0752C66.7871 24.6041 83.6236 34.1915 88.0946 50.4893C92.5657 66.7871 82.9783 83.6235 66.6805 88.0946C50.3828 92.5656 33.5463 82.9782 29.0753 66.6804C24.6042 50.3827 34.1916 33.5462 50.4894 29.0752Z" fill="#079455"/>
            <path d="M50.4894 29.0752C66.7871 24.6041 83.6236 34.1915 88.0946 50.4893C92.5657 66.7871 82.9783 83.6235 66.6805 88.0946C50.3828 92.5656 33.5463 82.9782 29.0753 66.6804C24.6042 50.3827 34.1916 33.5462 50.4894 29.0752Z" stroke="white" stroke-width="1.2"/>
            <path d="M41.2494 51.7883C40.6049 50.995 40.2827 50.5984 40.2939 50.2976C40.3037 50.0369 40.4399 49.7974 40.659 49.6557C40.9117 49.4923 41.4173 49.5664 42.4285 49.7147L71.1267 53.9224C72.0395 54.0562 72.496 54.1232 72.6892 54.3388C72.857 54.526 72.927 54.7812 72.8783 55.0279C72.8221 55.3119 72.4637 55.6024 71.7469 56.1833L49.2131 74.4457C48.4191 75.0893 48.0221 75.411 47.7214 75.3994C47.4607 75.3894 47.2213 75.2529 47.0799 75.0336C46.9168 74.7807 46.9915 74.2752 47.141 73.2641L48.8375 61.7872C48.8678 61.582 48.883 61.4793 48.8728 61.3799C48.8638 61.2917 48.8402 61.2056 48.803 61.1252C48.761 61.0344 48.6955 60.9539 48.5647 60.7929L41.2494 51.7883Z" stroke="white" stroke-width="3.2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>`;
        parentDiv.appendChild(arrowElement);

        // if (coordinates.length > 1) {
        //   const angle = calculateAngle(
        //     coordinates[0][0],
        //     coordinates[0][1],
        //     coordinates[1][0],
        //     coordinates[1][1]
        //   );
        //   arrowElement.style.transform = `rotate(${angle}deg)`;
        // }
      }

      const marker = new mapboxgl.Marker(parentDiv)
        .setLngLat(coord)
        .addTo(mapRef.current!);
      orderMarkers.push(marker);

      // Center the map and adjust zoom after placing the last marker
      if (index === coordinates.length - 1) {
        mapRef?.current?.setCenter([coord[0], coord[1]]);
        mapRef?.current?.setZoom(4);
      }
    });

    SetOrderMarkersPin(orderMarkers);
  };

  useEffect(() => {
    setOrder(0);
    removeCustomerMarkers();
    setTimeout(() => {
      if (order) {
        setOrderDetailMarker();
      } else {
        setOrderMarker();
      }
    }, 100);
  }, [orders, order]);

  useEffect(() => {
    let activeElement = document.getElementById(orderId);

    if (activeElement) {
      activeElement.className = 'active-order';
    }
  }, [orderId]);

  const getOrderList = () => {
    setIsOrdersLoading(false);
    setOrders([]);
    setPickupOrderCount(0);
    setDeliveredOrderCount(0);
    setInTransitOrderCount(0);
  };

  useEffect(() => {
    getOrderList();
  }, [params]);

  useEffect(() => {
    initializeMap();
  }, []);

  return (
    <PageSectionLayout
      header={
        <Header
          title="Radar"
          desc="Stay updated on your orders from pickup to their final delivery."
        />
      }
      contentClassName="h-[calc(100vh_-_200px)] !pl-0"
    >
      <div className="w-full flex h-full">
        <div className="min-w-[540px] w-[53%] flex flex-col gap-y-3 [&>div]:pl-5 [&>div]:pr-5 px-px border-r border-utilityGray200 mr-5">
          <OrderDetailLeftSidebar
            orders={orders}
            isOrdersLoading={isOrdersLoading}
            setParams={setParams}
            params={params}
            setOrderId={setOrderId}
            pickupOrderCount={pickupOrderCount}
            deliveredOrderCount={deliveredOrderCount}
            inTransitOrderCount={inTransitOrderCount}
          />
        </div>

        <div className=" h-full flex-1">
          <div
            id="map"
            className="border border-utilityGray200 rounded-lg overflow-hidden"
            ref={mapContainerRef}
            style={{ width: '100%', height: '100%' }}
          ></div>
        </div>
      </div>
    </PageSectionLayout>
  );
};
export default RadarOrderDetail;
