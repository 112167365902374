import { ImagePlus } from '@untitled-ui/icons-react/build/cjs';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';

import AutoCompleteGoogle from '../../components/AutoCompleteGoogle/AutoCompleteGoogle';
import ButtonCmp from '../../components/ButtonCmp';
import CropperModal from '../../components/CropperModal/CropperModal';
import ErrorMsg from '../../components/errorMsg';
import InputText from '../../components/InputText/InputText';
import PageSectionLayout from '../../components/PageSectionLayout';
import Header from '../../components/PageSectionLayout/Header/Header';
// import PhoneInput from '../../components/PhoneInput/PhoneInput';
import PhoneInputCmp from '../../components/PhoneInputCmp/PhoneInputCmp';
import { scriptId } from '../../constants/common';
import { AuthContext } from '../../context/AuthContext';
import { BasicContext } from '../../context/BasicContext';
import { getDetail } from '../../services/AuthService';
import { organizationUpdate } from '../../services/OrganizationService';
import { convertBase64ToFile } from '../../utils/CommonFunctions';
import WalToast from '../../utils/WalToast';

import OrganizationMembers from './components/OrganizationMembers';

const Organization = () => {
  const { googleKey } = useContext(BasicContext);
  const { currentUser } = useContext(AuthContext);
  const [address, setAddress] = useState<any>(null);
  const [isOrgDetailsSaveLoading, setIsOrgDetailsSaveLoading] =
    useState<any>(false);
  const [fullAddress, setFullAddress] = useState<any>(null);
  const [upload, setUpload] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState<any>(false);
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  // const [value, setValue] = useState();

  const [validForm, setValidForm] = useState<any>({
    name: true,
    company_no: true,
    company_address: true,
    company_no_valid: true,
    company_logo: true,
    company_logo_size_valid: true,
  });

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  window.initMap = () => {
    setStatus(true);
  };

  const [companyLogo, setCompanyLogo] = useState<any>(null);
  const [defaultCompanyLogo, setDefaultCompanyLogo] = useState<any>(null);
  const [formData, setFormData] = useState<any>({
    name: '',
    company_no: '',
  });

  useEffect(() => {
    getDetail(currentUser?.customerId?.id)
      .then((response) => {
        setFormData({
          ...formData,
          name: response?.data?.name,
          company_no: response?.data.companyPhoneNumber,
        });
        setFullAddress(response?.data?.company_address);

        const defaultCompanyImage = response?.data?.image
          ? `${response?.data.imageUrl}${response?.data.image}`
          : null;
        // setCompanyLogo(defaultCompanyImage);
        setDefaultCompanyLogo(defaultCompanyImage);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [currentUser]);

  // const handleNumberChange = (
  //   country: any,
  //   phone: string,
  //   code: string,
  //   isValid: boolean
  // ) => {
  //   setFormData({
  //     ...formData,
  //     company_no: phone === '' || phone === null ? '' : `+${code}${phone}`,
  //     phone_country_code: country,
  //   });

  //   if ((phone !== '' || phone !== null) && isValid) {
  //     setValidForm({
  //       ...validForm,
  //       company_no: true,
  //       company_no_valid: isValid,
  //     });
  //   } else {
  //     setValidForm({
  //       ...validForm,
  //       company_no_valid: isValid,
  //     });
  //   }
  // };

  // const handleNumberChange = (
  //   country: any,
  //   phone: string,
  //   code: string,
  //   isValid: boolean
  // ) => {
  //   setFormData({
  //     ...formData,
  //     company_no: phone === '' || phone === null ? '' : `+${code}${phone}`,
  //     ['phone_country_code']: country,
  //   });

  //   if ((phone !== '' || phone !== null) && isValid) {
  //     setValidForm({
  //       ...validForm,
  //       company_no: true,
  //       company_no_valid: isValid,
  //     });
  //   } else {
  //     setValidForm({
  //       ...validForm,
  //       company_no_valid: isValid,
  //     });
  //   }
  // };
  const handleNumberChange = (phone: any, isValid: any) => {
    setFormData({
      ...formData,
      company_no: phone === '' || phone === null ? '' : `${phone}`,
    });
    setValidForm({
      ...validForm,
      company_no: true,
      company_no_valid: isValid,
    });
  };

  const validateStep = () => {
    let isValid = true;
    const newValidForm = { ...validForm };

    if (!formData?.name || formData?.name?.trim() === '') {
      newValidForm.name = false;
      isValid = false;
    } else {
      newValidForm.name = true;
    }

    if (fullAddress === null || fullAddress === '') {
      newValidForm.company_address = false;
      isValid = false;
    } else {
      newValidForm.company_address = true;
    }

    if ((companyLogo === null || companyLogo === '') && !defaultCompanyLogo) {
      newValidForm.company_logo = false;
      isValid = false;
    } else {
      newValidForm.company_logo = true;
    }
    console.log('object test test', formData.company_no);

    if (formData.company_no === null || formData.company_no === '') {
      newValidForm.company_no = false;
      console.log('object test test');
      isValid = false;
    } else {
      newValidForm.company_no = true;
    }
    setValidForm(newValidForm);

    return isValid;
  };

  const hasAnyFormValueFalse = (object: any) => {
    for (let key in object) {
      if (key === 'company_logo') {
        continue;
      }

      if (!object[key]) {
        return false;
      }
    }

    return true;
  };

  const convertToFormDataWithImages = async (formDataStructure: any) => {
    const formValueData = new FormData();

    for (const step in formDataStructure) {
      if (step === 'company_logo') {
        continue;
      }
      formValueData.append(`${step}`, formDataStructure[step]);
    }

    return formValueData;
  };

  const handleSaveDetails = async () => {
    setIsFormSubmitted(true);
    if (!validateStep() || !hasAnyFormValueFalse(validForm)) return;
    setIsFormSubmitted(false);
    const payload = await convertToFormDataWithImages(formData);
    payload.append(`address`, JSON.stringify(address) as any);
    payload.append(`fullAddress`, fullAddress as any);
    setIsOrgDetailsSaveLoading(true);

    if (companyLogo) {
      const uploadImage = convertBase64ToFile(companyLogo);
      payload.append(`company_logo`, uploadImage?.convertedFile as any);
    }

    organizationUpdate(payload)
      .then((response) => {
        if (response?.status) {
          WalToast.success(
            'Organization Update',
            'Organization detail updated successfully.'
          );
        }
        setIsOrgDetailsSaveLoading(false);
      })
      .catch((error) => {
        setIsOrgDetailsSaveLoading(false);
        console.log('error', error);
        WalToast.error(
          'ERROR : Organization Update',
          'Something went wrong with update organization detail. Please try again.'
        );
      });
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    // setCompanyLogo(null);

    if (file?.type?.match('image')) {
      setValidForm({ ...validForm, company_logo: true });
    } else {
      setValidForm({ ...validForm, company_logo: false });
    }

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();

        img.onload = () => {
          if (img.width > 800 || img.height > 800) {
            setValidForm({
              ...validForm,
              company_logo: true,
              company_logo_size_valid: false,
            });
          } else {
            setValidForm({ ...validForm, company_logo_size_valid: true });
            setUpload(true);
            setCompanyLogo(img.src);
          }
        };
        img.src = e.target!.result as string;
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/svg+xml': ['.svg'],
    },
  });

  const handleInputChange = (event: any) => {
    setFormData({ ...formData, [event?.target?.name]: event?.target?.value });

    // Live validation for the current field
    setValidForm({
      ...validForm,
      [event?.target?.name]: event?.target?.value.trim() !== '',
    });
  };

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title="Team Members"
            desc="Overview and Management of Your Team Members"
            isShowNotificationBox
            rightSideContent={
              <ButtonCmp
                className="btn-outline-primary"
                loading={isOrgDetailsSaveLoading}
                onClick={handleSaveDetails}
              >
                Save
              </ButtonCmp>
            }
          />
        }
      >
        <div className="flex flex-wrap gap-y-4 last:border-b-0 last:pb-0 pb-[32px] border-b border-utilityGray200">
          <div className="xl:w-[30%] w-full xl:pr-11">
            <h6 className="text-textSecondary text-sm font-semibold">
              Organization
            </h6>
            <p className="text-grayLight600 text-sm font-normal">
              Here you can change your organization photo
            </p>
          </div>
          <div className="xl:w-[70%] w-full flex flex-col gap-y-[30px]">
            {/* UPLOAD FILE COMPONENT STARTS HERE */}
            <div className="max-w-[444px]">
              <label className="form_label">Organization Logo</label>

              <label
                className="flex gap-x-2 cursor-pointer pointer-events-none"
                htmlFor="fileSelect"
                {...getRootProps()}
              >
                <div className="w-14 h-14 border border-black/[0.08] rounded-lg object-cover flex justify-center items-center bg-utilityGray100 text-gray500 flex-none pointer-events-auto">
                  {companyLogo || defaultCompanyLogo ? (
                    <img
                      className="h-full w-full object-cover"
                      src={companyLogo || defaultCompanyLogo}
                      alt={formData.name}
                      title={formData.name}
                    />
                  ) : (
                    <ImagePlus className="text-grayLight600" />
                  )}
                </div>

                <div className="flex-1 rounded-xl border border-borderPrimary bg-white p-3 pointer-events-auto">
                  <p className="text-xs text-grayLight600 text-center mx-auto max-w-[190px]">
                    <span className="text-primary font-semibold">
                      Click to upload
                    </span>
                    &nbsp;or drag and drop PNG or JPG
                  </p>
                </div>
              </label>
              {!validForm.company_logo && (
                <ErrorMsg errorText={'Required the company logo.'} />
              )}
              {!validForm.company_logo_size_valid && (
                <ErrorMsg
                  errorText={
                    'Image dimensions must be less than or equal to 800 x 800'
                  }
                />
              )}
              <input
                type="file"
                name="image"
                id="fileSelect11"
                className="hidden"
                {...getInputProps()}
              />
            </div>
            {/* UPLOAD FILE COMPONENT ENDS HERE */}
            <div className="flex flex-wrap gap-3">
              <InputText
                parentClassName="flex-1"
                inputName="name"
                label="Organization Name"
                placeholder="Enter organization name"
                value={formData?.name}
                onChangeFunc={handleInputChange}
                errorText={
                  !validForm.name ? 'Organization Name is required' : null
                }
              />
              {status && (
                <AutoCompleteGoogle
                  mapParentClass="flex-1"
                  label="Organization Address"
                  inputName="company_address"
                  placeholder="Enter company address"
                  className={`form_control truncate  ${
                    !validForm.company_address && isFormSubmitted
                      ? ' border-red-600'
                      : ''
                  }`}
                  // label="Address"
                  labelClassName="mb-1.5 block"
                  onChangeFunc={(e: any) => {
                    if (e) {
                      setFullAddress(e.fullAddress);
                      setAddress(e);
                    }
                  }}
                  onAddressInputChange={(e: any) => {
                    setFullAddress(e);
                    console.log(e?.fullAddress?.trim() !== '');
                    setValidForm({
                      ...validForm,
                      company_address:
                        e?.fullAddress?.trim() === '' ||
                        e?.fullAddress === null ||
                        e?.fullAddress === undefined
                          ? false
                          : true,
                    });
                  }}
                  errorText={
                    !validForm.company_address && isFormSubmitted
                      ? 'Company Address is required'
                      : null
                  }
                  value={fullAddress}
                />
              )}

              <div className="flex-1">
                <label className="form_label ">Company Number</label>
                {/* <PhoneInput
                    onNumberChange={handleNumberChange}
                    name="company_no"
                    errors={
                      !validForm.company_no
                        ? 'Phone number is required'
                        : !validForm.company_no_valid && isFormSubmitted
                        ? 'Invalid Number'
                        : null
                    }
                    value={formData?.company_no || ''}
                  /> */}
                {/* <PhoneInput
                    specialLabel={''}
                    enableSearch={true}
                    country="CA"
                    inputClass={`wal-phone-input form_control ${
                      !validForm.company_no
                        ? '!border-red-600'
                        : !validForm.company_no_valid && isFormSubmitted
                        ? '!border-red-600'
                        : null
                    }`}
                    buttonClass={`wal-phone-input-button ${
                      !validForm.company_no
                        ? '!border-red-600'
                        : !validForm.company_no_valid && isFormSubmitted
                        ? '!border-red-600'
                        : null
                    }`}
                    dropdownClass={`wal-phone-input-dropdown ${
                      !validForm.company_no
                        ? '!border-red-600'
                        : !validForm.company_no_valid && isFormSubmitted
                        ? '!border-red-600'
                        : null
                    }`}
                    value={formData?.company_no || ''}
                    onChange={handleNumberChange as any}
                  /> */}

                <PhoneInputCmp
                  phoneInputWrapClass=""
                  value={formData.company_no}
                  onChange={handleNumberChange}
                  isFormSubmitted={isFormSubmitted}
                  errorMessage={
                    !validForm.company_no
                      ? 'Phone number is required.'
                      : !validForm.company_no_valid
                      ? 'Invalid phone number'
                      : ''
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <OrganizationMembers />
        {upload && (
          <CropperModal
            modalTitle={`Organization logo`}
            modalDesc={`Crop Organization Logo`}
            // imageUpload={imageUpload}
            imageUrl={companyLogo}
            setUpload={setUpload}
            defaultImage={''}
            setCropData={setCompanyLogo}
            isCropType={false}
            defaultCropType="Square"
            // isSetToModalClose={false}
            // primaryBtnLoading={isFileUploadLoading}
          />
        )}
      </PageSectionLayout>
    </>
  );
};

export default Organization;
