import moment from 'moment';
import { useContext } from 'react';
import { TIMEZONE, USER_ROLE } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import { BadgeType } from 'src/interface/common';
import { downloadGCSPdf } from 'src/services/QuoteService';

import defaultImage from '../assets/img/default-image.jpg';
import { MEASUREMENT } from '../constants/common';
import { AuthContext } from '../context/AuthContext';

export const GetAppearance = () => {
  let { appearance } = useContext(BasicContext);

  return appearance;
};

export const preventEnterSubmit = (keyEvent: any) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
};

export const getSelectBoxOptions = (
  data: Array<any>,
  value: string = 'id',
  label: string = 'name',
  isImage: boolean = false,
  imageUrl: string = 'imageUrl',
  image: string = 'image',
  members: string = 'members',
  secondLabel: any = null,
  isSort: boolean = false,
  defaultImg: any = null
) => {
  if (!Array.isArray(data)) {
    data = [data];
  }
  if (isSort) data.sort((a, b) => a[label].localeCompare(b[label]));

  return data.map((dataItem: any) => ({
    value: dataItem[value],
    label: `${dataItem[label]} ${secondLabel ? dataItem[secondLabel] : ''}`,
    members: dataItem[members],
    image: isImage
      ? dataItem[image]
        ? dataItem[imageUrl] + dataItem[image]
        : defaultImg
      : defaultImg,
    isImage: isImage,
  }));
};

export const getShortName = (name: string = '') => {
  const shortName = name
    .split(' ')
    .map((word) => word.charAt(0))
    .slice(0, 2)
    .join('');

  return shortName;
};

export const onError = (event: any) => {
  event.target.src = defaultImage;
};

export const useRolePermission = () => {
  const { currentUser } = useContext(AuthContext);

  const hasRoleV2 = (roleName: string) => {
    if (
      !currentUser?.role?.name ||
      !Object.values(USER_ROLE).includes(roleName)
    ) {
      return false;
    }

    if (roleName === USER_ROLE.USER) {
      return [
        USER_ROLE.SALES,
        USER_ROLE.OPERATIONS,
        USER_ROLE.CLAIMS,
        USER_ROLE.FINANCE,
      ].includes(currentUser?.role?.name);
    }

    return currentUser?.role?.name === roleName;
  };

  const hasPermissionV2 = (permission: string | string[]) => {
    const permissionsNameList = currentUser?.permissions?.map(
      (curPermission: any) => curPermission?.name
    );

    if (Array.isArray(permission)) {
      return permission.every((perm) => permissionsNameList.includes(perm));
    }

    return permissionsNameList?.includes(permission);
  };

  // old one
  const hasRole = (roleName: string) => (roleName ? true : false);

  // const permissions = currentUser.role.permissions.split(',');
  // return permissions.includes(permission);
  // TODO : implement role and permission management
  const hasPermission = (permission: string) => (permission ? true : false);

  return { hasRoleV2, hasRole, hasPermission, hasPermissionV2 };
};

export const stringWithDot = (stringToBeCut: string, length = 7) =>
  stringToBeCut.length > length
    ? `${stringToBeCut.substring(0, length - 3)}...`
    : stringToBeCut;

export const capitalizeFirstLetterAndDash = (str: string) => {
  const words = str?.split('-');
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const result = capitalizedWords.join('-');

  return result;
};

export const capitalizeFirstLetterSpace = (str: string) => {
  const words = str?.split(' ');
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const result = capitalizedWords.join(' ');

  return result;
};

export const fileToBase64 = (file: File): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    if (!file) {
      reject(new Error('No file provided'));

      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64String = reader.result?.toString()?.split(',')[1];

      if (base64String) {
        resolve(base64String);
      } else {
        reject(new Error('Failed to convert file to base64'));
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });

export const getFormattedNumber = (
  number: any,
  isDecimal: boolean = true,
  isDisplayDollar = false,
  isParseInt = false
) => {
  var options: Intl.NumberFormatOptions = {};

  if (isDecimal) {
    options = Number.isInteger(number)
      ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
      : { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  }

  if (isParseInt) {
    number = parseInt(number);
  }
  const formattedNumber = new Intl.NumberFormat('en-US', options).format(
    number
  );

  if (isDisplayDollar) {
    if (number >= 0) {
      return formattedNumber;
    } else {
      return `-$${formattedNumber.replace('-', '')}`;
    }
  }

  return formattedNumber;
};

export const formatBigNumberToAbbreviation = (num: number) => {
  if (Math.abs(num) >= 1.0e9) {
    return `${(num / 1.0e9).toFixed(2).replace(/\.?0+$/, '')}B`;
  } else if (Math.abs(num) >= 1.0e6) {
    return `${(num / 1.0e6).toFixed(2).replace(/\.?0+$/, '')}M`;
  } else if (Math.abs(num) >= 1.0e3) {
    return `${(num / 1.0e3).toFixed(1).replace(/\.?0+$/, '')}K`;
  } else {
    return num.toString();
  }
};

export const DateFormat = (val: any) => {
  let formattedDate = '';

  if (val) {
    const date = new Date(val);
    formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
  }

  return formattedDate;
};

export const getDateRange = (range: any, allTimeStartDate?: any) => {
  switch (range) {
    case 'last_7_days':
      return {
        start: moment().subtract(6, 'days').toDate(),
        end: moment().toDate(),
      };
    case 'last_30_days':
      return {
        start: moment().subtract(29, 'days').toDate(),
        end: moment().toDate(),
      };
    case 'last_90_days':
      return {
        start: moment().subtract(89, 'days').toDate(),
        end: moment().toDate(),
      };
    case 'last_year':
      return {
        start: moment().subtract(1, 'year').toDate(),
        end: moment().toDate(),
      };
    case 'monthly':
      return {
        start: moment().startOf('month').toDate(),
        end: moment().endOf('month').toDate(),
      };
    case 'weekly':
      return {
        start: moment().isoWeekday(0).startOf('week').toDate(),
        end: moment().isoWeekday(0).endOf('week').toDate(),
      };
    case 'quarterly':
      return {
        start: moment(moment().startOf('quarter'), 'YYYY-MM-DD').toDate(),
        end: moment(moment().endOf('quarter'), 'YYYY-MM-DD').toDate(),
      };
    case 'yearly':
      return {
        start: moment(moment().startOf('year'), 'YYYY-MM-DD').toDate(),
        end: moment(moment().endOf('year'), 'YYYY-MM-DD').toDate(),
      };
    case 'daily':
      return {
        start: moment().toDate(),
        end: moment().toDate(),
      };
    case 'all_time':
      return {
        start: moment(allTimeStartDate).toDate(),
        end: moment().toDate(),
      };
    // Add more cases for Weekly, Monthly, Quarterly, Yearly as needed
    default:
      return {};
  }
};

export const dateToDayAndTime = (dateString: any) => {
  const date = new Date(dateString);

  // const options = { weekday: "long", hour: "2-digit", minute: "2-digit" };
  return date.toLocaleString('en-US', {
    weekday: 'long',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const calculateNiceMaximum = (maxDataValue: any, tickAmount: any) => {
  let rawStep = maxDataValue / tickAmount;
  let magnitude = Math.pow(10, Math.floor(Math.log10(rawStep)));
  let normalizedStep = rawStep / magnitude;

  let adjustedStep;

  if (normalizedStep <= 1) {
    adjustedStep = 1;
  } else if (normalizedStep <= 2) {
    adjustedStep = 2;
  } else if (normalizedStep <= 5) {
    adjustedStep = 5;
  } else {
    adjustedStep = 10;
  }

  let customMax =
    Math.ceil(maxDataValue / (magnitude * adjustedStep)) *
    (magnitude * adjustedStep);
  customMax = Math.max(customMax, maxDataValue);

  return customMax;
};

export const TimeConverter = (inputDateTime: any) => {
  let appearance = GetAppearance();
  const momentDateTime = moment
    .utc(inputDateTime)
    .tz(appearance?.timezone ?? TIMEZONE);

  return momentDateTime.format('dddd hh:mm A');
};

export const getDateWithSuffixFormat = (date: string) => {
  const day = moment.utc(date, 'MMM Do YYYY').date();

  const suffixRegex = /(\d+)(st|nd|rd|th)/;

  const formattedDate = date.replace(suffixRegex, (match, p1, p2) => {
    if (day >= 11 && day <= 13) {
      return `${p1}<sup>th</sup>`;
    } else {
      switch (p2) {
        case 'st':
          return `${p1}<sup>st</sup>`;
        case 'nd':
          return `${p1}<sup>nd</sup>`;
        case 'rd':
          return `${p1}<sup>rd</sup>`;
        default:
          return `${p1}<sup>th</sup>`;
      }
    }
  });

  return formattedDate;
};

export const getFormattedDate = (
  date: any,
  format = 'MMMM Do, YYYY',
  isTimeDisplay = false,
  convertTimezone = false
) => {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // const momentDate = moment.utc(date).tz(browserTimezone);
  let momentDate = moment(date);
  let appearance = GetAppearance();

  if (convertTimezone) {
    momentDate = moment.utc(date).tz(appearance?.timezone ?? browserTimezone);
  }
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');
  const tomorrow = moment().add(1, 'days').startOf('day');

  if (momentDate.isSame(today, 'day')) {
    if (!isTimeDisplay) return `Today `;
    else return `Today, ${momentDate.format('hh:mm A')}`;
  } else if (momentDate.isSame(yesterday, 'day')) {
    if (!isTimeDisplay) return `Yesterday`;
    else return `Yesterday, ${momentDate.format('hh:mm A')}`;
  } else if (momentDate.isSame(tomorrow, 'day')) {
    if (!isTimeDisplay) return `Tomorrow`;
    else return `Tomorrow, ${momentDate.format('hh:mm A')}`;
  } else {
    return momentDate.format(format);
  }
};

export const getLabelByValue = (value: any, arr?: any) => {
  const obj: any = arr ?? MEASUREMENT;

  for (const key in obj) {
    if (obj[key].value === value) {
      return obj[key].label;
    }
  }

  return null;
};

export const formatAddress = (address: string | false) => {
  if (typeof address === 'string') {
    address = address.replace(/Ã©/g, 'é');
    address = address.replace(/Ã‰/g, 'É');
    address = address.replace(/%/g, '');
    address = address.replace(/Ã /g, 'à');
    address = address.replace(/Ã¨/g, 'è');
    address = address.replace(/Ã¬/g, 'ì');
    address = address.replace(/Ã²/g, 'ò');
    address = address.replace(/Ã¹/g, 'ù');
    address = address.replace(/Ã‚/g, 'Â');
    address = address.replace(/Ã‹/g, 'Ë');
    address = address.replace(/ÃŠ/g, 'Ê');
    address = address.replace(/Ã‹/g, 'Ë');
    address = address.replace(/Ã¯/g, 'ï');
    address = address.replace(/Ã´/g, 'ô');
    address = address.replace(/Ã¶/g, 'ö');
    address = address.replace(/Ã»/g, 'û');
    address = address.replace(/Ã¼/g, 'ü');
    address = address.replace(/Ã§Ã /g, 'ç');
    address = address.replace(/ÃŸ/g, 'ß');
    address = address.replace(/Ã¦/g, 'æ');
    address = address.replace(/Ã¸/g, 'ø');
    address = address.replace(/Ã¥/g, 'å');
    address = address.replace(/Ã¢/g, 'â');
    address = address.replace(/Ã‘/g, 'Ñ');
    address = address.replace(/Ã¡/g, 'á');
    address = address.replace(/Ã³/g, 'ó');

    return address;
  }

  return address;
};

export const capitalizeFirstLetter = (str: string) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return str;
};

export const fetchJsFromCDN = (src: any, externals: any = []) =>
  new Promise((resolve, reject) => {
    document.getElementById('ioScript')?.remove();
    const script = document.createElement('script');
    script.setAttribute('src', src);
    script.setAttribute('id', 'ioScript');
    script.addEventListener('load', () => {
      resolve(
        externals.map((key: any) => {
          const ext = window[key];

          if (typeof ext === 'undefined') {
            console.warn(`No external named '${key}' in window`);
          }

          return ext;
        })
      );
    });
    script.addEventListener('error', reject);
    document.body.appendChild(script);
  });

export const isValidJSON = (str: any) => {
  try {
    JSON.parse(str);

    return true;
  } catch (e) {
    return false;
  }
};

export const customFromNow = (date: any) => {
  const dateTime = moment.utc(date);
  const duration = moment.duration(moment().diff(moment(dateTime)));

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let result = '';

  if (years > 0) {
    result = `${years}y ago`;
  } else if (months > 0) {
    result = `${months}mo ago`;
  } else if (days > 0) {
    result = `${days}d ago`;
  } else if (hours > 0) {
    result = `${hours}h ago`;
  } else if (minutes > 0) {
    result = `${minutes}m ago`;
  } else {
    result = `${seconds}s ago`;
  }

  return result.trim();
};

export const getTimeDifference = (givenDate: any) => {
  const now = new Date() as any;
  const pastDate = new Date(givenDate) as any;
  const differenceInMs = now - pastDate;

  const differenceInSeconds = Math.floor(differenceInMs / 1000);
  const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  const differenceInHours = Math.floor(differenceInMinutes / 60);

  const years = Math.floor(differenceInHours / (24 * 365));
  const months = Math.floor(differenceInHours / (24 * 30)) % 12;
  const days = Math.floor(differenceInHours / 24) % 30;
  const hours = differenceInHours % 24;
  const minutes = differenceInMinutes % 60;
  const seconds = differenceInSeconds % 60;

  return { years, months, days, hours, differenceInHours, minutes, seconds };
};

function formatTimeUnit(unit: any) {
  return unit < 10 ? `0${unit}` : unit;
}

export const displayTimeDifference = (givenDate: any): any => {
  const { years, months, days, differenceInHours, minutes, seconds } =
    getTimeDifference(givenDate);

  let displayTime;

  if (differenceInHours < 100) {
    displayTime = `${formatTimeUnit(differenceInHours)}:${formatTimeUnit(
      minutes
    )}:${formatTimeUnit(seconds)}`;
  } else if (days > 0) {
    displayTime = `${days}d ago`;
  } else if (months > 0) {
    displayTime = `${months}Mo ago`;
  } else if (years > 0) {
    displayTime = `${years}y ago`;
  }

  return displayTime;
};

export const getTimeDifferenceWithUTC = (givenDate: any) => {
  const now = moment.utc();
  const pastDate = moment.utc(givenDate);
  const duration = moment.duration(now.diff(pastDate));

  const years = Math.floor(duration.asYears());
  const months = Math.floor(duration.asMonths());
  const days = Math.floor(duration.asDays());
  const totalHours = Math.floor(duration.asHours());

  return { years, months, days, totalHours };
};

export const getBadgeType = (
  createdAtDate: any
): { type: BadgeType; clockType: string } => {
  const { totalHours }: any = getTimeDifferenceWithUTC(createdAtDate);

  if (totalHours < 72) {
    return { type: 'success', clockType: 'text-success500' };
  } else if (totalHours >= 72 && totalHours < 168) {
    return { type: 'warning', clockType: 'text-orange500' };
  } else {
    return { type: 'red', clockType: 'text-red500' };
  }
};

export const getBadgeTypeByMinutes = (
  createdAtDate: any
): { type: BadgeType; clockType: string } => {
  // const { totalHours }: any = getTimeDifferenceWithUTC(createdAtDate);
  const elapsedMinutes = moment().diff(moment.utc(createdAtDate), 'minutes');

  if (elapsedMinutes <= 30) {
    return { type: 'success', clockType: 'text-success500' };
  } else if (elapsedMinutes > 30 && elapsedMinutes <= 120) {
    return { type: 'warning', clockType: 'text-orange500' };
  } else {
    return { type: 'red', clockType: 'text-red500' };
  }
};

export const removeHtmlTags = (str: any) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = str;

  return tempDiv.textContent || tempDiv.innerText || '';
};

export const getFormattedPickupDate = (date: any) => {
  const momentDate = moment(date);
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');
  const tomorrow = moment().add(1, 'days').startOf('day');

  if (momentDate.isSame(today, 'day')) {
    return `Today, ${momentDate.format('MMMM Do, YYYY')}`;
  } else if (momentDate.isSame(yesterday, 'day')) {
    return `Yesterday, ${momentDate.format('MMMM Do, YYYY')}`;
  } else if (momentDate.isSame(tomorrow, 'day')) {
    return `Tomorrow, ${momentDate.format('MMMM Do, YYYY')}`;
  } else {
    return momentDate.format('MMMM Do, YYYY');
  }
};

export const convertBase64ToFile = (appLogoImage: any) => {
  try {
    const imageExtension = appLogoImage.substring(
      appLogoImage.indexOf('/') + 1,
      appLogoImage.indexOf(';base64')
    );

    const currentTimestamp = Date.now();
    const randomNum = Math.floor(1000000000 + Math.random() * 9000000000);
    const filename = `${currentTimestamp}_${randomNum}.${imageExtension}`;

    const base64Data = appLogoImage.replace(/^data:[^;]+;base64,/, '');

    const uint8Array = Uint8Array.from(atob(base64Data), (c) =>
      c.charCodeAt(0)
    );

    const blob = new Blob([uint8Array], {
      type: 'application/octet-stream',
    });

    const convertedFile = new File([blob], filename, {
      type: 'application/octet-stream',
    });

    return {
      convertedFile: convertedFile,
      filename: filename,
    };
  } catch (error) {
    // console.error('Error converting base64 to file:', error);
  }
};

export const downloadPdf = (url: string, name: string = 'invoice.pdf') => {
  downloadGCSPdf(url)
    .then((response: any) => {
      const blob = new Blob([response.data]);
      const pdfUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.target = '_blank';
      link.download = name;
      link.dispatchEvent(new MouseEvent('click'));
      link.remove();
      URL.revokeObjectURL(pdfUrl);
    })
    .catch(console.error);
};

export const getCountryName = (countryCode: any, locale = 'en') => {
  try {
    const regionNames = new Intl.DisplayNames([locale], { type: 'region' });

    return regionNames.of(countryCode) || 'Unknown Country';
  } catch (error) {
    return 'Invalid Country Code';
  }
};

export const getCountryFlag = (countryCode: any) => {
  if (!countryCode) return '';

  return String.fromCodePoint(
    ...[...countryCode.toUpperCase()].map((char) => 127397 + char.charCodeAt())
  );
};

export const formatLastUpdate = (date: any): string => {
  const now: any = new Date();
  const lastUpdate: any = new Date(date);
  const differenceInMilliseconds = now - lastUpdate;
  const differenceInHours = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60)
  );
  const differenceInDays = Math.floor(differenceInHours / 24);

  if (differenceInHours <= 72) {
    return `${differenceInHours} hours ago`;
  } else if (differenceInDays <= 3) {
    return `${differenceInDays} days ago`;
  } else {
    return `${lastUpdate.toLocaleDateString()}`;
  }
};
