export const API = {
  AUTH: {
    LOGIN: '/auth/login',
    VERIFICATION: '/auth/verification',
    LOGOUT: '/user/logout',
    VERIFICATION_MAIL: '/auth/verification-mail',
    VERIFY_OTP: '/auth/verify-otp',
    RESET_PASSWORD: '/auth/reset-password',
    VERIFY_PASSWORD: '/auth/verify-password',
    SET_ONBOARD: '/auth/sign-up',
    SAVE_BASIC_DETAILS: '/auth/save-basic-details',
    GOOGLE_LOGIN: '/auth/verify-google-user',
    FETCH_MICROSOFT_LOGIN: '/auth/microsoft-login',
    VERIFY_CODE: '/auth/code-verify',
  },
  ORGANIZATION: {
    UPDATE: '/organization/update',
  },
  SETTING: {
    UPDATE: '/settings/update',
  },
  MEMBER: {
    MEMBER_LIST: 'member/member-list',
    MEMBER_EDIT: 'member/edit',
    MEMBER_DELETE: 'member',
    MEMBER_INVITE: 'member/invite',
  },
  SALES_DASHBOARD: {
    TEAM_LIST: 'sales/team-list',
  },
  USER: {
    GET: '/user/get',
    GET_DETAIL: '/user/get-detail',
  },
  QUOTE: {
    GET: '/quote',
    GET_USER_QUOTES: '/user/quotes',
    LIST: '/quote',
    QUOTELIST_COUNT: '/quote-count',
    GET_ANALYTICS: 'quote/analytics',
    GET_ANALYTICS_TEAM: '/quote/team',
    CREATE: '/quote/address',
    UPDATE: '/quote/address',
    CREATE_UPDATE_DIMENSION: '/quote/dimension',
    CREATE_UPDATE_SERVICE: '/quote/service',
    CREATE_UPDATE_SUMMARY: '/quote/summary',
    CREATE_RATE: '/quote/rate',
    UPDATE_RATE: '/quote/rate',
    CANCEL: '/quote/cancel',
    QUICK_QUOTE: '/quote/quick-quote',
    ACTIVE_QUOTE: '/quote/active-quote',
    DELETE_ACTIVE_QUOTE: '/quote/delete-active-quote',
    QUOTE_DETAIL: 'quote/quote-detail',
    CREATE_PDF: '/quote/pdf',
    UPDATE_PDF: '/quote/pdf',
    LIST_PDF: '/quote/pdf',
    UPDATE_STATUS: '/quote/status',
    PUSH_TO_ROSE_ROCKET: '/quote/roserocket-order',
    BOOK_ORDER: '/quote/book-order',
    CHECK_BOL: '/quote/check-shipment-bol',
    DELETE: '/quote',
    MODIFY_QUOTE: '/quote/modifyquote',
    GET_FREIGHT_CLASS: '/quote/dimension/get-freight-class',
    MAKE_NO_QUOTE: '/quote/make-no-quote',
    LOAD_LINK: {
      GET_VEHICLE_TYPE: '/load-link/vehicle_type',
    },
    UPDATE_QUOTE_STATUS: 'quote/update-quote-status',
  },
  ROLE: {
    LIST: '/role',
  },
  HANDLING_UNIT: {
    LIST: '/handling-unit',
  },
  SERVICES: {
    LIST: '/service',
  },
  CURRENCY_RATE: {
    GET: '/currency/rate',
  },
  INTEGRATIONS: {
    MAP_SERVICE: '/integrations/map-service',
  },
  SHIPPER: {
    LIST: '/shipper',
    CREATE: '/shipper',
    DELETE: '/shipper',
  },
  DASHBOARD: {
    UPCOMING: '/dashboard/get-orders-data',
    RECENT_ACTIVITY: '/dashboard/get-recent-activity',
  },
  ORDERS: {
    GET: '/orders/get',
  },
  CLAIMS_AND_DISPUTE: {
    CHAT_MESSAGE: {
      LIST: '/chat-messages',
      SEND_MESSAGE: '/chat-messages/send',
      MARK_MESSAGE_AS_READ: '/mark-message-as-read',
    },
    INTERNAL_NOTES: {
      LIST: '/claim-and-dispute/get-internal-notes',
      CREATE: '/claim-and-dispute/create-internal-notes',
      MARK_NOTE_AS_READ: '/claim-dispute-internal-notes/mark-note-as-read',
    },
    CLAIMS_DISPUTE_TASK: {
      LIST: '/claim-dispute/action-item',
      LIST_COUNT: '/claim-dispute/action-item-count',
      ADD_TASK: '/claim-dispute/action-item',
      UPDATE_TASK: '/claim-dispute/action-item',
      DELETE_TASK: '/claim-dispute/action-item',
    },
  },
};
