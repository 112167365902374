import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const fetchOrganizationMemberList = async (params: any) => {
  const response = await axiosInterceptor.get(`${API.MEMBER.MEMBER_LIST}`, {
    params,
  });

  return response;
};

export const editMember = async (payload: any) => {
  const response = await axiosInterceptor.post(
    `${API.MEMBER.MEMBER_EDIT}`,
    payload
  );

  return response;
};

export const inviteMember = async (payload: any) => {
  const response = await axiosInterceptor.post(
    `${API.MEMBER.MEMBER_INVITE}`,
    payload
  );

  return response;
};

export const organizationUpdate = async (payload: any) => {
  const response = await axiosInterceptor.post(
    `${API.ORGANIZATION.UPDATE}`,
    payload
  );

  return response;
};

export const deleteMember = async (id: any) => {
  const response = await axiosInterceptor.delete(
    `${API.MEMBER.MEMBER_DELETE}/${id}`
  );

  return response;
};
