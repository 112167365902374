import React from 'react';

import ErrorMsg from '../errorMsg';

import { ICheckBoxProps } from './checkBox.interface';

const CheckBox = ({
  errorText,
  classes,
  parentClassName,
  checked,
  disabled = false,
  required,
  onChangeFunc,
  label,
  checkboxBox,
  labelIcon,
  ...props
}: ICheckBoxProps) => (
  <>
    <div
      className={`flex relative ${
        checkboxBox ? 'px-3 py-2.5 custom-checkbox-box' : ''
      } ${parentClassName}`}
    >
      <input
        type="checkbox"
        className={`checkbox border-borderPrimary  border-1 cursor-pointer !animate-none ${
          checkboxBox
            ? 'checkbox-box-input h-full w-full absolute top-0 left-0 !bg-transparent rounded-lg bg-none checked:border-primary hover:border-primary checked:!bg-[rgba(164, 188, 253, 0.2)]'
            : 'h-3.5 w-3.5 rounded checkbox-primary'
        } ${disabled ? '!checkbox-primary !opacity-60' : ''} ${classes}`}
        id={props.id}
        value={props.value}
        onChange={onChangeFunc}
        required={required}
        checked={checked}
        disabled={disabled}
        {...props}
      />
      {label && (
        <div className="flex gap-x-1.5 checkbox-label text-textSecondary ">
          {labelIcon}
          <p className="text-xs font-semibold ">{label}</p>{' '}
        </div>
      )}
    </div>
    <ErrorMsg errorText={errorText}></ErrorMsg>
  </>
);

export default CheckBox;
