import {
  ArrowNarrowRight,
  File05,
  Mark,
  NotificationText,
  PackagePlus,
  Plus,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AccordionCmp from 'src/components/AccordionCmp';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
// import TimerBadge from 'src/components/TimerBadge';
import Toggle from 'src/components/Toggle/Toggle';
import { PATH } from 'src/constants/path';
import { ROUTES } from 'src/constants/routes';
import { AuthContext } from 'src/context/AuthContext';
import {
  listRecentActivity,
  listUpcomingOrder,
} from 'src/services/DashboardService';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
} from 'src/utils/CommonFunctions';

import gettingSatrtedImage from '../../assets/img/getting-started-image.svg';
import gettingSatrtedVideo from '../../assets/img/getting-started-video.svg';
import videoPlayButton from '../../assets/img/video-play-btn.png';

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
moment.tz.setDefault(browserTimezone);

const Dashboard = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [upComingDeliverOrder, setUpComingDeliverOrder] = useState([]);
  const [upComingPickupOrder, setUpComingPickupOrder] = useState([]);
  const [recentActivityData, setRecentActivityData] = useState([]);
  const [isUpcomingOrderLoading, setIsUpcomingOrderLoading] = useState(false);
  const [isRecentActivityLoading, setIsRecentActivityLoading] = useState(false);

  useEffect(() => {
    setIsRecentActivityLoading(true);
    setIsUpcomingOrderLoading(true);
    listUpcomingOrder(
      `startDate=${moment().format('YYYY-MM-DD')}&endDate=${moment().format(
        'YYYY-MM-DD'
      )}`
    )
      .then((response) => {
        if (response?.data?.length) {
          const data = response.data;
          const pickupOrders = data.filter(
            (order: any) => order.orderType === 'pickup'
          );
          const deliveryOrders = data.filter(
            (order: any) => order.orderType === 'delivery'
          );

          setUpComingPickupOrder(pickupOrders || []);
          setUpComingDeliverOrder(deliveryOrders || []);
        }
        setIsUpcomingOrderLoading(false);
      })
      .catch((error) => {
        setIsUpcomingOrderLoading(false);
        console.log('error', error);
      });
    listRecentActivity()
      .then((response) => {
        if (response?.data?.length) {
          const data = response.data || [];
          setRecentActivityData(data);
        }
        setIsRecentActivityLoading(false);
      })
      .catch((error) => {
        setIsRecentActivityLoading(false);
        console.log('error', error);
      });
  }, []);

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title={`Welcome back, ${
              currentUser?.name?.split(' ')[0] || 'User'
            }`}
            desc="What would you like to do today?"
            isShowNotificationBox
          />
        }
      >
        <ul className="flex gap-4">
          <li
            onClick={() => navigate(PATH.ADDRESS_DETAILS)}
            className="flex-1 rounded-xl border border-utilityGray200 shadow-xs p-5 flex items-start gap-x-3 pag-y-1 cursor-pointer"
          >
            <div className="rounded-[10px] border border-utilityGray200 shadow-xs p-3">
              <Plus className="w-4 h-4 text-textSecondary" />
            </div>
            <div className="flex-1 mt-0.5">
              <h6 className="text-primary text-base font-semibold">
                Create a New Quote
              </h6>
              <p className="text-grayLight600 text-sm font-normal">
                Click to begin your shipment quote
              </p>
            </div>
            <ButtonCmp
              className="btn_secondary_black !p-3"
              icon={<ArrowNarrowRight className="w-4 h-4" />}
            >
              <></>
            </ButtonCmp>
          </li>
          <li
            onClick={() => navigate(ROUTES.RADAR)}
            className="flex-1 rounded-xl border border-utilityGray200 shadow-xs p-5 flex items-start gap-x-3 pag-y-1 cursor-pointer"
          >
            <div className="rounded-[10px] border border-utilityGray200 shadow-xs p-3">
              <Mark className="w-4 h-4 text-textSecondary" />
            </div>
            <div className="flex-1 mt-0.5">
              <h6 className="text-primary text-base font-semibold">
                Track My Shipments
              </h6>
              <p className="text-grayLight600 text-sm font-normal">
                Monitor your shipments with ease
              </p>
            </div>
            <ButtonCmp
              className="btn_secondary_black !p-3"
              icon={<ArrowNarrowRight className="w-4 h-4" />}
            >
              <></>
            </ButtonCmp>
          </li>
          <li
            onClick={() => navigate(ROUTES.DOCUMENTATION_HUB)}
            className="flex-1 rounded-xl border border-utilityGray200 shadow-xs p-5 flex items-start gap-x-3 pag-y-1 cursor-pointer"
          >
            <div className="rounded-[10px] border border-utilityGray200 shadow-xs p-3">
              <File05 className="w-4 h-4 text-textSecondary" />
            </div>
            <div className="flex-1 mt-0.5">
              <h6 className="text-primary text-base font-semibold">
                My Documents
              </h6>
              <p className="text-grayLight600 text-sm font-normal">
                View and manage all your files in one place
              </p>
            </div>
            <ButtonCmp
              className="btn_secondary_black !p-3"
              icon={<ArrowNarrowRight className="w-4 h-4" />}
            >
              <></>
            </ButtonCmp>
          </li>
        </ul>
        <div className="flex flex-col gap-y-3">
          <h6 className="text-grayLight900 text-lg font-semibold">
            Today&apos;s Overview
          </h6>
          {/* IF NO OVERVIEW STARTS */}
          {isUpcomingOrderLoading && (
            <div className="rounded-[10px] border border-utilityGray200 bg-gray25 p-5 text-center">
              <h6 className="text-grayLight900 text-xs font-semibold">
                No Scheduled Pickups Today
              </h6>
              <p className="mt-1 text-grayLight600 text-xs font-normal">
                orders are currently scheduled for pickup.
              </p>
            </div>
          )}
          {upComingPickupOrder?.length === 0 && !isUpcomingOrderLoading && (
            <div className="rounded-[10px] border border-utilityGray200 bg-gray25 p-5 text-center">
              <h6 className="text-grayLight900 text-xs font-semibold">
                No Scheduled Pickups Today
              </h6>
              <p className="mt-1 text-grayLight600 text-xs font-normal">
                No orders are currently scheduled for pickup.
              </p>
            </div>
          )}
          {isUpcomingOrderLoading && (
            <div className="rounded-[10px] border border-utilityGray200 bg-gray25 p-5 text-center">
              <h6 className="text-grayLight900 text-xs font-semibold">
                No Scheduled Deliveries Today
              </h6>
              <p className="mt-1 text-grayLight600 text-xs font-normal">
                There are no deliveries scheduled at this time.
              </p>
            </div>
          )}
          {upComingDeliverOrder?.length === 0 && !isUpcomingOrderLoading && (
            <div className="rounded-[10px] border border-utilityGray200 bg-gray25 p-5 text-center">
              <h6 className="text-grayLight900 text-xs font-semibold">
                No Scheduled Deliveries Today
              </h6>
              <p className="mt-1 text-grayLight600 text-xs font-normal">
                There are no deliveries scheduled at this time.
              </p>
            </div>
          )}
          {/* IF NO OVERVIEW ENDS */}
          {upComingPickupOrder?.length > 0 && (
            <AccordionCmp
              className="!p-5 bg-gray25"
              contentParentClass="mt-5 pt-5"
              contentClassName="!bg-transparent"
              title={
                <div className="flex flex-wrap gap-x-2 w-full">
                  <div className="flex-col gap-y-1 flex-1">
                    <h6 className="text-grayLight600 text-xs font-normal">
                      Orders
                    </h6>
                    <p className="text-grayLight900 text-sm font-medium">
                      {upComingPickupOrder.length} Orders Picking Up Today
                    </p>
                  </div>
                  <div className="flex-col gap-y-1 min-w-[75%]">
                    <h6 className="text-grayLight600 text-xs font-normal">
                      Order ID
                    </h6>
                    <ul className="flex flex-wrap space-x-2">
                      {upComingPickupOrder.map((order: any, index: any) => (
                        <li
                          key={index}
                          className={`text-textSecondary text-sm font-normal last:pr-0 pr-2 last:border-r-0  border-grayLight900 ${
                            upComingPickupOrder?.[index + 1] ? 'border-r' : ''
                          }`}
                        >
                          {order.orderNo}{' '}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              }
            >
              <ul className="flex flex-wrap -m-2">
                {upComingPickupOrder?.map((order: any, index: any) => (
                  <li key={index} className="w-1/2 p-2">
                    <div className="rounded-[10px] border border-utilityGray200 bg-white shadow-sm p-4 flex flex-col gap-y-6 h-full">
                      <div className="flex items-center gap-x-2.5">
                        <div className="rounded bg-utilityGray100 p-1">
                          <PackagePlus className="w-4 h-4" />
                        </div>
                        <h6>{order.orderNo}</h6>
                        {order?.classification == 'ftl' && (
                          <BadgeCmp type="primary" style="modern">
                            FTL
                          </BadgeCmp>
                        )}
                        {order?.classification == 'ltl' && (
                          <BadgeCmp type="success" style="modern">
                            LTL
                          </BadgeCmp>
                        )}
                        <BadgeCmp type="warning" style="modern">
                          Picked Up
                        </BadgeCmp>
                      </div>
                      <div className="flex flex-wrap gap-x-2">
                        <div className="w-1/5">
                          <h6 className="text-grayLight600 text-xs font-normal">
                            Pickup Time
                          </h6>
                          <p className="text-grayLight900 text-xs">
                            Approx.{' '}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: order?.date
                                  ? getDateWithSuffixFormat(
                                      getFormattedDate(
                                        order?.date,
                                        `hh:mm A`,
                                        true,
                                        true
                                      )
                                    )
                                  : '-',
                              }}
                            />
                          </p>
                        </div>
                        <div className="w-1/5">
                          <h6 className="text-grayLight600 text-xs font-normal">
                            Pickup Items
                          </h6>
                          <p className="text-grayLight900 text-xs">
                            {order?.orderItem}
                          </p>
                        </div>
                        <div className="w-1/5">
                          <h6 className="text-grayLight600 text-xs font-normal">
                            Shipper
                          </h6>
                          <p className="text-grayLight900 text-xs">
                            {order?.companyName || '-'}
                          </p>
                        </div>
                        <div className="flex-1">
                          <h6 className="text-grayLight600 text-xs font-normal">
                            Pickup Address
                          </h6>
                          <p className="text-grayLight900 text-xs">
                            {order?.address || '-'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </AccordionCmp>
          )}
          {upComingDeliverOrder?.length > 0 && (
            <AccordionCmp
              className="!p-5 bg-gray25"
              contentParentClass="mt-5 pt-5"
              contentClassName="!bg-transparent"
              title={
                <div className="flex flex-wrap gap-x-2 w-full">
                  <div className="flex-col gap-y-1 flex-1">
                    <h6 className="text-grayLight600 text-xs font-normal">
                      Orders
                    </h6>
                    <p className="text-grayLight900 text-sm font-medium">
                      {upComingDeliverOrder.length} Orders Delivering today
                    </p>
                  </div>
                  <div className="flex-col gap-y-1 min-w-[75%]">
                    <h6 className="text-grayLight600 text-xs font-normal">
                      Order ID
                    </h6>
                    <ul className="flex flex-wrap space-x-2">
                      {upComingDeliverOrder.map((order: any, index: any) => (
                        <li
                          key={index}
                          className={`text-textSecondary text-sm font-normal last:pr-0 pr-2 last:border-r-0  border-grayLight900 ${
                            upComingDeliverOrder?.[index + 1] ? 'border-r' : ''
                          }`}
                        >
                          {order.orderNo}{' '}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              }
            >
              <ul className="flex flex-wrap -m-2">
                {upComingDeliverOrder?.map((order: any, index: any) => (
                  <li key={index} className="w-1/2 p-2">
                    <div className="rounded-[10px] border border-utilityGray200 bg-white shadow-sm p-4 flex flex-col gap-y-6 h-full">
                      <div className="flex items-center gap-x-2.5">
                        <div className="rounded bg-utilityGray100 p-1">
                          <PackagePlus className="w-4 h-4" />
                        </div>
                        <h6>{order.orderNo}</h6>
                        {order?.classification == 'ftl' && (
                          <BadgeCmp type="primary" style="modern">
                            FTL
                          </BadgeCmp>
                        )}
                        {order?.classification == 'ltl' && (
                          <BadgeCmp type="success" style="modern">
                            LTL
                          </BadgeCmp>
                        )}
                        <BadgeCmp type="warning" style="modern">
                          Delivering
                        </BadgeCmp>
                      </div>
                      <div className="flex flex-wrap gap-x-2">
                        <div className="w-1/5">
                          <h6 className="text-grayLight600 text-xs font-normal">
                            Delivering Time
                          </h6>
                          <p className="text-grayLight900 text-xs">
                            Approx.{' '}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: order?.date
                                  ? getDateWithSuffixFormat(
                                      getFormattedDate(
                                        order?.date,
                                        `hh:mm A`,
                                        true,
                                        true
                                      )
                                    )
                                  : '-',
                              }}
                            />
                          </p>
                        </div>
                        <div className="w-1/5">
                          <h6 className="text-grayLight600 text-xs font-normal">
                            Delivering Items
                          </h6>
                          <p className="text-grayLight900 text-xs">
                            {order?.orderItem}
                          </p>
                        </div>
                        <div className="w-1/5">
                          <h6 className="text-grayLight600 text-xs font-normal">
                            Shipper
                          </h6>
                          <p className="text-grayLight900 text-xs">
                            {order?.shipperName || '-'}
                          </p>
                        </div>
                        <div className="flex-1">
                          <h6 className="text-grayLight600 text-xs font-normal">
                            Delivering Address
                          </h6>
                          <p className="text-grayLight900 text-xs">
                            {order?.orderAddress || '-'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </AccordionCmp>
          )}
        </div>
        <div className="flex flex-wrap gap-x-6">
          <div className="w-[60%] flex flex-col gap-y-5">
            <div className="flex items-center gap-x-2.5">
              <h6 className="text-grayLight900 text-lg font-semibold">
                Recent Activity
              </h6>
              <BadgeCmp type="primary" style="modern">
                0 Unread
              </BadgeCmp>
            </div>
            {recentActivityData?.length > 0 && !isRecentActivityLoading && (
              <ul className="rounded-lg border border-utilityGray200 flex flex-col gap-y-2">
                {recentActivityData?.map((activity: any, index: any) => (
                  <li
                    className="px-4 py-3 flex gap-2 items-start even:bg-gray50"
                    key={index}
                  >
                    <div className="rounded bg-utilityGray100 p-2">
                      <PackagePlus className="w-4 h-4 text-gray500" />
                    </div>
                    <div className="flex-1">
                      <h6 className="text-textSecondary text-sm font-medium">
                        {activity?.notificationTitle}
                      </h6>
                      <p className="text-grayLight600 text-xs font-normal">
                        {/* Package of order{' '}
                        <span className="text-grayLight900 text-sm font-medium">
                          #WAL-SHKL-45
                        </span>{' '}
                        is collected and ready for transit. */}
                        {activity?.description}
                      </p>
                    </div>
                    <p className="text-gray500 text-xs font-medium min-w-24 text-right">
                      {/* <TimerBadge
                        badgeDate={activity?.createdAt}
                        onlyTimer={true}
                      /> */}
                    </p>
                    {/* <p className="w-1.5 h-1.5 rounded-full"></p> */}
                    {/* IF NEW ACTIVITY SHOW BELOW, IF NOT SHOW ABOVE COMMENTED CODE */}
                    {activity?.isRead === false && (
                      <p className="w-1.5 h-1.5 bg-primary rounded-full"></p>
                    )}
                  </li>
                ))}
              </ul>
            )}
            {isRecentActivityLoading && (
              <ul className="rounded-lg border border-utilityGray200 flex flex-col gap-y-2">
                <li className="px-4 py-3 flex gap-2 items-start even:bg-gray50">
                  <div className="rounded bg-utilityGray100 p-2 custom-loading">
                    <PackagePlus className="w-4 h-4 text-gray500" />
                  </div>
                  <div className="flex-1">
                    <h6 className="text-textSecondary text-sm font-medium custom-loading">
                      Pickup Confirmed
                    </h6>
                    <p className="text-grayLight600 text-xs font-normal custom-loading">
                      Package of order #WAL-SHKL-45 is collected and ready for
                      transit.
                    </p>
                  </div>
                  <p className="text-gray500 text-xs font-medium min-w-24 text-right custom-loading">
                    35 mins ago
                  </p>
                  <p className="w-1.5 h-1.5 rounded-full custom-loading"></p>
                </li>
                <li className="px-4 py-3 flex gap-2 items-start even:bg-gray50">
                  <div className="rounded bg-utilityGray100 p-2 custom-loading">
                    <PackagePlus className="w-4 h-4 text-gray500" />
                  </div>
                  <div className="flex-1">
                    <h6 className="text-textSecondary text-sm font-medium custom-loading">
                      Pickup Confirmed
                    </h6>
                    <p className="text-grayLight600 text-xs font-normal custom-loading">
                      Package of order #WAL-SHKL-45 is collected and ready for
                      transit.
                    </p>
                  </div>
                  <p className="text-gray500 text-xs font-medium min-w-24 text-right custom-loading">
                    35 mins ago
                  </p>
                  <p className="w-1.5 h-1.5 rounded-full custom-loading"></p>
                </li>
                <li className="px-4 py-3 flex gap-2 items-start even:bg-gray50">
                  <div className="rounded bg-utilityGray100 p-2 custom-loading">
                    <PackagePlus className="w-4 h-4 text-gray500" />
                  </div>
                  <div className="flex-1">
                    <h6 className="text-textSecondary text-sm font-medium custom-loading">
                      Pickup Confirmed
                    </h6>
                    <p className="text-grayLight600 text-xs font-normal custom-loading">
                      Package of order #WAL-SHKL-45 is collected and ready for
                      transit.
                    </p>
                  </div>
                  <p className="text-gray500 text-xs font-medium min-w-24 text-right custom-loading">
                    35 mins ago
                  </p>
                  <p className="w-1.5 h-1.5 rounded-full custom-loading"></p>
                </li>
                <li className="px-4 py-3 flex gap-2 items-start even:bg-gray50">
                  <div className="rounded bg-utilityGray100 p-2 custom-loading">
                    <PackagePlus className="w-4 h-4 text-gray500" />
                  </div>
                  <div className="flex-1">
                    <h6 className="text-textSecondary text-sm font-medium custom-loading">
                      Pickup Confirmed
                    </h6>
                    <p className="text-grayLight600 text-xs font-normal custom-loading">
                      Package of order #WAL-SHKL-45 is collected and ready for
                      transit.
                    </p>
                  </div>
                  <p className="text-gray500 text-xs font-medium min-w-24 text-right custom-loading">
                    35 mins ago
                  </p>
                  <p className="w-1.5 h-1.5 rounded-full custom-loading"></p>
                </li>
                <li className="px-4 py-3 flex gap-2 items-start even:bg-gray50">
                  <div className="rounded bg-utilityGray100 p-2 custom-loading">
                    <PackagePlus className="w-4 h-4 text-gray500" />
                  </div>
                  <div className="flex-1">
                    <h6 className="text-textSecondary text-sm font-medium custom-loading">
                      Pickup Confirmed
                    </h6>
                    <p className="text-grayLight600 text-xs font-normal custom-loading">
                      Package of order #WAL-SHKL-45 is collected and ready for
                      transit.
                    </p>
                  </div>
                  <p className="text-gray500 text-xs font-medium min-w-24 text-right custom-loading">
                    35 mins ago
                  </p>
                  <p className="w-1.5 h-1.5 rounded-full custom-loading"></p>
                </li>
              </ul>
            )}

            {recentActivityData?.length <= 0 && (
              <ul className="rounded-lg border border-utilityGray200 flex-1 flex flex-col">
                <li className="px-4 py-3 flex-1 flex flex-col">
                  <NotFoundUI
                    title="No Recent Activity Found"
                    desc="There are no recent activity."
                    containerClassName="min-h-[unset] flex-1"
                  />
                </li>
              </ul>
            )}
          </div>
          <div className="flex-1 flex flex-col gap-y-5">
            <h6 className="text-grayLight900 text-lg font-semibold">
              Getting Started
            </h6>
            <div className="flex flex-col gap-y-3">
              <Toggle
                labelPosition="left"
                labelTextClassName="flex gap-x-2.5"
                labelClassName="justify-between rounded-md border border-utilityGray200 bg-gray25 p-3"
                label={
                  <>
                    <NotificationText className="w-4 h-4 text-textSecondary flex-none mt-0.5" />
                    <div>
                      <h6 className="text-grayLight900 text-sm font-semibold">
                        Enable Text Notifications
                      </h6>
                      <p className="text-textSecondary text-xs font-normal">
                        Turn on text notifications to never miss an updates and
                        important alerts.
                      </p>
                    </div>
                  </>
                }
              />
              <ul className="rounded-lg border border-utilityGray200 flex flex-col gap-y-4 p-4">
                <li className="flex gap-3 items-start last:border-b-0 border-b border-utilityGray200 last:pb-0 pb-4">
                  <div className="rounded-md overflow-hidden w-[150px] h-[90px] relative">
                    <img
                      src={gettingSatrtedImage}
                      className="h-full w-full object-cover"
                    />
                    <BadgeCmp
                      style="pill"
                      type="primary"
                      mainClassName="absolute bottom-2 left-2"
                    >
                      New
                    </BadgeCmp>
                  </div>
                  <div className="flex-1">
                    <h6 className="text-grayLight900 text-lg font-semibold">
                      Overview
                    </h6>
                    <p className="text-grayLight600 text-xs font-normal">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industrys
                      standard.
                    </p>
                  </div>
                </li>
                <li className="flex gap-3 items-start last:border-b-0 border-b border-utilityGray200 last:pb-0 pb-4">
                  <div className="rounded-md overflow-hidden w-[150px] h-[90px] relative">
                    <img
                      src={gettingSatrtedVideo}
                      className="h-full w-full object-cover"
                    />
                    <img
                      src={videoPlayButton}
                      className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
                    />
                  </div>
                  <div className="flex-1">
                    <h6 className="text-grayLight900 text-lg font-semibold">
                      Explore What&apos;s New
                    </h6>
                    <p className="text-grayLight600 text-xs font-normal">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industrys
                      standard dummy text since 1500s, when an unknown printer
                      took a galley of type.
                    </p>
                  </div>
                </li>
                <li className="flex gap-3 items-start last:border-b-0 border-b border-utilityGray200 last:pb-0 pb-4">
                  <div className="rounded-md overflow-hidden w-[150px] h-[90px] relative">
                    <img
                      src={gettingSatrtedImage}
                      className="h-full w-full object-cover"
                    />
                    <BadgeCmp
                      style="pill"
                      type="primary"
                      mainClassName="absolute bottom-2 left-2"
                    >
                      New
                    </BadgeCmp>
                  </div>
                  <div className="flex-1">
                    <h6 className="text-grayLight900 text-lg font-semibold">
                      Tutorial
                    </h6>
                    <p className="text-grayLight600 text-xs font-normal">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industrys
                      standard dummy text since 1500s, when an unknown printer
                      took a galley of type.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </PageSectionLayout>
    </>
  );
};

export default Dashboard;
