import { Eye, EyeOff } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ButtonCmp from 'src/components/ButtonCmp';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import { ROUTES } from 'src/constants/routes';
import { AuthContext } from 'src/context/AuthContext';
import { verifyPassword } from 'src/services/AuthService';

import AuthWrapper from '../AppLayout/AuthWrapper';

const initFormData: any = {
  password: '',
};

const VerifyPassword = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);
  const [formData, setFormData] = useState<any>(initFormData);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState<any>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [code, setCode] = useState<string | null>(null);

  const handleInputChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    const codeVal =
      new URLSearchParams(location?.search).get('code') || (null as any);

    if (!codeVal) {
      navigate(ROUTES?.LOGIN);
    }
    setCode(encodeURIComponent(codeVal));
  }, []);

  const handleSubmit = (event: any) => {
    setErrorMsg('');
    setIsValid(true);
    event.preventDefault();

    if (!formData?.password && formData?.password === '') {
      setIsValid(false);

      return true;
    }
    setIsLoading(true);
    const payload = {
      password: formData?.password,
      code: code,
    };
    verifyPassword(payload)
      .then((response) => {
        setIsLoading(false);

        if (response?.token && response?.token) {
          // setShowVerification(true);
          setIsAuthenticated(true);

          if (response?.data?.isSetBasicDetail) {
            console.log('home page');
            navigate(ROUTES.HOME);

            return;
          } else {
            console.log('organisation page');
            navigate(ROUTES.SET_DETAILS);

            return;
          }
        } else {
          console.log('object', response);

          setErrorMsg(response?.message || 'Something went wrong with login');
        }
      })
      .catch((error): any => {
        setIsLoading(false);
        setErrorMsg(
          error?.response?.data?.message || 'Something went wrong with login'
        );
      });
  };

  return (
    <AuthWrapper
      authHeading="Password"
      authSubTitle="Welcome! Please enter Password."
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-y-4">
        <div>
          <InputText
            label="Password"
            labelClassName="block mb-1.5"
            inputType={showPassword ? 'text' : 'password'}
            inputName="password"
            className={`input_text_auth pr-[36px] ${
              !isValid ? ' !border-[#FDA29B] ' : ' !border-[#D0D5DD] '
            }`}
            placeholder="Enter your password"
            value={formData.password}
            errorText={!isValid ? 'Password is required' : null}
            onChangeFunc={handleInputChange}
            icon={
              <div
                className="absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer peer-placeholder-shown:pointer-events-none peer-placeholder-shown:text-gray400"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <Eye className="w-4 h-4 " />
                ) : (
                  <EyeOff className="w-4 h-4 " />
                )}
              </div>
            }
          />
          <ErrorMsg errorText={errorMsg} />
        </div>
        <ButtonCmp
          type="submit"
          disabled={isLoading}
          className="btn_primary w-full mt-1"
          loading={isLoading}
        >
          Sign In
        </ButtonCmp>
        {/* <div className="flex w-full p-0 mb-5 lg:mb-2 xl:mb-5 mt-2.5 ">
                  <div className="w-full items-center text-[#000000B2] text-[12px] font-medium">
                    <input
                      type="checkbox"
                      className="mr-2 mb-1 rounded-md border-[#D0D5DD]"
                    />
                    Remember me
                  </div>
                  <div className="w-full text-right  text-[#000000B2] text-[12px] font-medium">
                    <Link
                      className="cursor-pointer text-[#2422DD]  font-medium "
                      to={ROUTES.FORGOTPASSWORD}
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div> */}
      </form>
      <div className="text-utilityTertiary600 text-sm font-normal text-center mt-6">
        Forgot Your Password?&nbsp;
        <Link
          className="text-primary font-semibold cursor-pointer"
          to={ROUTES.FORGOTPASSWORD}
        >
          Click here
        </Link>
      </div>
    </AuthWrapper>
  );
};
export default VerifyPassword;
