import { XClose } from '@untitled-ui/icons-react/build/cjs';
import React, { useCallback, useMemo, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import DeleteModal from 'src/components/DeleteModal';
// import OutsideClickHandler from 'src/components/OutsideClickHandler';
import Header from 'src/components/PageSectionLayout/Header/Header';
import TooltipCmp from 'src/components/TooltipCmp';
import { TABLE_IDS } from 'src/constants/common';
// import { PATH } from 'src/constants/path';
import { ROUTES } from 'src/constants/routes';
import { BadgeType } from 'src/interface/common';
// import { cancelOrder } from 'src/services/OrderService';

import defaultimage from '../../../assets/img/default-image.jpg';

// import RecurringOrderModal from './RecurringOrder/RecurringOrderModal';

interface IProps {
  id: number;
  isLoading: boolean;
  order: any;
  navigate: any;
  isDiscardButton: boolean;
  isShowActionButton: boolean;
}

const OrderHeader = ({
  // id,
  isLoading,
  order,
  navigate,
  isDiscardButton,
}: IProps) => {
  const [isOrderDiscardModal, setIsOrderDiscardModal] = useState(false);

  const handleDiscardModalClose = useCallback(
    (status: boolean) => () => {
      if (status) {
        localStorage.setItem(TABLE_IDS.ORDER_LIST, JSON.stringify('new-order'));

        navigate(`${ROUTES.ORDERS}`);
      } else {
        setIsOrderDiscardModal(false);
      }
    },
    []
  );

  const getBadgeType = (status: any) => {
    let type: BadgeType = 'success';

    if (
      status === 'draft' ||
      status === 'cancelled' ||
      status === 'dispatched'
    ) {
      type = 'pink';
    } else if (status === 'booked' || status === 'delivered') {
      type = 'lightblue';
    } else if (status === 'in-transit') {
      type = 'error';
    } else if (status === 'completed') {
      type = 'gray';
    } else {
      type = 'red';
    }

    return type;
  };

  const memorizedBadge = useMemo(
    () => (
      <BadgeCmp
        style="modern"
        mainClassName={`rounded-md ${isLoading ? 'custom-loading' : ''}`}
        type={getBadgeType(order.orderStatus)}
      >
        {!isLoading && order ? order.orderStatus : 'Opened'}
      </BadgeCmp>
    ),
    [order, isLoading]
  );

  return (
    <>
      <Header
        leftClassName="!w-auto"
        title={
          <div className="flex gap-x-2.5 gap-y-1 flex-wrap items-center">
            Order:
            <span className={`${isLoading ? 'custom-loading' : ''}`}>
              #{!isLoading && order ? order.orderId : 'CL-AOI1-141'}
            </span>
            <div className="flex items-center [&>div:not(:first-child)>div>div]:border-white [&>div:not(:first-child)>div>div]:border-[1.5px] [&>div:not(:first-child)]:-ml-2">
              {order?.customerName && (
                <TooltipCmp message={order?.customerName}>
                  {order?.customerImage ? (
                    <div className="rounded-full bg-white">
                      <img
                        src={order?.customerImageUrl + order?.customerImage}
                        className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                      />
                    </div>
                  ) : (
                    <div className="rounded-full bg-white">
                      <img
                        src={defaultimage}
                        className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                      />
                    </div>
                  )}
                </TooltipCmp>
              )}
              {order?.salesRepFirstName && (
                <TooltipCmp
                  message={`${order?.salesRepFirstName} ${order?.salesRepLastName}`}
                >
                  {order?.salesImage ? (
                    <div className="rounded-full bg-white">
                      <img
                        src={order?.salesImageUrl + order?.salesImage}
                        className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                      />
                    </div>
                  ) : (
                    <div className="rounded-full bg-white">
                      <img
                        src={defaultimage}
                        className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                      />
                    </div>
                  )}
                </TooltipCmp>
              )}
            </div>
            {memorizedBadge}
          </div>
        }
        desc={`Detailed review of items, prices, and order status`}
        rightSideContent={
          <>
            <TooltipCmp message={isDiscardButton ? 'Discard Changes' : null}>
              <ButtonCmp
                className="btn_secondary_black lg:!px-[9px] !px-2"
                onClick={() => {
                  if (isDiscardButton) {
                    setIsOrderDiscardModal(true);
                  } else {
                    localStorage.setItem(
                      TABLE_IDS.ORDER_LIST,
                      JSON.stringify('new-order')
                    );

                    navigate(`${ROUTES.ORDERS}`);
                  }
                }}
              >
                <XClose className="w-4 h-4" />
              </ButtonCmp>
            </TooltipCmp>
          </>
        }
      />

      {isOrderDiscardModal && (
        <DeleteModal
          moduleTitle={'Discard Changes'}
          // moduleTitleDesc={`Are you sure you want to discard the changes of order details?`}
          handleClose={handleDiscardModalClose}
          // dangerBtnText={'Discard'}
        />
      )}
    </>
  );
};

export default OrderHeader;
