// import { useMsal } from '@azure/msal-react';
// import { InteractionStatus } from '@azure/msal-browser';
// import { useMsal } from '@azure/msal-react';
import { useGoogleLogin } from '@react-oauth/google';
import { Eye, EyeOff } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import GoogleLogo from '../../assets/img/google_logo.png';
import MicrosoftLogo from '../../assets/img/microsoft-icon.svg';
// import { msalInstance } from '../../authConfig';
import ButtonCmp from '../../components/ButtonCmp';
import ErrorMsg from '../../components/errorMsg';
import InputText from '../../components/InputText/InputText';
import { ROUTES } from '../../constants/routes';
import { AuthContext } from '../../context/AuthContext';
import {
  microsoftLogin,
  login,
  verifyPassword,
} from '../../services/AuthService';
import './login.scss';
import AuthWrapper from '../AppLayout/AuthWrapper';

import VerifyOtp from './VerifyOtp';

const initValidForm = {
  email: true,
  isEmailValid: true,
  password: true,
  emailNotExists: true,
  inCorrectPassword: true,
};

const Login = () => {
  // const { accounts, inProgress } = useMsal();
  const { setIsAuthenticated } = useContext(AuthContext);
  const [validForm, setValidForm] = useState(initValidForm);
  const [isLoading, setIsLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const initFormData: any = {
    email: '',
    password: '',
  };
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState<any>(initFormData);
  const [errorMsg, setErrorMsg] = useState<any>('');
  const [showVerification, setShowVerification] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [isUserExists, setIsUserExists] = useState<any>(false);

  useEffect(() => {
    if (window.performance.navigation.type === 1) {
      navigate(location.pathname, { replace: true });
    }
  }, []);

  const handleInputChange = (event: any) => {
    if (location.state?.message) {
      navigate(location.pathname, { replace: true });
    }
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const valid = () => {
    let isValid = true;
    const validFormData = {
      email: true,
      isEmailValid: true,
      password: true,
    };
    setErrorMsg(null);
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;

    if (formData.email === '' || formData.email === null) {
      validFormData.email = false;
      isValid = false;
      const message = 'Email address is required';
      setErrorMsg(message);
    }

    if (formData.email && !emailRegex.test(formData.email)) {
      validFormData.isEmailValid = false;
      isValid = false;
      const message = 'Please enter valid email address';
      setErrorMsg(message);
    }

    if (
      isUserExists &&
      (!formData?.password ||
        formData?.password === '' ||
        formData?.password === null)
    ) {
      const message = 'Password is required';
      setErrorMsg(message);
      validFormData.password = false;
      isValid = false;
    }
    setValidForm((prevValidForm) => {
      const updatedState = { ...prevValidForm, ...validFormData };

      return updatedState;
    });

    return isValid;
  };

  const handleSubmit = (event: any) => {
    setErrorMsg('');

    event.preventDefault();

    if (!valid()) {
      return true;
    }

    // setValidForm({
    //   ...validForm,
    //   emailNotExists: true,
    //   inCorrectPassword: true,
    // });
    setIsLoading(true);

    const payload = {
      password: formData?.password,
      email: formData?.email,
    };

    (!isUserExists ? login(formData) : verifyPassword(payload))
      .then((response) => {
        setIsLoading(false);

        if (response?.token) {
          setIsAuthenticated(true);

          if (!response?.data?.isSetBasicDetail && response?.data?.isMainUser) {
            navigate(ROUTES.SET_DETAILS);
          } else if (
            !response?.data?.isSetBasicDetail &&
            !response?.data?.isMainUser
          ) {
            navigate(ROUTES.SET_BASIC_DETAILS);
          } else {
            navigate(ROUTES.HOME);
          }

          return true;
        }

        if (response?.isUserActive) {
          setValidForm({ ...validForm, emailNotExists: true });
          setIsUserExists(true);
        } else {
          setShowVerification(true);
          const navigateUrl = response?.data?.isSetPassword
            ? response?.data.isMainUser
              ? ROUTES.SET_BASIC_DETAILS
              : ROUTES.SET_DETAILS
            : ROUTES.CREATE_PASSWORD;

          setRedirectUrl(navigateUrl);
        }
      })
      .catch((error): any => {
        setIsLoading(false);

        if (!isUserExists) {
          setValidForm({ ...validForm, emailNotExists: false });
        } else {
          setValidForm({ ...validForm, inCorrectPassword: false });
        }

        setErrorMsg(
          error?.response?.data?.message || 'Something went wrong with login'
        );
      });
  };

  const googleSuccessResponse = (token: any) => {
    console.log(token);
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => googleSuccessResponse(tokenResponse),
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: `${window.WEB_APP_URL}/callback`,
  });
  console.log(handleGoogleLogin);

  // const microsoftAuthHandler = () => {
  //   const microsoftAuth = async () => {
  //     await msalInstance.initialize(); // Ensure MSAL is initialized

  //     msalInstance.loginRedirect({
  //       scopes: ['openid', 'profile', 'email'],
  //       responseMode: 'query', // Ensure query mode
  //     } as any);
  //   };

  //   microsoftAuth();
  // };

  const microsoftAuthHandler = () => {
    microsoftLogin()
      .then((response: any) => {
        if (response?.data?.url) {
          window.location.href = response?.data?.url;
        } else {
          navigate(ROUTES.LOGIN, {
            state: {
              message: 'Microsoft login failed. No matching account found.',
            },
          });
        }
      })
      .catch((error: any): any => {
        navigate(ROUTES.LOGIN, {
          state: {
            message:
              error?.response?.data?.message ||
              'Login failed due to Microsoft authentication error. Please try again later.',
          },
        });
      });
  };

  return (
    <>
      {!showVerification ? (
        <AuthWrapper
          authHeading="Login"
          authSubTitle="Welcome! Please enter your email."
        >
          <>
            <form onSubmit={handleSubmit} className="flex flex-col gap-y-4">
              <div>
                <InputText
                  label="Email"
                  id="email"
                  labelClassName="block mb-1.5"
                  inputName="email"
                  className={`input_text_auth${
                    !isUserExists &&
                    (!validForm.email ||
                      !validForm?.isEmailValid ||
                      !validForm?.emailNotExists)
                      ? ' !border-[#FDA29B] '
                      : ' !border-[#D0D5DD] '
                  }`}
                  placeholder="Enter your email"
                  value={formData.email}
                  onChangeFunc={handleInputChange}
                  // errorText={
                  //   !validForm.email
                  //     ? 'Email address is required'
                  //     : !validForm.isEmailValid
                  //     ? 'Please enter valid email address'
                  //     : null
                  // }
                  disabled={isUserExists}
                />
                {!isUserExists && <ErrorMsg errorText={errorMsg} />}
              </div>

              {isUserExists && (
                <div>
                  <InputText
                    label="Password"
                    id="password"
                    labelClassName="block mb-1.5"
                    inputType={showPassword ? 'text' : 'password'}
                    inputName="password"
                    className={`input_text_auth pr-[36px] ${
                      !validForm.password || !validForm?.inCorrectPassword
                        ? ' !border-[#FDA29B] '
                        : ' !border-[#D0D5DD] '
                    }`}
                    placeholder="Enter your password"
                    value={formData.password}
                    // errorText={
                    //   !validForm.password ? 'Password is required' : null
                    // }
                    onChangeFunc={handleInputChange}
                    icon={
                      <div
                        className="absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer peer-placeholder-shown:pointer-events-none peer-placeholder-shown:text-gray400"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <Eye className="w-4 h-4 " />
                        ) : (
                          <EyeOff className="w-4 h-4 " />
                        )}
                      </div>
                    }
                  />
                  {isUserExists && <ErrorMsg errorText={errorMsg} />}
                </div>
              )}

              <ButtonCmp
                type="submit"
                disabled={isLoading}
                className="btn_primary w-full mt-1"
                loading={isLoading}
              >
                Continue
              </ButtonCmp>
            </form>
            {isUserExists && (
              <>
                <div className="text-utilityTertiary600 text-sm font-normal text-center mt-6">
                  Forgot Your Password?&nbsp;
                  <Link
                    className="text-primary font-semibold cursor-pointer"
                    to={ROUTES.FORGOTPASSWORD}
                  >
                    Click here
                  </Link>
                </div>
              </>
            )}
            <div className="flex items-center mt-4 ">
              <div className="h-[1px] flex-1 bg-utilityGray200"></div>
              <p className="text-xs mx-2 text-textSecondary font-medium">Or</p>
              <div className="h-[1px] flex-1 bg-utilityGray200"></div>
            </div>
            <ButtonCmp
              type="button"
              className="mt-3 btn_secondary_black"
              // onClick={() => handleGoogleLogin()}
              onClick={() => false}
            >
              <img src={GoogleLogo} className="h-4" alt="logo" />
              Sign in with Google
            </ButtonCmp>
            <ButtonCmp
              type="button"
              onClick={() => microsoftAuthHandler()}
              className="mt-3 btn_secondary_black"
            >
              <img src={MicrosoftLogo} className="h-4" alt="logo" />
              Sign in with Microsoft
            </ButtonCmp>
            {location.state?.message && (
              <div className="flex pt-3 pl-[53px] ">
                <ErrorMsg errorText={location.state.message} />
              </div>
            )}
          </>
        </AuthWrapper>
      ) : (
        <VerifyOtp
          redirectUrl={redirectUrl}
          email={formData?.email}
          setShowVerification={setShowVerification}
          login={true}
        />
      )}
    </>
  );
};

export default Login;
