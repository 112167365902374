import { Edit01, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useRef, useState, useEffect } from 'react';
import { DATE_FORMAT } from 'src/constants/common';

import BadgeCmp from '../../../../components/BadgeCmp';
import TooltipCmp from '../../../../components/TooltipCmp';
import { getShortName, onError } from '../../../../utils/CommonFunctions';

interface IProps {
  data: any;
  keyValue: string;
  handleActionType: any;
}

const MemberRow = ({ data, handleActionType }: IProps) => {
  const nameRef = useRef<HTMLHeadingElement>(null);
  const emailRef = useRef<HTMLParagraphElement>(null);
  const [isNameTruncated, setIsNameTruncated] = useState(false);
  const [isEmailTruncated, setIsEmailTruncated] = useState(false);

  useEffect(() => {
    if (nameRef.current) {
      setIsNameTruncated(
        nameRef.current.scrollWidth > nameRef.current.offsetWidth
      );
    }

    if (emailRef.current) {
      setIsEmailTruncated(
        emailRef.current.scrollWidth > emailRef.current.offsetWidth
      );
    }
  }, [data.name, data.email]);

  return (
    <>
      <td className=" px-5 py-4 w-[25%] max-w-[1px] min-w-[25%]">
        <div className="flex items-center gap-x-3">
          <div className="table-profile-wrap notification-mark-wrap">
            {data.image ? (
              <img
                className="table-profile-icn-circle-xl"
                src={data.imageUrl + data.image}
                alt={data.name}
                title={data.name}
                onError={onError}
              />
            ) : (
              <div className="table-profile-user-circle-xl">
                {getShortName(`${data?.name || data?.email}`)}
              </div>
            )}
          </div>
          <div className="truncate">
            {data?.name && (
              <TooltipCmp message={isNameTruncated ? data.name : ''}>
                <h6
                  ref={nameRef}
                  className="truncate text-grayLight900 font-medium"
                >{`${data.name}`}</h6>
              </TooltipCmp>
            )}
            <TooltipCmp message={isEmailTruncated ? data.email : ''}>
              <p ref={emailRef} className="truncate">
                {data.email}
              </p>
            </TooltipCmp>
          </div>
        </div>
      </td>
      <td className="px-5 py-4">
        <div className="flex">
          {data?.role ? (
            <BadgeCmp
              style="modern"
              type={data.role.toLowerCase() === 'user' ? 'primary' : 'success'}
            >
              {data?.role?.toLowerCase()}
            </BadgeCmp>
          ) : (
            '-'
          )}
        </div>
      </td>

      <td className="px-5 py-4 ">
        <span>
          {data?.lastLoginAt
            ? moment(data?.lastLoginAt).format(DATE_FORMAT)
            : '-'}
        </span>
      </td>
      <td className="px-5 py-4 ">
        {data.isActive ? (
          <BadgeCmp style="modern" type="success">
            Active
          </BadgeCmp>
        ) : (
          <BadgeCmp style="pill" type="warning">
            Pending
          </BadgeCmp>
        )}
      </td>

      <td className="px-5 py-4 w-[84px] max-w-[84px] min-w-[84px]">
        <div className="items-center flex gap-3 justify-end">
          {!data?.isMainUser && (
            <>
              <TooltipCmp message={'Edit Member'}>
                <Edit01
                  className="w-4 h-4 relative cursor-pointer "
                  onClick={handleActionType('member', 'member-edit', data)}
                />
              </TooltipCmp>
              <TooltipCmp message={'Delete Member'}>
                <Trash01
                  className="w-4 h-4 relative cursor-pointer"
                  onClick={handleActionType('member', 'member-delete', data)}
                />
              </TooltipCmp>
            </>
          )}
        </div>
      </td>
    </>
  );
};

export default MemberRow;
