import { ChevronDown } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { Link } from 'react-router-dom';
import TooltipCmp from 'src/components/TooltipCmp';
import { STATUS } from 'src/constants/common';

interface QuoteDetailTableRowProps {
  data: any; // You might want to replace 'any' with the specific data type
}

const QuoteDetailTableLoadingRow: React.FC<QuoteDetailTableRowProps> = ({
  data,
}) => (
  <>
    <td className="px-5 py-4 min-w-[18%] max-w-[1px] w-[18%]">
      <div className="flex items-center gap-3">
        <div className="table-profile-user-circle-xl custom-loading ">WA</div>
        <div className="truncate custom-loading">
          <h6 className="text-grayLight900 font-medium leading-tight truncate">
            Dev User
          </h6>
          <p className="text-grayLight600 font-normal leading-tight truncate">
            #SQ-L-205584
          </p>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 min-w-[16%] max-w-[1px] w-[16%]">
      <p className="custom-loading">FTL</p>
    </td>
    {data?.status === STATUS.WON && (
      <td className="px-5 py-4 min-w-[16%] max-w-[1px] w-[16%]">
        <Link to={``} className="underline text-primary custom-loading">
          #SQ-L-205584
        </Link>
      </td>
    )}

    <td className="px-5 py-4 min-w-[20%] max-w-[1px] w-[20%]">
      <TooltipCmp>
        <p className="flex truncate max-w-[80%] custom-loading">
          <span className="text-gray500 font-normal">From:&nbsp;</span>
          <span className="font-medium truncate">
            9700 Jasper Ave, Edmonton, AB T5
          </span>
        </p>
      </TooltipCmp>
      <TooltipCmp>
        <p className="flex truncate max-w-[80%] custom-loading">
          <span className="text-gray500 font-normal">To:&nbsp;</span>
          <span className="font-medium truncate">
            9700 Jasper Ave, Edmonton, AB T5
          </span>
        </p>
      </TooltipCmp>
    </td>
    <td className="px-5 py-4 min-w-[14%] max-w-[14%] w-[14%]">
      <span className="custom-loading">Nov 6th - 2024</span>
    </td>
    <td className="px-5 py-4 min-w-[14%] max-w-[14%] w-[14%]">
      <span className="custom-loading">400 lbs</span>
    </td>
    <td className="px-5 py-4 min-w-[14%] max-w-[14%] w-[14%]">
      <span className="custom-loading">2</span>
    </td>
    <td className="px-5 py-4 min-w-[65px] w-[65px] max-w-[65px]">
      <div className="custom-loading">
        <ChevronDown className="w-4 h-4" />
      </div>
    </td>
  </>
);

export default QuoteDetailTableLoadingRow;
