import { Check } from '@untitled-ui/icons-react/build/cjs';
import React, { useMemo } from 'react';
import AccordionCmp from 'src/components/AccordionCmp';
import BadgeCmp from 'src/components/BadgeCmp';
import TableCmp from 'src/components/TableCmp';

import DimensionsOrderDetailRow from './DimensionsOrderDetailRow';

const orderDimension: any = [
  {
    quantity: '01',
    handlingUnit: 'Pallet',
    dimensions: '90 X 50 X 40',
    class: '-',
    totalWeight: '20 (LBS)',
    refNumber: '55KDLF351',
    description: 'Laptops',
  },
];

const OrderDetails = () => {
  const headCells = useMemo(
    () => [
      {
        id: 'quantity',
        name: 'Quantity',
        rowClassName: '',
      },
      {
        id: 'handlingUnit',
        name: 'Handling Unit',
        rowClassName: '',
      },
      {
        id: 'dimensions',
        name: 'Dimensions (Inches)',
        rowClassName: '',
      },
      {
        id: 'class',
        name: 'Class',
        rowClassName: '',
      },
      {
        id: 'totalWeight',
        name: 'Total Weight',
        rowClassName: '',
      },
      {
        id: 'refNumber',
        name: 'REF Number',
        rowClassName: '',
      },
      {
        id: 'description',
        name: 'Description',
        rowClassName: '',
      },
    ],
    []
  );

  const otherAddtionalService: any = [
    {
      name: 'Protect from Freeze',
    },
    {
      name: 'Flatbed',
    },
    {
      name: 'In-Bond',
    },
  ];

  return (
    <>
      <h6 className="text-textSecondary text-xs font-medium mb-1 leading-[1.5]">
        Order Details
      </h6>

      <AccordionCmp
        title={
          <div className="flex flex-wrap items-start flex-1 gap-x-3">
            <div className="flex-1">
              <h6 className="text-grayLight600 text-xs font-normal mb-1">
                Order Type
              </h6>
              <BadgeCmp
                style="modern"
                type="success"
                mainClassName={`!flex w-fit `}
              >
                {'LTL'}
              </BadgeCmp>
            </div>
            <div className="flex-1">
              <h6 className="text-grayLight600 text-xs font-normal mb-1">
                PO number
              </h6>
              <p className={`text-textSecondary text-sm font-medium `}>
                {'58923354'}
              </p>
            </div>
            <div className="flex-1">
              <h6 className="text-grayLight600 text-xs font-normal mb-1">
                Reference Number
              </h6>
              <p className={`text-textSecondary text-sm font-medium `}>
                {'156GJI584HJBHD'}
              </p>
            </div>
            <div className="flex-1">
              <h6 className="text-grayLight600 text-xs font-normal mb-1">
                Linear Footage
              </h6>
              <p className={`text-textSecondary text-sm font-medium `}>
                {'78'}
              </p>
            </div>
            <div className="flex-1">
              <h6 className="text-grayLight600 text-xs font-normal mb-1">
                Declared Value
              </h6>
              <p className={`text-textSecondary text-sm font-medium `}>
                {'58923354'}
              </p>
            </div>
          </div>
        }
        className="!bg-gray25 !p-5 !rounded-xl !shadow-xs"
        contentClassName="!bg-gray25 flex flex-col gap-y-4"
        contentParentClass="!border-utilityGray200 !mt-4"
      >
        <div>
          <h6 className="form_label block mb-1.5">Dimensions</h6>
          <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
              <TableCmp
                headCells={headCells}
                tableDataArr={orderDimension}
                // TableLoaderRowCmp={CarriersListLoading}
                TableRowCmp={DimensionsOrderDetailRow}
              />
            </div>
          </div>
        </div>

        {otherAddtionalService && otherAddtionalService?.length > 0 && (
          <div>
            <h6 className="form_label block mb-1.5">
              Other Additional Services
            </h6>
            <div className="rounded-xl border border-utilityGray200 bg-white shadow-sm px-4 py-1.5">
              <div className="flex flex-wrap -mx-5">
                {otherAddtionalService?.map((service: any, index: number) => (
                  <div
                    className="flex gap-2 text-grayLight900 truncate px-5 py-2.5 w-1/4"
                    key={index}
                  >
                    <Check className="w-4 h-4 text-primary flex-none" />
                    <h6 className="truncate text-xs"> {service?.name}</h6>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </AccordionCmp>
    </>
  );
};

export default OrderDetails;
