import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import defaultImage from 'src/assets/img/default-image.jpg';
import profile1 from 'src/assets/img/tick-icon.svg';
import InputText from 'src/components/InputText/InputText';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';

import {
  IIntegration,
  IIntegrationTypeObject,
  IntegrationData,
  IParams,
} from './Integration.interface';

const initParams: IParams = {
  search: '',
};

const Integration = () => {
  // const location = useLocation();
  const [integrationList, setIntegrationList] = useState<
    IIntegrationTypeObject[]
  >([]);

  const [params, setParams] = useState<IParams>(initParams);
  // const [firstModalOpen, setFirstModalOpen] = useState(true);
  // const [isIntegrationEnableLoading, setIsIntegrationEnableLoading] = useState<boolean>(false);

  const fetchIntegrationList = () => {
    setIntegrationList(IntegrationData);
    // listIntegration(params)
    //   .then((response: any) => {
    //     if (response.data) {
    //       setIntegrationList(response.data);
    //     }
    //   })
    //   .catch((e: any) => {
    //     console.log(e);
    //   })
    //   .finally(() => setRefresh(false));
  };

  useEffect(() => {
    fetchIntegrationList();
  }, [params]);

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   const modalOpenFor = searchParams.get('isModalOpen');

  //   if (modalOpenFor) {
  //     const foundIntegration = integrationList
  //       .flatMap((group) => group.integrations)
  //       .find(
  //         (integration) =>
  //           integration.slug.toLowerCase() === modalOpenFor.toLowerCase()
  //       );

  //     if (foundIntegration && firstModalOpen) {
  //       setSelectedIntegration(foundIntegration);
  //       setIsViewIntegrationModal(true);
  //     }
  //   }
  // }, [integrationList]);

  return (
    <PageSectionLayout
      header={
        <Header
          title="Manage Integrations"
          desc="Maximize organizational productivity by integrating with your favorite tools."
          mainContainerClassName="mdm:!flex-row !flex-row"
          rightClassName="mdm:!w-fit md:!w-full sm:!w-fit"
          leftClassName="mdm:!w-auto md:!w-full sm:w-auto"
          rightSideContent={
            <InputText
              inputName="searchAdvance"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 font-normal search-input "
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={params.search}
              inputType="text"
              isClearable={true}
              onChangeFunc={(e: any) => {
                setParams((old: IParams) => ({
                  ...old,
                  search: e.target.value,
                }));
              }}
              parentClassName="xls:min-w-80 mdm:min-w-64 md:min-w-full sm:min-w-[180px] min-w-[280px]"
            />
          }
        />
      }
    >
      <div className="flex flex-col gap-14 mb-8">
        {integrationList.map(
          (integration: IIntegrationTypeObject, index: number) => (
            <div key={`integration-${index}`}>
              <h6 className="mb-4 text-grayLight900 font-semibold text-base tracking-[-0.47px]">
                {integration.typeDisplayName}
              </h6>
              <ul className="xl:-m-3 -m-2 flex flex-wrap">
                {integration?.integrations.map(
                  (integrationItem: IIntegration) => (
                    <li
                      key={`integration-item-${integrationItem.id}`}
                      className="xl:p-3 p-2 xl:w-1/4 xls:w-1/3 mdm:w-[350px] md:w-full sm:w-1/2 w-full"
                    >
                      <div className="hover-text-wrap rounded-xl border border-utilityGray200 p-6 flex gap-2 h-full cursor-pointer shadow-xs  hover:shadow-[0_-1px_4px_0px_rgba(88,104,116,0.12),0_1px_4px_0px_rgba(88,104,116,0.12)] sm:min-h-[130px]">
                        <img
                          src={
                            integrationItem.logo
                              ? integrationItem.logoUrl + integrationItem.logo
                              : defaultImage
                          }
                          className=" rounded w-6 h-6 object-contain"
                        />
                        <div className="w-[calc(100%_-_32px)]">
                          <div className="flex items-center gap-2">
                            <h6 className="text-grayLight900 text-sm font-semibold max-w-[calc(100%_-_103px)] truncate">
                              {integrationItem.name}
                            </h6>
                            {integrationItem.isEnable && (
                              <div className="hover-text text-success700 items-center bg-[#eefcf6] px-[1px] h-6 flex  rounded-full text-xs font-semibold transition-all duration-[0.3s]">
                                <img src={profile1} className="mx-1" />
                                <span className="opacity-0 w-0 font-semibold transition-all duration-[0.5s] text-[#0d442e] text-xs leading-[20px]">
                                  Connected
                                </span>
                              </div>
                            )}
                          </div>
                          <p className="text-gray500 text-sm font-normal [&:not:empty]:mt-1 line-clamp-3">
                            {integrationItem.shortDescription}
                          </p>
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          )
        )}
      </div>
    </PageSectionLayout>
  );
};

export default Integration;
