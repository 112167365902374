import { Mail01, UploadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import PhoneInputCmp from 'src/components/PhoneInputCmp/PhoneInputCmp';
import { AuthContext } from 'src/context/AuthContext';
// import { getUser } from 'src/services/AuthService';
// import { listRole } from 'src/services/RoleService';
import { updateSetting } from 'src/services/SettingService';
import { getShortName, onError } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import defaultImage from '../../assets/img/default-image.jpg';

const AccountSettings = () => {
  const initValidForm = {
    name: true,
    email: true,
    title: true,
    image: true,
    phone: true,
    phone_valid: true,
    image_size_valid: true,
  };

  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [isFormSubmitted, setIsFormSubmitted] = useState<any>(false);

  const initFormData = {
    name: currentUser.name || '',

    email: currentUser.email || '',
    phone: currentUser.phone || '',
    title: currentUser.title || '',
    image: '',
  };
  const [formData, setFormData] = useState<any>(initFormData);
  const [validForm, setValidForm] = useState(initValidForm);
  const [displayImage, setDisplayImage] = useState<any>(null);
  // const [roleOptions, setRoleOptions] = useState([]);
  // const [isRoleLoading, setIsRoleLoading] = useState(true);
  const [isUpdateProfile, setIsUpdateProfile] = useState(false);

  useEffect(() => {
    const defaultProfileImage = currentUser?.image
      ? `${currentUser?.imageUrl}${currentUser?.image}`
      : null;
    setDisplayImage(defaultProfileImage);
    setFormData({
      name: currentUser.name || '',
      email: currentUser.email || '',
      phone: currentUser.phone || '',
      image: defaultProfileImage,
      title: currentUser.title || '',
    });
  }, [currentUser]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      setDisplayImage(null);

      if (file.type.match('image')) {
        setFormData({ ...formData, ['image']: file });
      }

      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const img = new Image();

          img.onload = () => {
            if (img.width > 800 || img.height > 800) {
              setValidForm({
                ...validForm,
                ['image_size_valid']: false,
                ['image']: true,
              });
            } else {
              setValidForm({
                ...validForm,
                ['image_size_valid']: true,
                ['image']: true,
              });
            }
            setDisplayImage(img.src);
          };
          img.src = e.target!.result as string;
        };
        reader.readAsDataURL(file);
      }
    },
    [formData, validForm]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/svg+xml': ['.svg'],
    },
  });

  const isFormValid = () => {
    let valid = true;
    const validFormData = { ...validForm };

    if (formData.name === '') {
      validFormData.name = false;
      valid = false;
    }

    if (formData.title === '') {
      validFormData.title = false;
      valid = false;
    }

    if (formData.email === '' || formData.email === null) {
      validFormData.email = false;
      valid = false;
    }

    if (formData.phone === '' || formData.phone === null) {
      validFormData.phone = false;
      valid = false;
    }

    if (displayImage === null || displayImage === '') {
      validFormData.image = false;
      valid = false;
    } else {
      validFormData.image = true;
    }

    setValidForm({ ...validForm, ...validFormData });

    return valid;
  };

  const handleInputChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const hasAnyFormValueFalse = (object: any) => {
    for (let key in object) {
      if (key === 'image' || key === 'phone_country_code') {
        continue;
      }

      if (!object[key]) {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsFormSubmitted(true);

    if (!isFormValid() || !hasAnyFormValueFalse(validForm)) {
      return true;
    }
    setIsFormSubmitted(false);
    setIsUpdateProfile(true);
    const appendFormData: any = new FormData();
    Object.entries(formData).forEach((data) => {
      const [key, value] = data;
      appendFormData.append(key, value);
    });

    // pushing image to last
    const imageValueOfFormToAppend = appendFormData.get('image');
    appendFormData.delete('image');

    if (imageValueOfFormToAppend) {
      appendFormData.append('image', imageValueOfFormToAppend);
    }

    updateSetting(appendFormData)
      .then((response: any) => {
        setCurrentUser(response?.data);
        WalToast.success('Account setting have been updated successfully');
      })
      .finally(() => setIsUpdateProfile(false))
      .catch(() => WalToast.error('Profile have been not updated'));
  };

  // const handleNumberChange = (
  //   country: any,
  //   phone: string,
  //   code: string,
  //   isValid: boolean
  // ) => {
  //   // setFormData({
  //   //   ...formData,
  //   //   ['phone']: phone === '' || phone === null ? '' : `+${code}${phone}`,
  //   // });

  //   setFormData((old: any) => ({
  //     ...old,
  //     phone: phone === '' || phone === null ? '' : `+${code}${phone}`,
  //   }));

  //   if ((phone !== '' || phone !== null) && isValid) {
  //     setValidForm({
  //       ...validForm,
  //       ...{ phone: true, phone_valid: isValid },
  //     });
  //   } else {
  //     setValidForm((old: any) => ({
  //       ...old,
  //       phone_valid: isValid,
  //     }));
  //   }
  // };

  const handleNumberChange = (phone: any, isValid: any) => {
    setFormData({
      ...formData,
      phone: phone === '' || phone === null ? '' : `${phone}`,
    });
    setValidForm({
      ...validForm,
      phone: true,
      phone_valid: isValid,
    });
  };

  return (
    <PageSectionLayout
      header={
        <Header
          title="Account Settings"
          desc="Here’s an overview of your recent activity and orders picking up or delivering today."
        />
      }
    >
      <form className="h-full w-full px-2 py-1" onSubmit={handleSubmit}>
        <div className="w-full flex justify-between border-b border-utilityGray200 pb-4">
          <div>
            <h6 className="text-grayLight900 font-semibold text-sm">Profile</h6>
            <p className="text-grayLight600 font-normal text-sm">
              Update your photo and personal details here.
            </p>
          </div>
        </div>

        <div className="mt-4">
          <div className="border-b border-utilityGray200 pb-4 flex sm:gap-8 gap-2 sm:flex-row flex-col">
            <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
              Name
            </label>
            <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full flex gap-4">
              <InputText
                inputName="name"
                placeholder="Enter customer name"
                parentClassName="flex-1"
                value={formData.name}
                onChangeFunc={handleInputChange}
                errorText={!validForm.name ? 'Name is required' : null}
              />

              {/* <InputText
                inputName="lastName"
                placeholder="LastName"
                value={formData.lastName}
                onChangeFunc={handleInputChange}
                className="shadow placeholder:text-gray500  text-black/90"
                parentClassName="w-1/2"
                errorText={!validForm.lastName ? 'Last name is required' : null}
              /> */}
            </div>
          </div>
          <div className="border-b border-utilityGray200 py-4 flex sm:gap-8 gap-2 sm:flex-row flex-col">
            <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
              Email
            </label>
            <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full flex gap-4">
              <InputText
                inputType="email"
                inputName="Email"
                placeholder="Email"
                value={formData.email}
                iconLeft={
                  <Mail01 className="h-4 w-4 cursor-pointer absolute left-2 mr-2 text-black/90" />
                }
                onChangeFunc={handleInputChange}
                className="shadow placeholder:text-gray500 disabled:text-gray500 text-black/90 disabled:bg-gray50 pl-7"
                parentClassName="w-full"
                // disabled={true}
                errorText={!validForm.email ? 'email is required' : null}
              />
            </div>
          </div>
          <div className="border-b border-utilityGray200 py-4 flex sm:gap-8 gap-2 sm:flex-row flex-col">
            <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
              Virtual Phone Number
            </label>
            <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full flex gap-4">
              {/* <div className="grid lg:grid-cols-2 grid-col-1 xxl:gap-5 xl:gap-4 gap-3">
                <div className="md:flex flex-col">
                  <label className="form_label ">Phone number*</label> */}
              {/* <PhoneInput
                onNumberChange={() => {}}
                name="phone"
                errors={!validForm.phone ? 'Phone is required' : null}
                // className="w-full"
                value={formData?.phone || ''}
              /> */}
              <PhoneInputCmp
                phoneInputWrapClass=""
                value={formData?.phone}
                onChange={handleNumberChange}
                isFormSubmitted={isFormSubmitted}
                errorMessage={
                  !validForm.phone
                    ? 'Phone number is required.'
                    : !validForm.phone_valid
                    ? 'Invalid phone number'
                    : ''
                }
              />
            </div>
          </div>
          <div className="border-b border-utilityGray200 py-4 flex sm:gap-8 gap-2 sm:flex-row flex-col">
            <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
              <div className="flex items-center gap-0.5">Profile picture</div>
              <p className="text-grayLight600 font-normal">
                This will be displayed on your profile.
              </p>{' '}
            </label>
            <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full ">
              <div className="flex gap-4">
                {displayImage ? (
                  <img
                    className="w-14 h-14 border-[0.75px] border-black/[0.08] rounded-full object-cover opa"
                    src={displayImage}
                    alt={formData.firstName}
                    title={formData.firstName}
                  />
                ) : formData.image ? (
                  <label>
                    <img
                      className="w-14 h-14 border-[0.75px] border-black/[0.08] rounded-full object-cover"
                      src={currentUser.imageUrl + currentUser.image}
                      alt={formData.firstName}
                      title={formData.firstName}
                      onError={onError}
                    />
                  </label>
                ) : formData.firstName ? (
                  <label className="flex-none w-14 h-14 rounded-full cursor-pointer text-2xl font-medium bg-primary  text-white border-[0.75px] border-black/[0.08] flex items-center justify-center uppercase">
                    {getShortName(`${formData.firstName} ${formData.lastName}`)}
                  </label>
                ) : (
                  <label className="cursor-pointer">
                    <img
                      className="w-14 h-14 border-[0.75px] border-black/[0.08] rounded-full object-cover"
                      src={defaultImage}
                      alt={formData.firstName}
                      title={formData.firstName}
                    />
                  </label>
                )}

                <label
                  className="cursor-pointer flex-auto rounded-xl border border-gray100 py-3.5 px-5 text-center bg-white"
                  htmlFor="fileSelect"
                  {...getRootProps()}
                >
                  <div className="mx-auto mb-3 w-9 h-9 flex items-center justify-center rounded-lg border border-utilityGray200 shadow">
                    <UploadCloud02 className="w-4 h-4 text-textSecondary" />
                  </div>
                  <div className="text-grayLight600 text-xs font-normal">
                    <p>
                      <span className="text-[#2422DD] font-semibold pr-1">
                        Click to upload{' '}
                      </span>
                      or drag and drop
                    </p>
                    <p className="text-[11px] leading-[18px]">
                      SVG, PNG or JPG (max. 800x400px)
                    </p>
                  </div>
                </label>
                <input
                  type="file"
                  name="image"
                  id="fileSelect11"
                  className="hidden"
                  {...getInputProps()}
                />
              </div>
              <span className="text-xs font-normal text-red-600 dark:text-red-500">
                {!validForm.image ? 'Profile Image is required.' : null}
                {!validForm.image_size_valid && validForm.image
                  ? 'Image dimensions must be less than or equal to 800 x 800'
                  : null}
              </span>
            </div>
          </div>

          <div className="border-b border-utilityGray200 py-4 flex sm:gap-8 gap-2 sm:flex-row flex-col">
            <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
              Title
            </label>
            <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full flex gap-4">
              <InputText
                inputName="title"
                placeholder="Enter title"
                parentClassName="flex-1"
                className="p-2 flex-1 h-[unset] disabled:bg-gray50 disabled:text-gray500"
                value={formData.title}
                // disabled={!hasRoleV2('admin')}
                onChangeFunc={handleInputChange}
                errorText={!validForm.title ? 'Title is required' : null}
              />
            </div>
          </div>

          <div className="flex justify-end gap-4 py-4">
            <ButtonCmp
              type="button"
              className="btn_secondary_black shadow py-2 px-3"
              onClick={() => {
                setFormData(initFormData);
              }}
            >
              Cancel
            </ButtonCmp>
            <ButtonCmp
              type="submit"
              className="btn_primary  shadow py-2 px-3"
              loading={isUpdateProfile}
              disabled={isUpdateProfile}
            >
              Save
            </ButtonCmp>
          </div>
        </div>
      </form>
    </PageSectionLayout>
  );
};

export default AccountSettings;
