import React from 'react';

import { IRadioProps } from './Radio.interface';

const Radio = ({
  inputName,
  tabIndex,
  disabled,
  checked,
  onChangeFunc,
  onFocus,
  required,
  readOnly,
  inputClassName,
  radioLabel,
  radioBox,
  radioWrapClass,
  ...props
}: IRadioProps) => (
  <label
    className={`flex-none custom-radio cursor-pointer flex relative ${radioWrapClass} ${
      radioBox ? 'px-3 py-2.5' : 'w-4 h-4'
    }`}
  >
    <input
      {...props}
      className={`opacity-0 absolute cursor-pointer ${inputClassName}`}
      type="radio"
      name={inputName}
      onChange={onChangeFunc}
      onFocus={onFocus}
      tabIndex={tabIndex}
      checked={checked}
      disabled={disabled}
      required={required}
      readOnly={readOnly}
    />
    <span
      className={`checkmark h-full w-full absolute left-0 top-0 flex items-center justify-center after:content-[''] after:w-1.5 after:h-1.5 after:rounded-full after:bg-white after:transition-all transition-all ${
        radioBox
          ? 'after:hidden radiobox-checkmark rounded-lg'
          : 'rounded-full '
      } `}
    ></span>
    <p className="radio-label text-xs font-semibold">{radioLabel}</p>
  </label>
);

export default Radio;
