import {
  Check,
  Copy05,
  DotsVertical,
  Edit05,
  HelpCircle,
  RefreshCcw05,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import NotFoundUI from 'src/components/NotFoundUI';
import OutsideClickHandler from 'src/components/OutsideClickHandler';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  QUOTE_CLASSIFICATION,
  ROSE_ROCKET,
  STATUS,
} from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { ROUTES } from 'src/constants/routes';
import {
  cancelQuote,
  getQuote,
  // getQuoteDetail,
  modifyQuote,
} from 'src/services/QuoteService';
import WalToast from 'src/utils/WalToast';

// import CarrierListLoading from './CarrierListLoading';
import CarrierListLoading from './CarrierListLoading';
import CarrierLisRow from './CarrierListRow';
import CloseQuoteConfirm from './CloseQuoteConfirm';
import ConfirmOrder from './ConfirmOrder';
import QuoteDetailTable from './QuoteDetailTable';

const initAction = {
  mode: '',
  id: 0,
  data: {},
};

const QuoteDetail = () => {
  const { id } = useParams<{ id: any }>();
  const [colors, setColors] = useState<any>({});
  const navigate = useNavigate();

  const [addressDetails, setAddressDetails] = useState<any>({});
  const [dimensions, setDimensions] = useState<any[]>([]);
  const [services, setServices] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalUnit, setTotalUnit] = useState<number | string>();
  const [totalWeight, setTotalWeight] = useState<number | string>();
  const [isShowActionButtons, setIsShowActionButtons] = useState(false);
  const [isCustomerDeleted, setIsCustomerDeleted] = useState(false);
  const [isShowCloseReasonModal, setIsShowCloseReasonModal] = useState(false);
  const [isQuoteCloseLoading, setIsQuoteCloseLoading] = useState(false);
  const [carrierList, setCarrierList] = useState<any>([]);
  const [action, setAction] = useState<any>(initAction);

  const headCellsQuoted = useMemo(
    () => [
      {
        id: 'carrier',
        name: '',
        sortable: true,
        visible: true,
      },
      {
        id: 'orderID',
        name: 'fullId',
        visible: status === STATUS.WON ? true : false,
      },
      {
        id: 'projectedDeliveryDate',
        name: 'Est. Delivery Date',
        sortable: true,
      },
      {
        id: 'transitTime',
        name: 'Transit time',
      },
      {
        id: 'finalCharge',
        name: 'Total Cost',
        sortable: true,
      },
      {
        id: 'action',
        rowClassName: 'pl-0',
        visible: status === STATUS.ACTIVE ? true : false,
      },
    ],
    [status]
  );

  const tooltipItems = (
    <div className="">
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center mb-[1px]">
        <Edit05 className="w-4 h-4 text-gray500" />
        Modify quote
      </div>
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center mb-[1px]">
        <RefreshCcw05 className="w-4 h-4 text-gray500" />
        Rollover
      </div>
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center mb-[1px]">
        <XClose className="w-4 h-4 text-gray500" />
        No quote
      </div>
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center">
        <HelpCircle className="w-4 h-4 text-gray500" />
        Request help
      </div>
    </div>
  );

  const handleAllColors = (quoteStatus: any) => {
    const status = quoteStatus;
    const colorsObj = {
      badgeColor: ' bg-gray-100 ',
      badgeText: ' text-grayText ',
      textColor: ' text-black ',
      bgColor: '',
      borderColor: '',
    };

    switch (status) {
      case STATUS.OPENED:
        colorsObj.badgeColor = ' bg-primary50 ';
        colorsObj.badgeText = ' text-primary ';
        colorsObj.borderColor = ' border-utilityBlue200 ';
        break;
      case STATUS.ACTIVE:
        colorsObj.badgeColor = ' bg-primary50 ';
        colorsObj.badgeText = ' text-primary ';
        colorsObj.borderColor = ' border-utilityBlue200 ';
        break;
      case STATUS.WON:
        colorsObj.badgeColor = ' bg-green100 ';
        colorsObj.badgeText = ' text-green ';
        colorsObj.textColor = ' text-green ';
        colorsObj.bgColor = ' bg-green100 ';
        colorsObj.borderColor = ' border-green ';
        break;
      case STATUS.LOST:
      case STATUS.EXPIRED:
        colorsObj.badgeColor = ' bg-red-100 ';
        colorsObj.badgeText = ' text-danger ';
        colorsObj.borderColor = ' border-danger ';
        break;
      default:
        break;
    }
    setColors(colorsObj);
  };

  const copyDebounce = useCallback(
    debounce((copyQuote: string) => {
      navigator.clipboard.writeText(copyQuote);
      WalToast.copy('Copied to clipboard', '', 'copy-msg');
    }, 0),
    []
  );

  const getQuoteById = () => {
    if (id) {
      localStorage.removeItem('isModifyQuoteFromQuote');
      localStorage.removeItem('isPickupDateChanged');
      localStorage.removeItem('modifyQuoteCode');

      getQuote(id)
        .then((response: any) => {
          if (
            response &&
            response.data &&
            response.data.addressDetails &&
            response.data.addressDetails.status !== STATUS.PENDING
          ) {
            const responseData = response.data;

            setIsCustomerDeleted(
              responseData?.addressDetails.isCustomerDeleted
            );

            if (responseData?.addressDetails) {
              setAddressDetails(responseData?.addressDetails);
            }

            if (responseData?.dimensions) {
              setDimensions(responseData.dimensions);
              let unit: number | string = 0;
              let weight = 0;

              responseData.dimensions.forEach((val: any) => {
                unit += val.handlingUnitNo || 0;
                weight += val.totalWeight;
              });

              let carrierIds = responseData?.carrierRates
                .filter(
                  (val: any) =>
                    val.carrierStatus === responseData?.addressDetails.status
                ) // Filter out inactive carriers
                .map((val: any) => val); // Map to get only the carrier IDs

              // let carrierIds = responseData.carrierRates
              //   .filter((val: any) => val.carrierStatus === 'Active') // Filter out inactive carriers
              //   .map((val: any) => val); // Map to get only the carrier IDs

              setCarrierList(carrierIds);

              if (
                responseData.dimensions?.length &&
                responseData.dimensions[0].handlingUnitName
              ) {
                unit = `${unit} ${responseData.dimensions[0].handlingUnitName}`;
              }
              setTotalWeight(weight);
              setTotalUnit(unit);
            } else {
              setTotalWeight(responseData?.addressDetails?.totalWeight);
            }

            if (responseData?.additionalService) {
              setServices(responseData?.additionalService);
            }
            setIsLoading(false);

            if (
              responseData?.addressDetails?.classification ===
                QUOTE_CLASSIFICATION.FTL ||
              responseData?.addressDetails?.classification ===
                QUOTE_CLASSIFICATION.LTL
            ) {
              handleAllColors(responseData?.addressDetails?.status);
            }
          } else {
            navigate(ROUTES.MY_QUOTE);
          }
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getQuoteById();
  }, []);

  const handleQuoteClose = (closeReasonPayload: any) => {
    setIsShowCloseReasonModal(false);
    setIsQuoteCloseLoading(true);
    cancelQuote(closeReasonPayload, id)
      .then((response: any) => {
        if (response && response.data) {
          WalToast.success('Order Closed Successfully');
          setIsShowCloseReasonModal(false);
          // setIsRefresh(true);
        }
      })
      .finally(() => {
        setIsQuoteCloseLoading(false);
      })
      .catch((error: any) => {
        WalToast.error(
          error?.response?.data?.message || 'Something went wrong',
          ''
        );
      });
  };

  const modifyQuoteStatus = (quoteStatusId: number) => () => {
    return false;
    modifyQuote({ quoteId: quoteStatusId })
      .then((response) => {
        if (response && response.data) {
          const newQuoteId = response.data?.id;
          const isModifyQuoteFromQuote: any = quoteStatusId;
          localStorage.setItem(
            'isModifyQuoteFromQuote',
            isModifyQuoteFromQuote
          );
          localStorage.setItem('modifyQuoteCode', addressDetails?.code);

          if (response.data?.isDateChange) {
            localStorage.setItem(
              'isPickupDateChanged',
              response.data?.isDateChange
            );
          }

          if (
            isCustomerDeleted ||
            response.data?.classification === QUOTE_CLASSIFICATION.FTL
          ) {
            navigate(`${PATH.ADDRESS_DETAILS}/${newQuoteId}`);
          } else {
            navigate(`${PATH.DIMENSIONS}/${newQuoteId}`);
          }
        }
      })
      .catch(() => WalToast.error('Quote has not been modified', ''));
  };

  const handleSubmit = () => {
    navigate(ROUTES.MY_QUOTE);
  };

  const rightSideContent = (
    <div className="flex sms:flex-nowrap flex-wrap sm:justify-end justify-start items-center lg:gap-x-4 sm:gap-x-3 gap-x-2 mdm:w-auto md:w-full whitespace-nowrap">
      <div className="!hidden">
        {!isLoading && (
          <>
            <TooltipCmp
              message={tooltipItems}
              type="light"
              openOnClick
              isArrow={false}
            >
              <ButtonCmp
                type="submit"
                className="btn_secondary_black lg:!p-[9px] !p-2"
              >
                <DotsVertical className="w-4 h-4" />
              </ButtonCmp>
            </TooltipCmp>
          </>
        )}
      </div>
      <OutsideClickHandler
        onOutsideClick={() => setIsShowActionButtons(false)}
        containerClassName="relative"
      >
        <ButtonCmp
          className="btn-outline-primary"
          onClick={() => setIsShowActionButtons(!isShowActionButtons)}
        >
          Quote Actions
        </ButtonCmp>
        <ul
          className={`rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-medium my-1.5 z-[1]  w-[117px] right-0 absolute ${
            isShowActionButtons ? '' : 'hidden'
          } `}
        >
          <li
            className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary`}
            onClick={modifyQuoteStatus(addressDetails.id)}
          >
            {addressDetails.status === STATUS.OPENED
              ? 'Modify Quote'
              : 'Duplicate Quote'}
          </li>
          {[STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status) && (
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary`}
              onClick={() => setIsShowCloseReasonModal(true)}
            >
              No Quote
            </li>
          )}
        </ul>
      </OutsideClickHandler>
      {!!addressDetails.isOrderBookingInProgress && (
        <ButtonCmp
          className="btn-outline-primary text-grayLight600"
          btnSpinnerClass="text-primary"
          loading
        >
          Processing Order Booking
        </ButtonCmp>
      )}

      {addressDetails.status === STATUS.WON && (
        <ButtonCmp
          className="btn_secondary_black"
          onClick={() =>
            window.open(ROSE_ROCKET.QUOTE_URL + addressDetails.rossRocketId)
          }
        >
          View Order
        </ButtonCmp>
      )}

      <ButtonCmp
        className="btn_secondary_black lg:!px-[9px] !px-2"
        onClick={handleSubmit}
      >
        <XClose className="w-4 h-4" />
      </ButtonCmp>
    </div>
  );

  const ActiveArray: any = [
    {
      value: 'Quote Options',
      name: 'Quote Options',
    },
  ];

  return (
    <div>
      <PageSectionLayout
        header={
          <Header
            title={`Quote: #${addressDetails.code ?? ''}`}
            leftContent={
              <Copy05
                className="w-5 h-5 text-primary cursor-pointer font-bold"
                onClick={() => copyDebounce(addressDetails.code ?? '')}
              />
            }
            countBadge={
              isLoading ? (
                <div className="flex h-6 w-20 border justify-center items-center border-gray300 rounded-lg gap-1 custom-loading">
                  <span className="rounded-full p-1 bg-warning500"></span>
                  <span className="text-xs">Opened </span>
                </div>
              ) : (
                <BadgeCmp
                  style="modern"
                  type={
                    addressDetails.status === 'Won'
                      ? 'success'
                      : addressDetails.status === 'Active'
                      ? 'lightblue'
                      : addressDetails.status === 'Opened'
                      ? 'primary'
                      : addressDetails.status === 'Expired' ||
                        addressDetails.status === 'Lost'
                      ? 'error'
                      : 'success'
                  }
                >
                  {addressDetails.status === 'Opened'
                    ? 'Open'
                    : addressDetails.status === 'Active'
                    ? 'Quoted'
                    : addressDetails.status === 'BookingRequested'
                    ? 'Requested'
                    : addressDetails.status}
                </BadgeCmp>
              )
            }
            desc="View the quote for your latest shipment."
            rightSideContent={rightSideContent}
            labelClassName={`${colors.badgeColor} ${colors.badgeText} ${colors.borderColor}`}
            labelTextColor={`${colors.badgeText}`}
            mainContainerClassName="xls:!flex-nowrap !flex-wrap mdm:!flex-row md:!flex-col sm:!flex-row flex-col mdm:!items-center !items-start"
            rightClassName="mdm:min-w-fit md:min-w-full sm:min-w-fit min-w-full !w-fit"
          />
        }
      >
        {/* Quote Detail Page */}
        <QuoteDetailTable
          dimensions={dimensions}
          services={services}
          isLoading={isLoading}
          // handleActionType={handleActionType}
          addressDetails={addressDetails}
          totalWeight={totalWeight}
          totalUnit={totalUnit}
        />

        <div className="  w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="w-full py-3 px-5 flex mdm:flex-row flex-col flex-wrap mdm:justify-between mdm:items-center gap-3">
            <TabButton
              className=""
              tabArray={ActiveArray}
              isActive={'Quote Options'}
              activeClassName="!bg-utilityGray100"
            />
          </div>
          <div className="w-full border-t border-gray100 flex-1 flex flex-col">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-pagination">
              <TableCmp
                headCells={headCellsQuoted}
                tableDataArr={carrierList}
                TableLoaderRowCmp={CarrierListLoading}
                TableRowCmp={CarrierLisRow}
                tableRowCmpProps={{
                  handleAction: setAction,
                  status: addressDetails?.status,
                }}
                getRowClass={(data: any) =>
                  data.carrierStatus === STATUS.BOOKING_REQUEST
                    ? '!bg-green20'
                    : ''
                }
                isTableDataLoading={isLoading}
                numberOfSkeletonRows={10}
              />
            </div>

            {carrierList?.length <= 0 && !isLoading && (
              <NotFoundUI
                title="No Quotes found"
                desc="There are no quotes found. You can create a new quote to get started."
                containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
                testId="wal-QuotingDashboard-dataNotFoundText"
              />
            )}
          </div>
        </div>
        <div className="rounded-xl border border-utilityGray200 overflow-hidden">
          <h6 className="text-grayLight900 text-base font-semibold px-5 py-4 bg-gray50 border-b border-utilityGray200">
            Terms & Condtions
          </h6>
          <ul className="p-3 flex flex-col gap-y-3">
            <li className="flex gap-x-1">
              <Check className="text-primary w-5 h-5 flex-none" />
              <p className="text-grayLight600 text-sm font-bold">
                Appointments:
              </p>
              <p className="text-grayLight600 text-sm font-normal">
                MAY BE SUBJECT TO A SURCHARGE
              </p>
            </li>
            <li className="flex gap-x-1">
              <Check className="text-primary w-5 h-5 flex-none" />
              <p className="text-grayLight600 text-sm font-bold">
                Loss/Damage Claims:
              </p>
              <p className="text-grayLight600 text-sm font-normal">
                The maximum liability is $2,00 CAD per pound.
              </p>
            </li>
            <li className="flex gap-x-1">
              <Check className="text-primary w-5 h-5 flex-none" />
              <p className="text-grayLight600 text-sm font-bold">Currency:</p>
              <p className="text-grayLight600 text-sm font-normal">
                Rate is in the currency stipulated above, if no currency is
                mentioned, the funds are in Canadian Dollars
              </p>
            </li>
            <li className="flex gap-x-1">
              <Check className="text-primary w-5 h-5 flex-none" />
              <p className="text-grayLight600 text-sm font-bold">
                Discrepencies:
              </p>
              <p className="text-grayLight600 text-sm font-normal">
                Rate is subject to adjustment should the weight, size or
                quantities vary from initial assumptions.
              </p>
            </li>
            <li className="flex gap-x-1">
              <Check className="text-primary w-5 h-5 flex-none" />
              <p className="text-grayLight600 text-sm font-bold">
                Waiting Time:
              </p>
              <p className="text-grayLight600 text-sm font-normal">
                2hrs free for loading/unloading FTL - 1hr free LTL &gt; 4 skids
                - 30min &lt; 4 skids- excess subject surcharge
              </p>
            </li>
            <li className="flex gap-x-1">
              <Check className="text-primary w-5 h-5 flex-none" />
              <p className="text-grayLight600 text-sm font-bold">
                Cancellations:
              </p>
              <p className="text-grayLight600 text-sm font-normal">
                Any cancellations within 24hrs of pickup date could potentially
                be subject to cancellation fee
              </p>
            </li>
            <li className="flex gap-x-1">
              <Check className="text-primary w-5 h-5 flex-none" />
              <p className="text-grayLight600 text-sm font-bold">LTL:</p>
              <p className="text-grayLight600 text-sm font-normal">
                Estimated delivery dates for LTL shipments are based on transit
                time and are subject to change
              </p>
            </li>
          </ul>
        </div>

        {isShowCloseReasonModal && (
          <CloseQuoteConfirm
            id={id}
            handleSuccess={handleQuoteClose}
            isLoading={isQuoteCloseLoading}
            handleClose={() => {
              setIsShowCloseReasonModal(false);
            }}
          />
        )}
        {action?.mode === 'confirm-order' && (
          <ConfirmOrder
            data={action?.data}
            handleClose={() => setAction(initAction)}
            getQuoteById={getQuoteById}
          />
        )}
      </PageSectionLayout>
    </div>
  );
};
export default QuoteDetail;
