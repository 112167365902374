import {} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

const DimensionsOrderDetailRow = ({ data }: any) => (
  <>
    <td className="px-5 py-4">{data?.quantity}</td>
    <td className="px-5 py-4">{data?.handlingUnit}</td>
    <td className="px-5 py-4">{data?.dimensions}</td>
    <td className="px-5 py-4">{data?.class}</td>
    <td className="px-5 py-4">{data?.totalWeight}</td>
    <td className="px-5 py-4">{data?.refNumber ?? '-'}</td>
    <td className="px-5 py-4">{data?.description ?? '-'}</td>
  </>
);

export default DimensionsOrderDetailRow;
