import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/context/AuthContext';
import { resetNewPassword } from 'src/services/AuthService';

import { ROUTES } from '../../constants/routes';
import AuthWrapper from '../AppLayout/AuthWrapper';

import UpdatePasswordForm from './UpdatePasswordForm';

// const initValidForm = {
//   password: true,
//   confirm_password: true,
// };

const CreatePassword = () => {
  const location = useLocation();
  const { setIsAuthenticated } = useContext(AuthContext);
  // const [validForm, setValidForm] = useState(initValidForm);
  const [code, setCode] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const initFormData: any = {
    email: '',
    password: '',
  };
  const initErrorMsg: any = {
    password: '',
    confirm_password: '',
    server_error: '',
  };

  const [formData, setFormData] = useState<any>(initFormData);
  const [errorMsg, setErrorMsg] = useState<any>(initErrorMsg);
  const [isStrongPassword, setIsStrongPassword] = useState(true);

  const validatePassword = () => {
    const validationRules = [
      { regex: /.{8,}/, message: 'Must be at least 8 characters long.' },
      {
        regex: /[A-Z]/,
        message: 'Must contain at least one uppercase letter.',
      },
      {
        regex: /[a-z]/,
        message: 'Must contain at least one lowercase letter.',
      },
      { regex: /[0-9]/, message: 'Must contain at least one number.' },
      {
        regex: /[!@#$%^&*(),.?":{}|<>]/,
        message: 'Must contain at least one special character.',
      },
    ];

    for (let rule of validationRules) {
      if (!rule.regex.test(formData?.password)) {
        setErrorMsg({
          ...errorMsg,
          password: rule.message,
          confirm_password: null,
        });
        setIsStrongPassword(false);

        return false;
      } else {
        setErrorMsg({ ...errorMsg, password: null });
      }
    }
    setIsStrongPassword(true);

    return true;
  };

  const handleInputChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });

    if (event.target.name === 'password') {
      validatePassword();
      console.log({
        ...errorMsg,
        confirm_password: null,
      });
    } else {
      setErrorMsg({
        ...errorMsg,
        confirm_password: null,
      });
    }
  };

  const handleSubmit = (event: any) => {
    setErrorMsg('');
    event.preventDefault();

    const isPasswordValid = validatePassword();
    const isPasswordMatch = formData?.password === formData?.confirm_password;

    if (!isPasswordValid) {
      return;
    }

    if (!isPasswordMatch) {
      setErrorMsg({
        ...errorMsg,
        confirm_password: "Confirm password doesn't match",
      });

      return;
    }
    setIsLoading(true);
    const payload = {
      password: formData?.password,
      confirm_password: formData?.confirm_password,
      code: code,
    };

    resetNewPassword(payload)
      .then((response: any) => {
        setIsLoading(false);

        if (response?.token) {
          localStorage.setItem('token', response?.token || null);
          setIsAuthenticated(true);
          navigate(ROUTES.SET_DETAILS);
        }
      })
      .catch((error): any => {
        setIsLoading(false);
        setErrorMsg(
          error?.response?.message || 'Something went wrong with set password'
        );
      });
  };

  useEffect(() => {
    const codeVal =
      new URLSearchParams(location?.search).get('code') || (null as any);

    if (!codeVal) {
      navigate(ROUTES?.LOGIN);
    }
    setCode(encodeURIComponent(codeVal));
  }, []);

  return (
    <>
      <AuthWrapper
        authHeading="Create Password"
        authSubTitle="Secure your account with a strong password."
      >
        <UpdatePasswordForm
          formData={formData}
          errorMsg={errorMsg}
          isStrongPassword={isStrongPassword}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
        />
      </AuthWrapper>
    </>
  );
};

export default CreatePassword;
