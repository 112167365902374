import {
  Download03,
  File05,
  Upload01,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import { downloadPdf } from 'src/utils/CommonFunctions';

export const initAction = {
  mode: null,
  type: null,
};

const DocumentItem = ({
  iconClass,
  title,
  isPrimary = false,
  onClick,
  isLoading,
}: any) => (
  <li className="xxl:p-1.5 p-1 xxl:w-1/4 w-1/3">
    <div
      className={`px-3 py-2 flex items-center rounded-md border border-borderPrimary bg-white shadow-xs gap-x-1.5 ${
        isLoading ? 'custom-loading' : ''
      }`}
    >
      <File05 className={`w-4 h-4 ${iconClass}`} />
      <p
        className={`flex-1 text-xs font-medium ${
          isPrimary ? 'text-primary' : 'text-gray500'
        }`}
      >
        {title}
      </p>
      {isPrimary ? (
        <Download03
          className="w-4 h-4 text-primary cursor-pointer"
          onClick={() => {
            onClick();
          }}
        />
      ) : (
        <Upload01
          className="w-4 h-4 text-textSecondary cursor-pointer"
          onClick={() => {
            onClick();
          }}
        />
      )}
    </div>
  </li>
);

const DocumentDetails = () => {
  const [action, setAction] = useState(initAction);
  const [documents, setDocuments] = useState<any>([]);
  const [modalTitle, setModalTitle] = useState<string>('Upload Document');
  const [documentType, setDocumentType] = useState<string | null>(null);

  console.log('action', action);
  console.log('modalTitle', modalTitle);
  console.log('documentType', documentType);
  console.log('setDocuments', setDocuments);

  const handleDocumentClick = (type: string) => {
    if (type === 'POD_document') {
      setModalTitle('Upload POD');
      setDocumentType('POD_document');
      setAction((old: any) => ({
        ...old,
        mode: 'upload-document',
        type: 'POD',
      }));
    } else if (type === 'BOL') {
      setModalTitle('Upload Bill of Lading');
      setDocumentType('BOL');
      setAction((old: any) => ({
        ...old,
        mode: 'upload-document',
        type: 'Bill of Lading',
      }));
    } else if (type === 'rate_confirmation') {
      setModalTitle('Upload Rate Confirmation');
      setDocumentType('rate_confirmation');
      setAction((old: any) => ({
        ...old,
        mode: 'upload-document',
        type: 'Rate Confirmation',
      }));
    } else if (type === 'customer_quote') {
      setModalTitle('Upload Customer Quote');
      setDocumentType('customer_quote');
      setAction((old: any) => ({
        ...old,
        mode: 'upload-document',
        type: 'Customer Quote',
      }));
    } else if (type === 'commercial_invoice') {
      setModalTitle('Upload Commercial Invoice');
      setDocumentType('commercial_invoice');
      setAction((old: any) => ({
        ...old,
        mode: 'upload-document',
        type: 'Commercial Invoice',
      }));
    } else if (type === 'new_documents') {
      setModalTitle('Upload New Documents');
      setDocumentType('new_documents');
      setAction((old: any) => ({
        ...old,
        mode: 'upload-document',
        type: 'Upload New Documents',
      }));
    }
  };

  const handleDocumentDownload = (docType: string) => {
    const docs =
      documents && documents?.length > 0
        ? documents?.filter((doc: any) => doc?.documentType === docType)
        : [];

    if (docs && docs.length > 0) {
      downloadPdf(`${docs[0].fileUrl}${docs[0].file}`, docs[0].file);
    } else {
      handleDocumentClick(docType);
    }
  };

  const getDocumentItemProps = (docType: string) => {
    const hasDocument = documents?.some(
      (doc: any) => doc?.documentType === docType
    );

    const onClickHandler = () => {
      if (hasDocument) {
        handleDocumentDownload(docType);
      } else {
        handleDocumentClick(docType);
      }
    };

    return {
      iconClass: hasDocument ? 'text-primary' : 'text-textSecondary',
      isPrimary: hasDocument,
      onClick: onClickHandler,
    };
  };

  const documentItems = [
    {
      title: 'Bill of Lading',
      ...getDocumentItemProps('BOL'),
    },
    {
      title: 'Rate Confirmation',
      ...getDocumentItemProps('rate_confirmation'),
    },
    {
      title: 'Customer Quote',
      ...getDocumentItemProps('customer_quote'),
    },
    {
      title: 'Commercial Invoice',
      ...getDocumentItemProps('commercial_invoice'),
    },
    {
      title: 'POD',
      ...getDocumentItemProps('POD'),
    },
    {
      title: 'Upload New Documents',
      ...getDocumentItemProps('new_documents'),
    },
  ];

  return (
    <li>
      <h6 className="text-textSecondary text-xs font-medium mb-1 leading-[1.5]">
        Documents
      </h6>
      <div className="rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-5 mb-4 last:mb-0">
        <ul className="-m-1.5 flex flex-wrap">
          {documentItems.map((item, index) => (
            <DocumentItem
              key={index}
              iconClass={item.iconClass}
              title={item.title}
              isPrimary={item.isPrimary}
              onClick={() => {
                item.onClick();
              }}
              // isLoading={isLoading}
            />
          ))}
        </ul>
      </div>
    </li>
  );
};

export default DocumentDetails;
