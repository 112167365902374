import { Plus, Rocket02, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
// import { useRolePermission } from 'src/utils/CommonFunctions';

import ButtonCmp from '../../components/ButtonCmp';

interface IProps {
  handleActionType?: () => void;
  title: any;
  icon?: any;
  desc: any;
  btnClass?: string;
  containerClassName?: string;
  contentClassName?: string;
  iconClassName?: string;
  iconSecondaryClassName?: string;
  buttonText?: any;
  buttonDisabled?: boolean;
  testId?: string;
  descClass?: string;
  buttonClassName?: string;
}

const NotFoundUI = ({
  handleActionType,
  title,
  desc,
  btnClass,
  containerClassName = '',
  contentClassName = '',
  iconSecondaryClassName = '',
  icon,
  iconClassName = '',
  buttonText,
  buttonDisabled,
  testId,
  descClass,
  buttonClassName,
}: IProps) => (
  <div
    data-test-id={testId}
    className={`h-full flex my-[61px] mx-4 items-center justify-center overflow-hidden ${containerClassName}`}
  >
    <div
      className={` ${contentClassName} max-w-[480px] flex items-center justify-center flex-col z-[0] relative before:[''] before:bg-[url('../../assets/img/not-found-images/empty-state.png')] before:w-full before:h-[calc(100%+200px)] before:absolute before:bottom-0 before:z-[-1] before:bg-no-repeat before:bg-top `}
    >
      <div
        className={`${iconClassName} rounded-[10px] border border-utilityGray200 shadow-sm h-11 w-11 bg-white text-textSecondary flex items-center justify-center mb-4`}
      >
        {icon ?? <SearchLg className="w-5 h-5" />}
      </div>
      <div
        className={`${iconSecondaryClassName} hidden rounded-full h-14 w-14 bg-gradient-to-b from-gray50 to-utilityGray500 text-grayLight900 items-center justify-center mb-4`}
      >
        <Rocket02 className="w-5 h-5" />
      </div>
      <div className="text-center text-grayLight900 text-sm font-semibold leading-6 mb-1">
        {title}
      </div>
      <div
        className={`sm:max-w-[342px] p-1 pt-0 text-center text-grayLight600 text-xs font-normal leading-normal ${descClass}`}
      >
        {desc}
      </div>

      {handleActionType && buttonText && buttonText !== 'false' && (
        <div className={`flex gap-3 mt-5 ${btnClass}`}>
          <ButtonCmp
            type="button"
            className={`${buttonClassName} btn_primary btn-heigh-auto shadow`}
            onClick={handleActionType}
            disabled={buttonDisabled}
            icon={<Plus className="w-4 h-4" />}
          >
            {buttonText}
          </ButtonCmp>
        </div>
      )}
    </div>
  </div>
);

export default NotFoundUI;
