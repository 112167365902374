import { API } from 'src/constants/api';

import { axiosInterceptor } from './interceptors';

export const updateSetting = async (data: any) => {
  const response = await axiosInterceptor.post(API.SETTING.UPDATE, data);

  return response;
};

// export const getAppearance = async () => {
//   const response = await axiosInterceptor.get(API.APPEARANCE.GET);

//   return response;
// };
