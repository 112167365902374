import {
  ArrowRight,
  Bell03,
  Check,
  ChevronDown,
  Container,
  Lightning01,
  SearchLg,
  SwitchVertical01,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import OutsideClickHandler from 'src/components/OutsideClickHandler';
import SelectBox from 'src/components/SelectBox/SelectBox';
import StatusDotProfileCmp from 'src/components/StatusDotProfileCmp';
import { ROUTES } from 'src/constants/routes';
import { formatAddress, formatLastUpdate } from 'src/utils/CommonFunctions';

import Loginlogo from '../../assets/img/logo2.svg';

const tabArr = [
  {
    value: 'pickups',
    label: 'Pickups',
  },
  {
    value: 'delivered',
    label: 'Delivered',
  },
  {
    value: 'in-transit',
    label: 'In Transit',
  },
];

interface IProps {
  orders?: any;
  setParams?: any;
  isOrdersLoading?: any;
  params?: any;
  setOrderId?: any;
  pickupOrderCount: any;
  deliveredOrderCount: any;
  inTransitOrderCount: any;
}

const LeftSidebar = ({
  orders,
  setParams,
  isOrdersLoading,
  params,
  setOrderId,
  pickupOrderCount,
  deliveredOrderCount,
  inTransitOrderCount,
}: IProps) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    console.log(pickupOrderCount, deliveredOrderCount, inTransitOrderCount);
  }, []);

  const [isDisplaySortOption, setIsDisplaySortOption] = useState(false);
  const navigate = useNavigate();

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old: any) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchQuote') {
      setSearch(value);
      searchDebounce(value);
    }
  };

  // const getAddressWholeString = (
  //   first: string,
  //   second: string,
  //   third: string
  // ) =>
  //   [first, second, third].filter((e) => e).length
  //     ? [first, second, third].filter((e) => e).toString()
  //     : false;

  return (
    <>
      <div className="flex items-center gap-1.5">
        <h6 className="text-grayLight900 text-base font-semibold">
          My Active Orders
        </h6>
        <BadgeCmp type="success" style="modern">
          15 Orders
        </BadgeCmp>
      </div>
      <div className="flex gap-x-2.5">
        <InputText
          inputName="searchQuote"
          placeholder="Search"
          className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
          icon={
            <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
          }
          value={search}
          inputType="text"
          onChangeFunc={handleFilter}
          parentClassName="flex-1"
          isClearable={true}
        />
        <SelectBox
          parentClassName="w-[160px]"
          name="recordsPerPageGroup"
          id="recordsPerPageGroup"
          className="form_control shadow"
          size="sm"
          options={tabArr}
          onChangeFunc={(event: any) => {
            setParams((old: any) => ({
              ...old,
              limit: event.value,
              page: 1,
            }));
          }}
          isSearchable={false}
          value={tabArr.find((val: any) => val.value === params.limit)}
        />
        <OutsideClickHandler
          onOutsideClick={() => {}}
          containerClassName="relative"
        >
          <ButtonCmp
            className={`btn-outline-primary lg:!px-[9px] !px-2 ${
              isDisplaySortOption ? 'bg-primary text-white' : ''
            }`}
            icon={<SwitchVertical01 className="w-4 h-4" />}
            onClick={() => {
              setIsDisplaySortOption(!isDisplaySortOption);
            }}
          >
            <></>
          </ButtonCmp>

          <ul
            className={`rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-medium my-1.5 z-[1]  w-[200px] right-0 absolute ${
              isDisplaySortOption ? '' : 'hidden'
            } `}
          >
            <li className="px-2 text-gray400 my-1.5">Sort by</li>
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex justify-between gap-x-2 hover:text-primary ${
                params.sortField === 'updatedAt' ? 'bg-gray50' : ''
              } `}
              onClick={() => {
                setIsDisplaySortOption(false);
                setParams((old: any) => ({
                  ...old,
                  sortField: 'updatedAt',
                }));
              }}
            >
              <span>Last Update Time</span>
              {params.sortField === 'updatedAt' && (
                <Check className="w-5 h-5 text-primary" />
              )}
            </li>
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary`}
              // onClick={() => {
              //   setIsDisplaySortOption(false);
              //   setParams((old: any) => ({ ...old, sortField: 'pickupDate' }));
              // }}
            >
              Pickup Date
            </li>
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary`}
              onClick={() => {
                setIsDisplaySortOption(false);
                setParams((old: any) => ({
                  ...old,
                  sortField: 'customerName',
                }));
              }}
            >
              Customer
            </li>
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary`}
              // onClick={() => {
              //   setIsDisplaySortOption(false);
              //   setParams((old: any) => ({
              //     ...old,
              //     sortField: 'customerName',
              //   }));
              // }}
            >
              Estimated Time
            </li>
          </ul>
        </OutsideClickHandler>
      </div>

      <div className=" flex flex-col gap-y-4 flex-1 overflow-y-auto scrollbar-hide pb-4">
        {!isOrdersLoading &&
          orders.map((value: any, index: any) => (
            <div
              key={index}
              className="rounded-lg border border-utilityGray200 bg-white p-3 cursor-pointer shadow-none hover:shadow-lgc"
              onClick={() => setOrderId(value.orderTableId)}
            >
              <div className="flex gap-x-2 justify-between items-start">
                <a
                  href={`https://wal.roserocket.com/#/ops/orders/${value.orderId}`}
                  className={`text-primary text-sm font-medium underline flex-none ${
                    isOrdersLoading ? 'custom-loading' : ''
                  }`}
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                >
                  #{value.orderId}
                </a>
                <div className="flex gap-x-2.5 gap-y-1 justify-end flex-wrap">
                  {value?.orderTags !== '' &&
                    value?.orderTags?.split(',')?.map((tag: any) => (
                      <BadgeCmp
                        key={tag}
                        style="modern"
                        type="success"
                        mainClassName={`${
                          isOrdersLoading ? 'custom-loading' : ''
                        }`}
                      >
                        {tag}
                      </BadgeCmp>
                    ))}
                  <BadgeCmp style="modern" type="primary">
                    {value.orderStatus}
                  </BadgeCmp>
                </div>
              </div>

              <StatusDotProfileCmp
                parentClassName="mt-1.5"
                dotColor="red"
                label={
                  <>
                    {params.filter === 'booked'
                      ? 'Last Updated:'
                      : 'Pickup Appointment'}
                    &nbsp;
                    <span>{formatLastUpdate(value?.createdAt)}</span>
                  </>
                }
              />

              <div className="mt-3.5 pt-3.5 border-t border-utilityGray200">
                <ul className="p-2.5 rounded-lg bg-gray50">
                  <li
                    key="0"
                    className="relative flex items-start gap-x-2 pb-5 last:pb-0 overflow-hidden [&:last-child>div:after]:hidden "
                  >
                    <div className="mt-1 after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[13px] after:absolute flex items-center justify-center after:bg-gray500">
                      <div className="w-2.5 h-2.5 border-2 border-gray500 rounded-full"></div>
                    </div>
                    <div className="flex-1 text-xs text-grayLight600 font-normal">
                      <span className="text-grayLight900 font-medium">
                        {value?.shipperCompanyName}&nbsp;
                      </span>
                      {formatAddress(value?.shipperFullAddress)}
                    </div>
                  </li>
                  <li
                    key="1"
                    className="relative flex items-start gap-x-2 pb-2 last:pb-0 mb-1 last:mb-0 [&:last-child>div:after]:hidden"
                  >
                    <div>
                      <ChevronDown className="w-[18px] text-gray500 -mt-[13px] -ml-1" />
                    </div>
                    <div className="flex-1 text-xs text-grayLight600 font-normal -mt-2.5 -ml-1">
                      <span className="text-grayLight900 font-medium">
                        {value?.consigneeCompanyName}&nbsp;
                      </span>
                      {formatAddress(value?.consigneeFullAddress)}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          ))}
        {!isOrdersLoading && !orders?.length && (
          <>
            <div
              onClick={() => navigate(`${ROUTES.RADAR_ORDER_DETAIL}/1`)}
              className="rounded-xl border border-utilityGray200 bg-white p-4 cursor-pointer shadow-xs hover:shadow-lgc flex gap-x-3"
            >
              <div className="rounded-md border border-utilityGray200 bg-gray50 flex items-center justify-center h-[50px] w-[50px] uppercase text-primary text-base p-2 flex-none">
                <img src={Loginlogo} className="w-full h-full" alt="" />
                {/* WHEN NO IMAGE FOUND BELOW CODE WILL BE USED */}
                {/* <span>WA</span> */}
              </div>
              <div className="flex-1">
                <div className="flex gap-x-2">
                  <div className="flex-1 truncate space-y-0.5">
                    <h6 className="text-primary700 text-xs font-semibold">
                      Picking up in approx. 10 hours
                    </h6>
                    <div className="flex items-center gap-x-1.5 flex-wrap">
                      <h6 className="text-grayLight900 text-lg">#WAL-DMIF-1</h6>
                      <BadgeCmp style="modern" type="success">
                        Common LTL
                      </BadgeCmp>
                      <BadgeCmp style="modern" type="error">
                        Pickup
                      </BadgeCmp>
                    </div>
                  </div>
                  <div className="flex gap-x-2 self-start">
                    <ButtonCmp
                      className="btn-outline-primary lg:!px-[9px] !px-2 icon-filled"
                      icon={<Lightning01 className="w-4 h-4" />}
                    >
                      <></>
                    </ButtonCmp>
                    <ButtonCmp
                      className="btn-outline-primary lg:!px-[9px] !px-2"
                      icon={<Bell03 className="w-4 h-4" />}
                    >
                      <></>
                    </ButtonCmp>
                  </div>
                </div>
                <div className="flex items-center flex-wrap gap-x-6 gap-y-1 pt-3">
                  <div className="flex items-center gap-x-3 max-w-full">
                    <p className="text-gray500 text-xs font-normal truncate">
                      <span className="text-grayLight900 font-medium">
                        Shipper&nbsp;
                      </span>
                      Montréal, Canada
                    </p>
                    <div className="rounded border border-borderPrimary shadow-xs p-1">
                      <ArrowRight className="w-3.5 h-3.5 text-grayLight900" />
                    </div>
                    <p className="text-gray500 text-xs font-normal truncate">
                      <span className="text-grayLight900 font-medium">
                        Shipper&nbsp;
                      </span>
                      Montréal, Canada
                    </p>
                  </div>
                  <div className="flex gap-x-1">
                    <Container className="w-4 h-4 text-gray400" />
                    <p className="text-gray500 text-xs font-normal">
                      <span className="text-grayLight900 font-medium">
                        Line Items&nbsp;
                      </span>
                      3 pallets & 2 skids
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              onClick={() => navigate(`${ROUTES.RADAR_ORDER_DETAIL}/1`)}
              className="rounded-xl border border-utilityGray200 bg-white p-4 cursor-pointer shadow-xs hover:shadow-lgc flex gap-x-3"
            >
              <div className="rounded-md border border-utilityGray200 bg-gray50 flex items-center justify-center h-[50px] w-[50px] uppercase text-primary text-base p-2 flex-none">
                <img src={Loginlogo} className="w-full h-full" alt="" />
                {/* WHEN NO IMAGE FOUND BELOW CODE WILL BE USED */}
                {/* <span>WA</span> */}
              </div>
              <div className="flex-1">
                <div className="flex gap-x-2">
                  <div className="flex-1 truncate space-y-0.5">
                    <h6 className="text-primary700 text-xs font-semibold">
                      Est. delivery in 2 days
                    </h6>
                    <div className="flex items-center gap-x-1.5 flex-wrap">
                      <h6 className="text-grayLight900 text-lg">#WAL-DMIF-1</h6>
                      <BadgeCmp style="modern" type="blue">
                        Private LTL
                      </BadgeCmp>
                      <BadgeCmp style="modern" type="error">
                        Pickup
                      </BadgeCmp>
                    </div>
                  </div>
                  <div className="flex gap-x-2 self-start">
                    <ButtonCmp
                      className="btn-outline-primary lg:!px-[9px] !px-2"
                      icon={<Lightning01 className="w-4 h-4" />}
                    >
                      <></>
                    </ButtonCmp>
                    <ButtonCmp
                      className="btn-outline-primary lg:!px-[9px] !px-2 icon-filled"
                      icon={<Bell03 className="w-4 h-4" />}
                    >
                      <></>
                    </ButtonCmp>
                  </div>
                </div>
                <div className="flex items-center flex-wrap gap-x-6 gap-y-1 pt-3">
                  <div className="flex items-center gap-x-3 max-w-full">
                    <p className="text-gray500 text-xs font-normal truncate">
                      <span className="text-grayLight900 font-medium">
                        Shipper&nbsp;
                      </span>
                      Montréal, Canada
                    </p>
                    <div className="rounded border border-borderPrimary shadow-xs p-1">
                      <ArrowRight className="w-3.5 h-3.5 text-grayLight900" />
                    </div>
                    <p className="text-gray500 text-xs font-normal truncate">
                      <span className="text-grayLight900 font-medium">
                        Shipper&nbsp;
                      </span>
                      Montréal, Canada
                    </p>
                  </div>
                  <div className="flex gap-x-1">
                    <Container className="w-4 h-4 text-gray400" />
                    <p className="text-gray500 text-xs font-normal">
                      <span className="text-grayLight900 font-medium">
                        Line Items&nbsp;
                      </span>
                      3 pallets & 2 skids
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {isOrdersLoading && !orders?.length && (
          <NotFoundUI
            title="No Orders found"
            desc="There are no data for orders."
          />
        )}
      </div>
      <div className="px-5">
        <CustomPagination
          parentClassName="px-0"
          recordsPerPage={5}
          totalRecords={10}
          currentPage={1}
          handlePagination={() => {}}
        />
      </div>
    </>
  );
};

export default LeftSidebar;
