import { Edit05, Plus, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import InputText from 'src/components/InputText/InputText';
import Map from 'src/components/Map/Map';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TooltipCmp from 'src/components/TooltipCmp';

const ShipperAddress = ({
  formData,
  shipperOptions,
  validForm,
  handleSelectChange,
  selectFormData,
  handleActionType,
  saveShipperDetails,
  isShipperBtnDisable,
  handleAutocompleteChange,
  handleInputChange,
  shipperAddress,
  setShipperAddress,
  isLoading,
  isShipperLoading,
  // isValidAddress,
  status,
}: any) => (
  // console.log("isShipperLoading :>> ", isShipperLoading);

  <div className="w-full xl:p-5 sm:p-4 pt-2 flex flex-wrap xl:gap-[51px] gap-6 sm:bg-white rounded-xl sm:border sm:border-utilityGray200 sm:shadow-sm">
    <div className="lg:w-1/2 w-full">
      <h6 className="text-textSecondary text-sm font-bold">
        Shipper Address {isShipperLoading}
      </h6>
      <p className=" text-grayLight600 text-xs font-normal mb-3.5">
        Enter shippers details below.
      </p>
      <div className="mb-5 relative">
        <SelectBox
          isClearable
          isSearchable
          isLoading={isShipperLoading || isLoading}
          name="shipperId"
          label="Shipper"
          className={`form_control ${
            !validForm.shipperId
              ? 'border border-red-500 rounded-[10px] mb-1 border-solid '
              : ''
          }`}
          labelClassName="form_label mb-1.5 block"
          classComp=""
          options={shipperOptions}
          onChangeFunc={handleSelectChange('shipperId')}
          value={selectFormData.shipperId}
          errorText={!validForm.shipperId ? 'Shipper is required' : null}
        />
        <div className="absolute right-0 top-0 flex">
          {selectFormData.shipperId && (
            <button
              onClick={handleActionType('shipper', 'delete')}
              type="button"
              className="text-xs flex mr-2"
            >
              <TooltipCmp message="Delete Shipper">
                <Trash01 className="h-4 w-4" />
              </TooltipCmp>
            </button>
          )}
          <button
            onClick={saveShipperDetails('shipper')}
            type="button"
            className={`text-primary700 text-xs flex font-semibold gap-2.5 ${
              isShipperBtnDisable && 'text-opacity-50'
            }`}
            disabled={isShipperBtnDisable}
          >
            {selectFormData.shipperId ? (
              <Edit05 className="w-4 h-4" />
            ) : (
              <Plus className="w-4 h-4" />
            )}
            {selectFormData.shipperId ? 'Edit' : 'Add'} New Shipper
          </button>
        </div>
      </div>
      <div className="mb-5">
        <InputText
          inputName="shipperCompanyName"
          placeholder="Enter company name"
          className="form_control"
          label="Company name"
          labelClassName="mb-1.5 block"
          value={formData.shipperCompanyName}
          errorText={
            !validForm.shipperCompanyName ? 'Company name is required' : null
          }
          onChangeFunc={handleInputChange}
        />
      </div>
      <div className="relative">
        {status && (
          <AutoCompleteGoogle
            inputName="shipperAddress"
            required
            placeholder="Enter address"
            className={`form_control truncate ${
              !validForm.shipperAddress
                ? 'border border-red-500 border-solid'
                : ''
            }`}
            label="Address"
            labelClassName="mb-1.5 block"
            isClearable
            onChangeFunc={handleAutocompleteChange('shipperAddress')}
            onBlur={handleAutocompleteChange('shipperAddress')}
            onAddressInputChange={setShipperAddress}
            // errorText={!validForm.shipperAddress ? "Address is required" : null}
            value={shipperAddress}
          />
        )}

        <button
          onClick={handleActionType(
            'shipper',
            formData.shipperAddress.fullAddress
              ? 'addressUpdate'
              : 'addressCreate'
          )}
          type="button"
          className={`text-primary700 text-xs flex font-semibold gap-2.5 absolute right-0 top-0`}
          disabled={false}
        >
          {formData.shipperAddress.fullAddress ? 'Change' : 'Select'} Address
        </button>
      </div>

      {/* <p className="text-xs font-normal text-red-600 dark:text-red-500 min-h-4 mt-1">
                    {!validForm.shipperAddress ? "Address is required." : !isValidAddress.shipperAddress ? "Please choose a valid address in the country, city, and postal code." : null}
                </p> */}

      {/* {!isValidAddress.shipperAddress && <ErrorMsg errorText="Please choose a valid address in the country, city, and postal code." />} */}
    </div>
    <div className="lg:flex-1 xl:ml-[30px] w-full">
      {/* {formData.shipperAddress && formData.shipperAddress.fullAddress !== "" && ( */}

      {status && (
        <Map
          className="rounded-[20px] bg-white"
          address={
            formData.shipperAddress &&
            formData.shipperAddress.fullAddress !== ''
              ? formData.shipperAddress
              : null
          }
          id={'shipperMap'}
          height={261}
          onReverseAddressFunc={handleAutocompleteChange('shipperAddress')}
        />
      )}
      {/* )} */}
    </div>
  </div>
);
export default ShipperAddress;
