import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Loader from '../../components/Loader';
import { ROUTES } from '../../constants/routes';
import { AuthContext } from '../../context/AuthContext';
import { googleLogin } from '../../services/AuthService';

const Callback = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    const mCode = new URLSearchParams(location?.search).get('code');

    if (mCode) {
      googleLogin({ code: mCode })
        .then((response: any) => {
          if (response?.token) {
            setIsAuthenticated(true);
            navigate(ROUTES.HOME);
          } else {
            navigate(ROUTES.LOGIN, {
              state: {
                message: 'Google login failed. No matching account found.',
              },
            });
          }
        })
        .catch((error: any): any => {
          navigate(ROUTES.LOGIN, {
            state: {
              message:
                error?.response?.data?.message ||
                'Login failed due to Google authentication error. Please try again later.',
            },
          });
        });
    }
  }, []);

  return <Loader />;
};

export default Callback;
