import { ArrowLeft } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ButtonCmp from 'src/components/ButtonCmp';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import { ROUTES } from 'src/constants/routes';
import { AuthContext } from 'src/context/AuthContext';
import { sendVerificationMail, verifyOtp } from 'src/services/AuthService';

import AuthWrapper from '../AppLayout/AuthWrapper';

const VerifyOtp = ({ email, redirectUrl, login = false }: any) => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);
  const [otp, setOtp] = useState(new Array(4).fill(''));
  const [focusOtp, setFocusOtp] = useState(new Array(4).fill(false));
  const [otpValue, setOtpValue] = useState<any>(null);
  const [isValidOtp, setIsValidOtp] = useState<boolean>(false);
  const [wrongOtp, setWrongOtp] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isResendMailLoading, setIsResendMailLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<any>('');

  useEffect(() => {
    let val = otp.join(' ').trim().replace(/ /g, '');
    setOtpValue(val);

    if (val.length === 4) {
      setIsValidOtp(true);
    } else {
      setIsValidOtp(false);
    }
  }, [otp]);

  useEffect(() => {
    // Focus the first input element after the component mounts
    const firstInput = document.getElementById('otp_text_0');

    if (firstInput) {
      firstInput.focus();
    }
  }, []);

  const handleInputChange = (e: any, index: number) => {
    const value = e.target.value;
    const newValue = value.replace(otp[index], '');

    otp[index] = newValue;

    if (newValue.length === 1) {
      if (document.activeElement === e.target) {
        e.target.blur();
      }
      const nextElement: any = document.querySelector(`.otp-${index + 1}`);

      if (nextElement && nextElement.tagName.toLowerCase() === 'input') {
        nextElement.focus();
      } else {
        let continueButton: any = document.querySelector(`button.continue-btn`);

        if (continueButton) {
          continueButton.focus();
        }
      }
    }

    if (index < 3) {
      // otp[index + 1] = '';
    }

    setWrongOtp(false);
    setOtp([...otp]);
  };

  // const onResendOtp = () => {
  //   setOtp(new Array(4).fill(''));
  // };

  const handleKeyDown = (e: any, index: number) => {
    console.log(e.key);

    if (e.key === 'Backspace') {
      const value = e.target.value;
      const newValue = value.slice(0, 1);

      if (newValue.length === 0) {
        const previousElement: any = document.querySelector(
          `.otp-${index - 1}`
        );

        if (
          previousElement &&
          previousElement.tagName.toLowerCase() === 'input'
        ) {
          previousElement.focus();
        }
      }
    }
  };

  const onVerifyOtp = (otpCode: any) => {
    setIsLoading(true);

    let verificationToken = localStorage.getItem('code');
    verifyOtp({
      email: email,
      otp: otpCode,
      token: verificationToken,
      login: login,
    })
      .then((response) => {
        setIsLoading(false);
        localStorage.removeItem('code');

        if (response?.token && response?.token) {
          if (login) {
            localStorage.setItem('token', response?.token || null);
            setIsAuthenticated(true);

            if (
              !response?.data?.isSetBasicDetail &&
              response?.data?.isMainUser
            ) {
              navigate(ROUTES.SET_DETAILS);
            } else {
              navigate(ROUTES.SET_BASIC_DETAILS);
            }
          }
        } else if (
          response?.code &&
          response?.data?.isSetPassword === false &&
          login
        ) {
          navigate(`${ROUTES?.CREATE_PASSWORD}?code=${response?.code}`);
        } else if (response?.code && !login) {
          navigate(`${ROUTES?.SET_PASSWORD}?code=${response?.code}`);
          console.log(redirectUrl);
        } else {
          setOtp(new Array(4).fill(''));
          setErrorMsg(
            response?.message || 'Something went wrong with verify otp'
          );
        }
      })
      .catch((error): any => {
        setOtp(new Array(4).fill(''));
        setIsLoading(false);
        setWrongOtp(true);
        setErrorMsg(
          error?.response?.data?.message ||
            'Something went wrong with verify otp'
        );
      });
  };

  const sendVerifyOtpMail = () => {
    setIsResendMailLoading(true);
    sendVerificationMail({ email: email })
      .then((response) => {
        console.log('🚀 ~ .then ~ response:', response, response?.token);
        setIsResendMailLoading(false);

        if (response?.token && response?.token) {
          // navigate(ROUTES.HOME);
        } else {
          console.log('object', response);

          setErrorMsg(response?.message || 'Something went wrong with login');
        }
      })
      .catch((error): any => {
        setIsResendMailLoading(false);
        setErrorMsg(
          error?.response?.data?.message || 'Something went wrong with login'
        );
      });
  };

  // const sendMailToCustomer = () => {
  //   console.log('id :>> ', id);
  //   setIsMailLoading(true);
  //   setOtp(new Array(4).fill(''));
  //   resendCustomerMail({ customerId: id })
  //     // sendCustomerMail(id)
  //     .then(() => {
  //       WalToast.success('Mail send to Customer successfully');
  //     })
  //     .catch((e: any) =>
  //       WalToast.error(
  //         e?.response?.data?.message ?? 'something went wrong please try again'
  //       )
  //     )
  //     .finally(() => {
  //       setIsMailLoading(false);
  //     });
  // };

  return (
    <AuthWrapper
      authHeading="Check your email"
      authSubTitle={
        <>
          We sent a verification code to{' '}
          <span className="font-medium">{email}</span>
        </>
      }
    >
      <>
        <div className="flex justify-center gap-2">
          {otp.map((_: any, index: number) => (
            <InputText
              key={`otp_text_${index}`}
              id={`otp_text_${index}`}
              inputName="otp"
              inputType="number"
              placeholder={focusOtp[index] ? '' : '0'}
              isError={wrongOtp}
              min={0}
              max={9}
              maxLength={1}
              className={`otp-${index} w-16 h-16 text-center !p-2 text-4xl placeholder:!text-borderPrimary placeholder-shown:border-borderPrimary focus:border-primary border-primary text-primary hover:border-primary ${
                wrongOtp ? ' !border-fgErrorPrimary !text-fgErrorPrimary ' : ' '
              }`}
              value={otp[index]}
              onFocus={() => {
                focusOtp[index] = true;
                setFocusOtp([...focusOtp]);
              }}
              onBlur={() => {
                focusOtp[index] = false;
                setFocusOtp([...focusOtp]);
              }}
              onChangeFunc={(e: any) => handleInputChange(e, index)}
              handleKeyDown={(e: any) => handleKeyDown(e, index)}
              // errorText={!validForm.address1 ? 'Address 1 name is required' : null}
            />
          ))}
        </div>
        <p className="text-grayLight600 text-xs font-normal mt-1.5 text-center">
          Tip: Be sure to check your inbox and spam folders
        </p>

        <ErrorMsg errorText={errorMsg} mainClassName="text-center" />

        <ButtonCmp
          className={`btn_primary w-full mt-8`}
          onClick={() => onVerifyOtp(otpValue)}
          loading={isLoading}
          disabled={!isValidOtp || isLoading}
        >
          Verify Email
        </ButtonCmp>

        <div className="text-utilityTertiary600 text-sm font-normal text-center mt-6">
          Didn’t receive the email?&nbsp;
          {!isResendMailLoading ? (
            <a
              className="text-primary font-semibold cursor-pointer"
              onClick={sendVerifyOtpMail}
            >
              Click to resend
            </a>
          ) : (
            <span
              className={`loading loading-spinner ml-2 h-3 w-3 mt-1`}
            ></span>
          )}
        </div>
        <div className="text-center">
          <Link
            to={'#'}
            className="text-utilityTertiary600 text-sm font-semibold gap-x-1.5 inline-flex items-center justify-center mt-4"
          >
            <ArrowLeft className="w-4 h-4" />
            Back to login
          </Link>
        </div>
      </>
    </AuthWrapper>
  );
};

export default VerifyOtp;
