import {
  ArrowNarrowLeft,
  ArrowRight,
  Bell03,
  Check,
  ChevronDown,
  Container,
  Download03,
  Edit05,
  File05,
  Lightning01,
  MarkerPin04,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import NotFoundUI from 'src/components/NotFoundUI';
import StatusDotProfileCmp from 'src/components/StatusDotProfileCmp';
import TableCmp from 'src/components/TableCmp';
import { ROUTES } from 'src/constants/routes';
import { formatAddress, formatLastUpdate } from 'src/utils/CommonFunctions';

import Loginlogo from '../../assets/img/logo2.svg';

import LineItemRow from './LineItemRow';

interface IProps {
  orders?: any;
  setParams?: any;
  isOrdersLoading?: any;
  params?: any;
  setOrderId?: any;
  pickupOrderCount: any;
  deliveredOrderCount: any;
  inTransitOrderCount: any;
}

const OrderDetailLeftSidebar = ({
  orders,
  isOrdersLoading,
  params,
  setOrderId,
  pickupOrderCount,
  deliveredOrderCount,
  inTransitOrderCount,
}: IProps) => {
  useEffect(() => {
    console.log(pickupOrderCount, deliveredOrderCount, inTransitOrderCount);
  }, []);

  const navigate = useNavigate();

  const LineItemHeadCells = useMemo(
    () => [
      {
        id: 'Quantity',
        name: 'Quantity',
        rowClassName: '',
      },
      {
        id: 'Handling Unit',
        name: 'Handling Unit',
        rowClassName: '',
      },
      {
        id: 'Dimensions (Inches)',
        name: 'Dimensions (Inches)',
        rowClassName: '',
      },

      {
        id: 'Total weight',
        name: 'Total weight',
        rowClassName: '',
      },
      {
        id: 'REF Number',
        name: 'REF Number',
        rowClassName: '',
      },
    ],
    []
  );

  // const getAddressWholeString = (
  //   first: string,
  //   second: string,
  //   third: string
  // ) =>
  //   [first, second, third].filter((e) => e).length
  //     ? [first, second, third].filter((e) => e).toString()
  //     : false;

  return (
    <>
      <div className="flex items-center justify-between gap-1.5">
        <div
          onClick={() => navigate(`${ROUTES.RADAR}`)}
          className="flex items-center gap-x-1.5 cursor-pointer"
        >
          <div className="rounded-md border border-utilityGray200 w-[30px] h-[30px] flex items-center justify-center bg-gray50">
            <ArrowNarrowLeft className="w-4 h-4 text-grayLight600" />
          </div>
          <h6 className="text-grayLight900 text-sm font-medium">Back</h6>
        </div>
        <div className="flex gap-x-2">
          <ButtonCmp
            className="btn_secondary_black"
            icon={<Edit05 className="w-4 h-4" />}
          >
            Edit Order Details
          </ButtonCmp>
          <ButtonCmp
            className="btn_secondary_black"
            icon={<MarkerPin04 className="w-4 h-4" />}
          >
            Request Location Update
          </ButtonCmp>
        </div>
      </div>
      <div className=" flex flex-col gap-y-4 flex-1 overflow-y-auto scrollbar-hide pb-4">
        {!isOrdersLoading &&
          orders.map((value: any, index: any) => (
            <div
              key={index}
              className="rounded-lg border border-utilityGray200 bg-white p-3 cursor-pointer shadow-none hover:shadow-lgc"
              onClick={() => setOrderId(value.orderTableId)}
            >
              <div className="flex gap-x-2 justify-between items-start">
                <a
                  href={`https://wal.roserocket.com/#/ops/orders/${value.orderId}`}
                  className={`text-primary text-sm font-medium underline flex-none ${
                    isOrdersLoading ? 'custom-loading' : ''
                  }`}
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                >
                  #{value.orderId}
                </a>
                <div className="flex gap-x-2.5 gap-y-1 justify-end flex-wrap">
                  {value?.orderTags !== '' &&
                    value?.orderTags?.split(',')?.map((tag: any) => (
                      <BadgeCmp
                        key={tag}
                        style="modern"
                        type="success"
                        mainClassName={`${
                          isOrdersLoading ? 'custom-loading' : ''
                        }`}
                      >
                        {tag}
                      </BadgeCmp>
                    ))}
                  <BadgeCmp style="modern" type="primary">
                    {value.orderStatus}
                  </BadgeCmp>
                </div>
              </div>

              <StatusDotProfileCmp
                parentClassName="mt-1.5"
                dotColor="red"
                label={
                  <>
                    {params.filter === 'booked'
                      ? 'Last Updated:'
                      : 'Pickup Appointment'}
                    &nbsp;
                    <span>{formatLastUpdate(value?.createdAt)}</span>
                  </>
                }
              />

              <div className="mt-3.5 pt-3.5 border-t border-utilityGray200">
                <ul className="p-2.5 rounded-lg bg-gray50">
                  <li
                    key="0"
                    className="relative flex items-start gap-x-2 pb-5 last:pb-0 overflow-hidden [&:last-child>div:after]:hidden "
                  >
                    <div className="mt-1 after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[13px] after:absolute flex items-center justify-center after:bg-gray500">
                      <div className="w-2.5 h-2.5 border-2 border-gray500 rounded-full"></div>
                    </div>
                    <div className="flex-1 text-xs text-grayLight600 font-normal">
                      <span className="text-grayLight900 font-medium">
                        {value?.shipperCompanyName}&nbsp;
                      </span>
                      {formatAddress(value?.shipperFullAddress)}
                    </div>
                  </li>
                  <li
                    key="1"
                    className="relative flex items-start gap-x-2 pb-2 last:pb-0 mb-1 last:mb-0 [&:last-child>div:after]:hidden"
                  >
                    <div>
                      <ChevronDown className="w-[18px] text-gray500 -mt-[13px] -ml-1" />
                    </div>
                    <div className="flex-1 text-xs text-grayLight600 font-normal -mt-2.5 -ml-1">
                      <span className="text-grayLight900 font-medium">
                        {value?.consigneeCompanyName}&nbsp;
                      </span>
                      {formatAddress(value?.consigneeFullAddress)}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          ))}
        {!isOrdersLoading && !orders?.length && (
          <>
            <div className="rounded-xl border border-utilityGray200 bg-white p-4 cursor-pointer shadow-xs hover:shadow-lgc space-y-5">
              <div className="flex gap-x-3 w-full pb-5 border-b border-utilityGray200">
                <div className="rounded-md border border-utilityGray200 bg-gray50 flex items-center justify-center h-[50px] w-[50px] uppercase text-primary text-base p-2 flex-none">
                  <img src={Loginlogo} className="w-full h-full" alt="" />
                  {/* WHEN NO IMAGE FOUND BELOW CODE WILL BE USED */}
                  {/* <span>WA</span> */}
                </div>
                <div className="flex-1">
                  <div className="flex gap-x-2">
                    <div className="flex-1 truncate space-y-0.5">
                      <h6 className="text-primary700 text-xs font-semibold">
                        Picking up in approx. 10 hours
                      </h6>
                      <div className="flex items-center gap-x-1.5 flex-wrap">
                        <h6 className="text-grayLight900 text-lg">
                          #WAL-DMIF-1
                        </h6>
                        <BadgeCmp style="modern" type="success">
                          Common LTL
                        </BadgeCmp>
                        <BadgeCmp style="modern" type="error">
                          Pickup
                        </BadgeCmp>
                      </div>
                    </div>
                    <div className="flex gap-x-2 self-start">
                      <ButtonCmp
                        className="btn-outline-primary lg:!px-[9px] !px-2"
                        icon={<Lightning01 className="w-4 h-4" />}
                      >
                        <></>
                      </ButtonCmp>
                      <ButtonCmp
                        className="btn-outline-primary lg:!px-[9px] !px-2"
                        icon={<Bell03 className="w-4 h-4" />}
                      >
                        <></>
                      </ButtonCmp>
                    </div>
                  </div>
                  <div className="flex items-center flex-wrap gap-x-6 gap-y-1 pt-3">
                    <div className="flex items-center gap-x-3 max-w-full">
                      <p className="text-gray500 text-xs font-normal truncate">
                        <span className="text-grayLight900 font-medium">
                          Shipper&nbsp;
                        </span>
                        Montréal, Canada
                      </p>
                      <div className="rounded border border-borderPrimary shadow-xs p-1">
                        <ArrowRight className="w-3.5 h-3.5 text-grayLight900" />
                      </div>
                      <p className="text-gray500 text-xs font-normal truncate">
                        <span className="text-grayLight900 font-medium">
                          Shipper&nbsp;
                        </span>
                        Montréal, Canada
                      </p>
                    </div>
                    <div className="flex gap-x-1">
                      <Container className="w-4 h-4 text-gray400" />
                      <p className="text-gray500 text-xs font-normal">
                        <span className="text-grayLight900 font-medium">
                          Line Items&nbsp;
                        </span>
                        3 pallets & 2 skids
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h6 className="text-textSecondary text-xs font-medium mb-1 leading-[1.5]">
                  Order Details
                </h6>
                <div className="rounded-[10px] border border-utilityGray200 bg-gray25 shadow-sm p-4 flex gap-x-4">
                  <div className="space-y-1 flex-1">
                    <h6 className="text-grayLight600 text-xs font-normal">
                      Pickup Date
                    </h6>
                    <p className="text-textSecondary text-xs font-semibold">
                      Dec 5th, 2024
                    </p>
                  </div>
                  <div className="space-y-1 flex-1 min-w-[115px]">
                    <h6 className="text-grayLight600 text-xs font-normal">
                      Est. Delivery Date
                    </h6>
                    <p className="text-textSecondary text-xs font-semibold">
                      Dec 18th, 2024
                    </p>
                  </div>
                  <div className="space-y-1 flex-1">
                    <h6 className="text-grayLight600 text-xs font-normal">
                      REF Number
                    </h6>
                    <p className="text-textSecondary text-xs font-semibold">
                      156GJ4HJBHD
                    </p>
                  </div>
                  <div className="space-y-1 flex-1">
                    <h6 className="text-grayLight600 text-xs font-normal">
                      PO Number
                    </h6>
                    <p className="text-textSecondary text-xs font-semibold">
                      76859423
                    </p>
                  </div>
                  <div className="space-y-1 flex-1">
                    <h6 className="text-grayLight600 text-xs font-normal">
                      Total Cost
                    </h6>
                    <p className="text-textSecondary text-xs font-semibold">
                      $542.22 CAD
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <h6 className="text-textSecondary text-xs font-medium mb-1 leading-[1.5]">
                  Line Items
                </h6>
                <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                  <div className="w-full ">
                    <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                      <TableCmp
                        headCells={LineItemHeadCells}
                        tableDataArr={[{}, {}]}
                        TableRowCmp={LineItemRow}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h6 className="text-textSecondary text-xs font-medium mb-1 leading-[1.5]">
                  Additional Services
                </h6>
                <div className="rounded-xl border border-utilityGray200 shadow-sm p-3 ">
                  <ul className="flex flex-wrap gap-y-2 -mx-2">
                    <li className="flex items-center gap-x-1 px-2 text-grayLight600 text-xs font-medium w-1/3">
                      <Check className="w-4 h-4 text-primary" />
                      Inside Pickup
                    </li>
                    <li className="flex items-center gap-x-1 px-2 text-grayLight600 text-xs font-medium w-1/3">
                      <Check className="w-4 h-4 text-primary" />
                      Tailgate Pickup
                    </li>
                    <li className="flex items-center gap-x-1 px-2 text-grayLight600 text-xs font-medium w-1/3">
                      <Check className="w-4 h-4 text-primary" />
                      Pickup Appointment Required
                    </li>
                    <li className="flex items-center gap-x-1 px-2 text-grayLight600 text-xs font-medium w-1/3">
                      <Check className="w-4 h-4 text-primary" />
                      Residential Pickup
                    </li>
                    <li className="flex items-center gap-x-1 px-2 text-grayLight600 text-xs font-medium w-1/3">
                      <Check className="w-4 h-4 text-primary" />
                      Inside Delivery
                    </li>
                    <li className="flex items-center gap-x-1 px-2 text-grayLight600 text-xs font-medium w-1/3">
                      <Check className="w-4 h-4 text-primary" />
                      Tailgate Delivery
                    </li>
                    <li className="flex items-center gap-x-1 px-2 text-grayLight600 text-xs font-medium w-1/3">
                      <Check className="w-4 h-4 text-primary" />
                      Construction Site Delivery
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <h6 className="text-textSecondary text-xs font-medium mb-1 leading-[1.5]">
                  Documents
                </h6>
                <div className="rounded-[10px] border border-utilityGray200 bg-gray25 p-4 flex flex-wrap gap-x-2">
                  <div className="rounded-md border border-borderPrimary px-3 py-2 flex gap-x-1.5 min-w-[19%] flex-1">
                    <File05 className="w-4 h-4 text-textSecondary" />
                    <p className="text-primary text-xs font-semibold flex-1 ">
                      Bill of Lading
                    </p>
                    <Download03 className="w-4 h-4 text-textSecondary" />
                  </div>
                  <div className="rounded-md border border-borderPrimary px-3 py-2 flex gap-x-1.5 min-w-[19%]">
                    <File05 className="w-4 h-4 text-textSecondary" />
                    <p className="text-primary text-xs font-semibold flex-1">
                      Quote
                    </p>
                    <Download03 className="w-4 h-4 text-textSecondary" />
                  </div>
                  <div className="rounded-md border border-borderPrimary px-3 py-2 flex gap-x-1.5 min-w-[32%]">
                    <File05 className="w-4 h-4 text-textSecondary" />
                    <p className="text-primary text-xs font-semibold flex-1">
                      Commercial Invoice
                    </p>
                    <Download03 className="w-4 h-4 text-textSecondary" />
                  </div>
                  <div className="rounded-md border border-borderPrimary px-3 py-2 flex gap-x-1.5 min-w-[19%]">
                    <File05 className="w-4 h-4 text-textSecondary" />
                    <p className="text-primary text-xs font-semibold flex-1 ">
                      POD
                    </p>
                    <Download03 className="w-4 h-4 text-textSecondary" />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {isOrdersLoading && !orders?.length && (
          <NotFoundUI
            title="No Orders found"
            desc="There are no data for orders."
          />
        )}
      </div>
      <div className="px-5">
        <CustomPagination
          parentClassName="px-0"
          recordsPerPage={5}
          totalRecords={10}
          currentPage={1}
          handlePagination={() => {}}
        />
      </div>
    </>
  );
};

export default OrderDetailLeftSidebar;
