import { isValidPhoneNumber } from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { getCountries } from 'react-phone-number-input/input';
import 'react-phone-input-2/lib/style.css'; // Ensure this is imported for styling
import { getCountryFlag } from 'src/utils/CommonFunctions';

import ErrorMsg from '../errorMsg';

interface ReusablePhoneInputProps {
  value: string;
  onChange: (value: string, isValid: boolean) => void;
  errorMessage?: string;
  isFormSubmitted?: boolean;
  country?: string;
  inputClassName?: string;
  buttonClassName?: string;
  dropdownClassName?: string;
  specialLabel?: string;
  enableSearch?: boolean;
  phoneInputWrapClass?: string;
}

const PhoneInputCmp: React.FC<ReusablePhoneInputProps> = ({
  value,
  onChange,
  errorMessage = '',
  country = 'ca',
  isFormSubmitted = true,
  inputClassName = '',
  buttonClassName = '',
  dropdownClassName = '',
  specialLabel = '',
  enableSearch = false,
  phoneInputWrapClass,
}) => {
  const errorClass = '!border-red-600';
  const [isValidInput, setIsValidInput] = useState(true);
  const computedInputClass = `${inputClassName} ${
    !isValidInput && isFormSubmitted ? errorClass : ''
  }`;

  const computedButtonClass = `${buttonClassName} ${
    !isValidInput && isFormSubmitted ? errorClass : ''
  }`;

  const computedDropdownClass = `${dropdownClassName} ${
    !isValidInput && isFormSubmitted ? errorClass : ''
  }`;
  const [emojiCSS, setEmojiCSS] = useState('');

  const handlePhoneChange = (phone: string) => {
    let valid = true;

    if (!isValidPhoneNumber(`+${phone}`)) {
      valid = false;
    }
    setIsValidInput(valid);
    onChange(phone, valid);
  };

  useEffect(() => {
    handlePhoneChange(value);
  }, [value]);

  useEffect(() => {
    const styles = getCountries()
      .map((code: any) => {
        const countryCode = code.toLowerCase();
        const emoji = getCountryFlag(countryCode);

        return `
          .react-tel-input .flag.${countryCode}::before {
            content: '${emoji}';
          }
        `;
      })
      .join('\n');

    setEmojiCSS(styles);
  }, []);

  useEffect(() => {
    let lastKeyTime = 0; // Track the last keypress time
    let searchBuffer = ''; // Buffer to handle multiple keypresses in quick succession

    const handleKeyDown = (event: KeyboardEvent) => {
      const dropdown = document.querySelector('.country-list');
      if (!dropdown || !dropdown.contains(document.activeElement)) return;

      const currentTime = new Date().getTime();
      const timeDiff = currentTime - lastKeyTime;

      if (timeDiff > 500) {
        // Reset the buffer if time since last keypress exceeds 500ms
        searchBuffer = '';
      }

      const key = event.key.toLowerCase();

      if (key.length === 1 && /^[a-z]$/.test(key)) {
        // Append the pressed key to the search buffer
        searchBuffer += key;

        const countryItems = dropdown.querySelectorAll('li') as any;
        let found = false;

        for (let item of countryItems) {
          const countryName = item.innerText.toLowerCase();

          if (countryName.startsWith(searchBuffer)) {
            // Scroll and highlight the matched country
            item.scrollIntoView({ behavior: 'smooth', block: 'center' });

            // Optionally, add a highlight class for better visibility
            countryItems.forEach((el: any) => el.classList.remove('highlight'));
            item.classList.add('highlight');

            found = true;
            break;
          }
        }

        if (!found) {
          console.warn(`No match found for: "${searchBuffer}"`);
        }
      }

      lastKeyTime = currentTime; // Update the last keypress time
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div>
      <style>
        {`
        .react-tel-input .flag {
          background-image: none !important; /* Remove default flag images */
          font-size: 16px; /* Adjust emoji size */
          display: flex;
          align-items: center;
          justify-content: center;
          left:33%
        }
        .react-tel-input .selected-flag .arrow{
          margin-top: -10px !important;
          left: 4px !important;
        }
        .react-tel-input .country-list .flag {
            margin-right: 13px !important;
            margin-top: -10px !important;
        }
        ${emojiCSS}
      `}
      </style>
      <PhoneInput
        specialLabel={specialLabel}
        preferredCountries={['ca', 'us']}
        enableSearch={enableSearch}
        country={!value ? 'ca' : country}
        containerClass={`wal-tel-input ${phoneInputWrapClass}`}
        placeholder="+1 (702) 123-4567"
        inputClass={`wal-phone-input form_control !pl-11 ${computedInputClass}`}
        buttonClass={`wal-phone-input-button${computedButtonClass}`}
        dropdownClass={`wal-phone-input-dropdown${computedDropdownClass}`}
        value={value || '+1'}
        onChange={handlePhoneChange}
        priority={['ca', 'us']}
      />
      {/* Display error message */}
      {!isValidInput && isFormSubmitted && errorMessage && (
        <ErrorMsg errorText={errorMessage ?? 'Invalid Phone number'} />
      )}
    </div>
  );
};

export default PhoneInputCmp;
