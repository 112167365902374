import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
// import TableCmp from 'src/components/TableCmp';
import { TABLE_IDS } from 'src/constants/common';
// import { PATH } from 'src/constants/path';
import { isValidJSON } from 'src/utils/CommonFunctions';

const initTabTypeArray: any = [
  {
    value: 'past_shipments',
    name: 'Past Shipments',
    count: 0,
  },
  {
    value: 'claims_disputes',
    name: 'Claims & Disputes',
    count: 0,
  },
  {
    value: 'invoices',
    name: 'Invoices',
    count: 0,
  },
  {
    value: 'pods',
    name: 'PODs',
    count: 0,
  },
  {
    value: 'insurance',
    name: 'Insurance',
    count: 0,
  },
];

const recordsPerPageArray = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
];

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 10,
  status: 'all',
  team: 'all',
  member: 'all',
};

const DocumentHub = () => {
  // const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('past_shipments');

  // const [tabArray, setTabArray] = useState(initTabTypeArray);
  const tabArray = initTabTypeArray;

  const [params, setParams] = useState(() => {
    const storedParams = localStorage.getItem(TABLE_IDS.CREDIT_DASHBOARD);

    return storedParams && isValidJSON(storedParams)
      ? JSON.parse(storedParams)
      : initParams;
  });
  // const [action, setAction] = useState<any>(initAction);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [documentList, setDocumentList] = useState<any>([]);
  const [totals, setTotals] = useState(0);
  // const [userList, setUserList] = useState<any[]>([]);
  // const [isDisplayFilterModal, setIsDisplayFilterModal] = useState(false);
  // const headCells = [
  //   {
  //     id: 'firstName',
  //     name: 'User',
  //   },
  //   {
  //     id: 'status',
  //     name: 'Status',
  //   },
  //   {
  //     id: 'name',
  //     name: 'Customers',
  //   },
  //   {
  //     id: 'contactName',
  //     name: 'Contact Name',
  //   },
  //   {
  //     id: 'contactEmail',
  //     name: 'Contact Email',
  //   },

  //   {
  //     id: 'createdAt',
  //     name: 'Created Date',
  //   },
  //   {
  //     id: 'form_view_date',
  //     name: 'Viewed Date',
  //   },
  //   {
  //     id: 'email',
  //     name: 'Email',
  //   },

  //   {
  //     id: '#',
  //   },
  // ];

  const fetchCustomerOnboardingData = async (listApiSignal?: any) => {
    console.log(
      '🚀 ~ fetchCustomerOnboardingData ~ listApiSignal:',
      listApiSignal
    );
    setIsLoading(true);
    setDocumentList([]);

    try {
      const [customerOnboardingResponse, customerOnboardingCountResponse]: [
        any,
        any
      ] = await Promise.all([[], []]);

      if (customerOnboardingResponse?.data) {
        setDocumentList(customerOnboardingResponse.data);
      }

      setTotals(customerOnboardingCountResponse.total);

      setIsLoading(false);
    } catch (e: any) {
      if (e.code === 'ERR_CANCELED') {
        return;
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const listApiController = new AbortController();
    const listApiSignal = listApiController.signal;
    fetchCustomerOnboardingData(listApiSignal);

    return () => {
      listApiController.abort();
    };
  }, [activeTab]);

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old: any) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchCustomer') {
      setSearch(value);
      searchDebounce(value);
    }
  };

  const memoizedTabTypeArray = useMemo(
    () => (
      <TabButton
        parentClassName="!bg-gray50 !w-full "
        className="rounded-md text-gray500 !border-0 min-w-[182px] "
        activeClassName="!bg-primary100 !text-primary700 "
        tabParentClassName={`!w-full ${
          window.MODE === 'production' ? '!hidden' : ''
        }`}
        tabArray={tabArray}
        isActive={activeTab}
        isTab={true}
        handleOnClick={(e: any) => {
          setActiveTab(e?.target?.dataset?.value);
        }}
      />
    ),
    [tabArray, activeTab]
  );

  return (
    <PageSectionLayout
      header={
        <Header
          title="Document Hub"
          desc="Here’s an overview of your recent activity and orders picking up or delivering today."
          isShowNotificationBox
        />
      }
      contentClassName="!gap-3"
    >
      {memoizedTabTypeArray}

      <>
        <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col  flex-1">
          <div className="table-top-header ">
            <div className="table-left-wrap sm:w-auto w-full">
              <div className="table-title-wrap">
                <h5 className="table-title">All Documents</h5>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={isLoading ? 'custom-loading' : ''}
                >
                  {totals} {totals > 1 ? 'Documents' : 'Document'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                View and download your documents.
              </p>
            </div>
          </div>
          <div className="table-bottom-header">
            <div className="table-header-bottom-left flex flex-row xxl:gap-4 gap-3 justify-between w-full sm:items-center">
              <InputText
                inputName="searchCustomer"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={search}
                inputType="text"
                onChangeFunc={handleFilter}
                parentClassName="table-searchInput "
                isClearable={true}
              />
            </div>
            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === params.limit
                )}
              />
            </div>
          </div>
          <div className="h-full lg:min-h-[122px] w-full border-t border-gray100 flex flex-col ">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide flex-1">
              {/* <TableCmp
                  headCells={headCells}
                  params={params}
                  setParams={setParams}
                  tableDataArr={documentList}
                  TableLoaderRowCmp={CreditCustomerLoaderRow}
                  TableRowCmp={CreditCustomerRow}
                  numberOfSkeletonRows={15}
                  tableHeaderClass=""
                  isTableDataLoading={isLoading}
                  isTableRowClickable={true}
                /> */}
            </div>
            {!isLoading && !documentList.length && (
              <NotFoundUI
                title="No Document Found"
                desc="There are no data for document."
                containerClassName="min-h-[unset]"
              />
            )}
            {/*  */}
          </div>

          <div className="w-full bg-white rounded-b-xl">
            <CustomPagination
              recordsPerPage={params.limit}
              totalRecords={totals}
              currentPage={params.page}
              handlePagination={(page: number) => {
                setParams((old: any) => ({ ...old, page }));
              }}
            />
          </div>
        </div>
      </>
    </PageSectionLayout>
  );
};
export default DocumentHub;
