export interface IIntegrationTypeObject {
  type: String;
  typeDisplayName: string;
  integrations: IIntegration[];
}
export interface IParams {
  search?: string;
}

export interface IIntegration {
  configurations: IConfiguration[];
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  websiteUrl?: string;
  type: string;
  typeDisplayName: string;
  slug: string;
  shortDescription?: string;
  description?: string;
  isEnable: boolean;
  logoUrl?: string;
  logo?: string;
  lastUpdateTime: string | null;
  userId: number | null;
  aboutImage?: string | null;
  aboutImageUrl?: string | null;
}

export interface IConfiguration {
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  slug: string;
  value: string;
  sampleValue?: string;
  lastUpdateTime: string | null;
  integrationId: number;
  userId: number | null;
}

export interface IConfigurationModalProps {
  handleClose: (data: boolean) => void;
  integration: IIntegration | undefined;
  // setFirstModalOpen: any;
}

export interface IConfigurationFormDataObj {
  id: number;
  value: string;
}

export interface IConfigurationFormData {
  configurations: IConfigurationFormDataObj[];
}

export const IntegrationData = [
  {
    type: 'carriers',
    typeDisplayName: 'Carriers',
    integrations: [
      {
        configurations: [
          {
            createdAt: '2024-06-07 13:40:16',
            updatedAt: '2024-12-12 16:11:40',
            id: 6,
            name: 'API Url',
            slug: 'apiUrl',
            value: 'https://api.polaristransport.com:1984',
            sampleValue: '**********************',
            lastUpdateTime: '2024-12-12 16:11:40',
            integrationId: 2,
            userId: 1,
          },
          {
            createdAt: '2024-06-07 13:40:16',
            updatedAt: '2024-12-12 16:11:40',
            id: 7,
            name: 'API Key',
            slug: 'apiKey',
            value: 'MTU1NTYxNDQ1NzM3NDk5Mw',
            sampleValue: '*************************************',
            lastUpdateTime: '2024-12-12 16:11:40',
            integrationId: 2,
            userId: 1,
          },
        ],
        createdAt: '2024-06-07 13:40:16',
        updatedAt: '2025-01-13 10:17:35',
        id: 2,
        name: 'Polaris Transportation',
        type: 'carriers',
        typeDisplayName: 'Carriers',
        slug: 'POLARIS',
        shortDescription:
          'Access carrier ratings, book shipments, and track deliveries with Polaris Transportation.',
        description:
          'Polaris Transportation specializes in less-than-truckload (LTL) shipping across North America. Integrate with Polaris to access carrier rating information for specific lanes, book shipments, and monitor delivery statuses in real-time. Enhance your logistics operations with comprehensive tracking and reliable service, ensuring timely and efficient freight management.',
        isEnable: true,
        logoUrl: 'https://storage.googleapis.com/western-alliance-gcp/',
        logo: 'polaris.svg',
        aboutImage: 'polaris_about.png',
        aboutImageUrl: 'https://storage.googleapis.com/western-alliance-gcp/',
        listOrder: 3,
        lastUpdateTime: '2024-12-12 16:11:40',
        websiteUrl: 'customer.polaristransport.com',
        userId: 1,
      },
      {
        configurations: [
          {
            createdAt: '2024-06-07 13:40:18',
            updatedAt: '2024-12-12 16:10:21',
            id: 27,
            name: 'API Url',
            slug: 'apiUrl',
            value: 'https://ws.logistics.banyantechnology.com/',
            sampleValue: '**********************',
            lastUpdateTime: '2024-12-12 16:10:20',
            integrationId: 9,
            userId: 1,
          },
          {
            createdAt: '2024-06-07 13:40:18',
            updatedAt: '2024-12-12 16:10:21',
            id: 28,
            name: 'Client Id',
            slug: 'client_id',
            value: 'WAWS',
            sampleValue: '****',
            lastUpdateTime: '2024-12-12 16:10:20',
            integrationId: 9,
            userId: 1,
          },
          {
            createdAt: '2024-06-07 13:40:18',
            updatedAt: '2024-12-12 16:10:21',
            id: 29,
            name: 'Client Secret',
            slug: 'client_secret',
            value: 'r2QNQJKoA8RQSjhNkp7B7c/Hpqkc2JVn27hYR5b3wuk=',
            sampleValue: '****************',
            lastUpdateTime: '2024-12-12 16:10:20',
            integrationId: 9,
            userId: 1,
          },
        ],
        createdAt: '2024-06-07 13:40:18',
        updatedAt: '2025-01-13 10:17:37',
        id: 9,
        name: 'Banyan Technology',
        type: 'carriers',
        typeDisplayName: 'Carriers',
        slug: 'BANYAN',
        shortDescription:
          'Get carrier ratings, book shipments, and track deliveries seamlessly with Banyan Technology.',
        description:
          'Banyan Technology offers advanced freight management solutions to streamline your logistics operations. Integrate with Banyan Technology to access carrier rating information, book shipments, and track deliveries in real-time. Improve your operational efficiency with comprehensive tracking and reliable service, ensuring effective and timely freight management.',
        isEnable: false,
        logoUrl: 'https://storage.googleapis.com/western-alliance-gcp/',
        logo: 'banyan.png',
        aboutImage: 'banyantechnology_about.png',
        aboutImageUrl: 'https://storage.googleapis.com/western-alliance-gcp/',
        listOrder: 3,
        lastUpdateTime: '2024-12-12 16:10:20',
        websiteUrl: 'integration.banyantechnology.com',
        userId: 1,
      },
      {
        configurations: [
          {
            createdAt: '2024-06-07 13:40:18',
            updatedAt: '2024-12-12 16:10:56',
            id: 24,
            name: 'API Url',
            slug: 'apiUrl',
            value: 'https://api.kindersleytransport.com/',
            sampleValue: '**********************',
            lastUpdateTime: '2024-12-12 16:10:55',
            integrationId: 8,
            userId: 1,
          },
          {
            createdAt: '2024-06-07 13:40:18',
            updatedAt: '2024-12-12 16:10:56',
            id: 25,
            name: 'Firm Id',
            slug: 'firm_id',
            value: '1115387',
            sampleValue: '*********',
            lastUpdateTime: '2024-12-12 16:10:55',
            integrationId: 8,
            userId: 1,
          },
          {
            createdAt: '2024-06-07 13:40:18',
            updatedAt: '2024-12-12 16:10:56',
            id: 26,
            name: 'Token',
            slug: 'token',
            value: '6a165a0f44d5cb7e2a6ca7f1690316f3',
            sampleValue: '****************',
            lastUpdateTime: '2024-12-12 16:10:55',
            integrationId: 8,
            userId: 1,
          },
        ],
        createdAt: '2024-06-07 13:40:18',
        updatedAt: '2025-01-13 10:17:37',
        id: 8,
        name: 'Kindersley',
        type: 'carriers',
        typeDisplayName: 'Carriers',
        slug: 'KINDERSLEY',
        shortDescription:
          'Obtain carrier ratings, book shipments, and track deliveries with Kindersley’s transportation services.',
        description:
          'Kindersley offers specialized transportation services tailored to meet your logistics needs. Integrate with Kindersley to access carrier rating information, book shipments, and monitor deliveries in real-time. Benefit from reliable and efficient service to improve your supply chain management with comprehensive tracking and timely logistics.',
        isEnable: true,
        logoUrl: 'https://storage.googleapis.com/western-alliance-gcp/',
        logo: 'kindersley.png',
        aboutImage: 'kindersley_about.png',
        aboutImageUrl: 'https://storage.googleapis.com/western-alliance-gcp/',
        listOrder: 3,
        lastUpdateTime: '2024-12-12 16:10:55',
        websiteUrl: 'api.kindersleytransport.com',
        userId: 1,
      },
      {
        configurations: [
          {
            createdAt: '2024-06-07 13:40:17',
            updatedAt: '2024-12-12 16:12:26',
            id: 21,
            name: 'API Url',
            slug: 'apiUrl',
            value: 'https://tmapi.csatransportation.com/tm',
            sampleValue: '**********************',
            lastUpdateTime: '2024-12-12 16:12:26',
            integrationId: 7,
            userId: 1,
          },
          {
            createdAt: '2024-06-07 13:40:17',
            updatedAt: '2024-12-12 16:12:26',
            id: 22,
            name: 'Username',
            slug: 'username',
            value: 'APIWALLIANCE',
            sampleValue: '*********',
            lastUpdateTime: '2024-12-12 16:12:26',
            integrationId: 7,
            userId: 1,
          },
          {
            createdAt: '2024-06-07 13:40:17',
            updatedAt: '2024-12-12 16:12:26',
            id: 23,
            name: 'Password',
            slug: 'password',
            value: 'APIWEST2024!',
            sampleValue: '****************',
            lastUpdateTime: '2024-12-12 16:12:26',
            integrationId: 7,
            userId: 1,
          },
        ],
        createdAt: '2024-06-07 13:40:17',
        updatedAt: '2025-01-13 10:17:36',
        id: 7,
        name: 'CSA',
        type: 'carriers',
        typeDisplayName: 'Carriers',
        slug: 'CSA',
        shortDescription:
          'Access carrier ratings, book shipments, and track deliveries across Canada and the US with CSA Transportation.',
        description:
          'CSA Transportation specializes in trucking logistics solutions for shipments between Canada and the US. Integrate with CSA Transportation to access carrier rating information for specific lanes, book shipments, and track deliveries in real-time. Enhance your cross-border logistics operations with detailed tracking and reliable service, ensuring efficient and timely freight management.',
        isEnable: true,
        logoUrl: 'https://storage.googleapis.com/western-alliance-gcp/',
        logo: 'csa.png',
        aboutImage: 'csa_about.png',
        aboutImageUrl: 'https://storage.googleapis.com/western-alliance-gcp/',
        listOrder: 3,
        lastUpdateTime: '2024-12-12 16:12:26',
        websiteUrl: 'tmapi.csatransportation.com',
        userId: 1,
      },
      {
        configurations: [
          {
            createdAt: '2024-06-07 13:40:17',
            updatedAt: '2024-12-13 07:38:08',
            id: 19,
            name: 'API Url',
            slug: 'apiUrl',
            value: 'https://external-api.freightcom.com',
            sampleValue: '**********************',
            lastUpdateTime: '2024-12-13 07:38:08',
            integrationId: 6,
            userId: 1,
          },
          {
            createdAt: '2024-06-07 13:40:17',
            updatedAt: '2024-12-13 07:38:09',
            id: 20,
            name: 'API Token',
            slug: 'apiToken',
            value:
              'xMBFytpXcRExCDwORMuZea0NezrBK2PhnOlaP2d6gelne7A2cL04Yr7o6J8UXWbQ',
            sampleValue: '************************************',
            lastUpdateTime: '2024-12-13 07:38:08',
            integrationId: 6,
            userId: 1,
          },
        ],
        createdAt: '2024-06-07 13:40:17',
        updatedAt: '2025-01-13 10:17:36',
        id: 6,
        name: 'FreightCom',
        type: 'carriers',
        typeDisplayName: 'Carriers',
        slug: 'FREIGHTCOM',
        shortDescription:
          'Access carrier ratings, book shipments, and track deliveries efficiently with Freightcom.',
        description:
          'Freightcom provides an all-in-one carrier management platform to simplify your shipping operations. This integration allows you to access carrier rating information, book shipments, and monitor deliveries in real-time. Enhance your logistics efficiency with detailed tracking and reliable service, ensuring cost-effective and streamlined freight management.',
        isEnable: true,
        logoUrl: 'https://storage.googleapis.com/western-alliance-gcp/',
        logo: 'freightcom.png',
        aboutImage: 'freightcom_about.png',
        aboutImageUrl: 'https://storage.googleapis.com/western-alliance-gcp/',
        listOrder: 3,
        lastUpdateTime: '2024-12-13 07:38:08',
        websiteUrl: 'live.freightcom.com',
        userId: 1,
      },
      {
        configurations: [
          {
            createdAt: '2024-06-07 13:40:17',
            updatedAt: '2024-12-12 16:38:54',
            id: 16,
            name: 'API Url',
            slug: 'apiUrl',
            value: 'https://api2.m-o.com/external',
            sampleValue: '**********************',
            lastUpdateTime: '2024-12-12 16:38:53',
            integrationId: 5,
            userId: 1,
          },
          {
            createdAt: '2024-06-07 13:40:17',
            updatedAt: '2024-12-12 16:38:54',
            id: 17,
            name: 'API Key',
            slug: 'apiKey',
            value: 'ea73a9ca994e4213b2659b5414dfb6c5',
            sampleValue: '**********************',
            lastUpdateTime: '2024-12-12 16:38:53',
            integrationId: 5,
            userId: 1,
          },
          {
            createdAt: '2024-06-07 13:40:17',
            updatedAt: '2024-12-12 16:38:54',
            id: 18,
            name: 'Client Id',
            slug: 'MO_ClientID',
            value: '218946',
            sampleValue: '*****************',
            lastUpdateTime: '2024-12-12 16:38:53',
            integrationId: 5,
            userId: 1,
          },
        ],
        createdAt: '2024-06-07 13:40:17',
        updatedAt: '2025-01-13 10:17:36',
        id: 5,
        name: 'Maritime Ontario',
        type: 'carriers',
        typeDisplayName: 'Carriers',
        slug: 'MARITIME_ONTARIO',
        shortDescription:
          'Obtain carrier ratings, book shipments, and track deliveries with Maritime Ontario.',
        description:
          'Maritime Ontario offers a full range of logistics services, including intermodal, truckload, and LTL shipping. Integrate with Maritime Ontario to access carrier rating information, book shipments, and track deliveries in real-time. Improve your supply chain efficiency with comprehensive tracking and reliable service, ensuring accurate and timely logistics management.',
        isEnable: true,
        logoUrl: 'https://storage.googleapis.com/western-alliance-gcp/',
        logo: 'mo.png',
        aboutImage: 'mo_about.png',
        aboutImageUrl: 'https://storage.googleapis.com/western-alliance-gcp/',
        listOrder: 3,
        lastUpdateTime: '2024-12-12 16:38:53',
        websiteUrl: 'www.m-o.com',
        userId: 1,
      },
      {
        configurations: [
          {
            createdAt: '2024-06-07 13:40:17',
            updatedAt: '2024-12-12 16:41:01',
            id: 12,
            name: 'API Url',
            slug: 'apiUrl',
            value: 'https://dayross.dayrossgroup.com/',
            sampleValue: '**********************',
            lastUpdateTime: '2024-12-12 16:41:00',
            integrationId: 4,
            userId: 1,
          },
          {
            createdAt: '2024-06-07 13:40:17',
            updatedAt: '2024-12-12 16:41:01',
            id: 13,
            name: 'Email',
            slug: 'email',
            value: 'DRSPOT@WESTERNALLIANCELOGISTICS.COM',
            sampleValue: '**********@*****.***',
            lastUpdateTime: '2024-12-12 16:41:00',
            integrationId: 4,
            userId: 1,
          },
          {
            createdAt: '2024-06-07 13:40:17',
            updatedAt: '2024-12-12 16:41:01',
            id: 14,
            name: 'Password',
            slug: 'password',
            value: 'PWD148004',
            sampleValue: '**********',
            lastUpdateTime: '2024-12-12 16:41:00',
            integrationId: 4,
            userId: 1,
          },
          {
            createdAt: '2024-06-07 13:40:17',
            updatedAt: '2024-12-12 16:41:01',
            id: 15,
            name: 'Bill To Account',
            slug: 'billToAccount',
            value: '177977',
            sampleValue: '******',
            lastUpdateTime: '2024-12-12 16:41:01',
            integrationId: 4,
            userId: 1,
          },
        ],
        createdAt: '2024-06-07 13:40:17',
        updatedAt: '2025-01-13 10:17:36',
        id: 4,
        name: 'Day & Ross USA',
        type: 'carriers',
        typeDisplayName: 'Carriers',
        slug: 'DAY_ROSS_USA',
        shortDescription:
          'Access carrier ratings, book shipments, and track deliveries across the US with Day & Ross USA.',
        description:
          'Day & Ross USA provides specialized transportation services across the United States. Integrate with Day & Ross USA to obtain carrier rating information for various lanes, book shipments efficiently, and monitor deliveries in real-time. Enhance your logistics management with comprehensive tracking and reliable service, ensuring seamless operations across the US.',
        isEnable: true,
        logoUrl: 'https://storage.googleapis.com/western-alliance-gcp/',
        logo: 'dayross.svg',
        aboutImage: 'dayrossusa_about.png',
        aboutImageUrl: 'https://storage.googleapis.com/western-alliance-gcp/',
        listOrder: 3,
        lastUpdateTime: '2024-12-12 16:41:01',
        websiteUrl: 'dayross.com',
        userId: 1,
      },
      {
        configurations: [
          {
            createdAt: '2024-06-07 13:40:17',
            updatedAt: '2024-12-12 16:40:12',
            id: 8,
            name: 'API Url',
            slug: 'apiUrl',
            value: 'https://dayross.dayrossgroup.com/',
            sampleValue: '**********************',
            lastUpdateTime: '2024-12-12 16:40:11',
            integrationId: 3,
            userId: 1,
          },
          {
            createdAt: '2024-06-07 13:40:17',
            updatedAt: '2024-12-12 16:40:12',
            id: 9,
            name: 'Email',
            slug: 'email',
            value: 'DRSPOT@WESTERNALLIANCELOGISTICS.COM',
            sampleValue: '**********@*****.***',
            lastUpdateTime: '2024-12-12 16:40:11',
            integrationId: 3,
            userId: 1,
          },
          {
            createdAt: '2024-06-07 13:40:17',
            updatedAt: '2024-12-12 16:40:12',
            id: 10,
            name: 'Password',
            slug: 'password',
            value: 'PWD148004',
            sampleValue: '**********',
            lastUpdateTime: '2024-12-12 16:40:12',
            integrationId: 3,
            userId: 1,
          },
          {
            createdAt: '2024-06-07 13:40:17',
            updatedAt: '2024-12-12 16:40:12',
            id: 11,
            name: 'Bill To Account',
            slug: 'billToAccount',
            value: '148004',
            sampleValue: '******',
            lastUpdateTime: '2024-12-12 16:40:12',
            integrationId: 3,
            userId: 1,
          },
        ],
        createdAt: '2024-06-07 13:40:17',
        updatedAt: '2025-01-13 10:17:35',
        id: 3,
        name: 'Day & Ross',
        type: 'carriers',
        typeDisplayName: 'Carriers',
        slug: 'DAY_ROSS',
        shortDescription:
          'Get carrier ratings, book shipments, and track deliveries seamlessly with Day & Ross.',
        description:
          'Day & Ross offers a wide range of transportation services, including LTL and truckload solutions. This integration enables you to access detailed carrier rating information, book shipments easily, and track deliveries in real-time. Streamline your logistics processes and ensure accurate and timely deliveries with the dependable services of Day & Ross.',
        isEnable: true,
        logoUrl: 'https://storage.googleapis.com/western-alliance-gcp/',
        logo: 'dayross.svg',
        aboutImage: 'dayross_about.png',
        aboutImageUrl: 'https://storage.googleapis.com/western-alliance-gcp/',
        listOrder: 3,
        lastUpdateTime: '2024-12-12 16:40:12',
        websiteUrl: 'dayross.com',
        userId: 1,
      },
    ],
  },
];
