import { ArrowDown, ArrowUp } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';

export interface IHeadCell {
  id: string;
  name?: string | React.JSX.Element;
  rowClassName?: string;
  visible?: boolean;
  sortable?: boolean;
}

interface IProps {
  headCells?: IHeadCell[];
  params?: any;
  setParams?: any;
  tableDataArr: any;
  TableLoaderRowCmp?: any;
  TableRowCmp: any;
  tableRowCmpProps?: any;
  isTableDataLoading?: boolean;
  isTableDataLoadingWithData?: boolean;
  onRowClick?: any;
  isTableRowClickable?: boolean;
  trCustomClass?: boolean;
  numberOfSkeletonRows?: number;
  tableHeaderClass?: string;
  tableFooterRowClass?: string;
  footerDataArr?: any;
  TableFooterRowCmp?: any;
  tableFooterRowCmpProps?: any;
  tableFooterClass?: string;
  isTableFooterSticky?: boolean;
  isTableFooterShow?: boolean;
  trCustomBaseHeight?: number;
  totalHeight?: string;
  tableBodyRowClass?: string;
  getRowClass?: any;
  isDisplaySubData?: boolean;
  SubDataCmp?: any;
  selectedData?: any;
  selectedRows?: any;
  subDataRowCmpProps?: any;
}

const TableCmp = ({
  headCells,
  params,
  setParams,
  tableDataArr,
  TableLoaderRowCmp,
  TableRowCmp,
  tableRowCmpProps = {},
  isTableDataLoading,
  isTableDataLoadingWithData,
  onRowClick,
  isTableRowClickable,
  trCustomClass,
  tableHeaderClass,
  tableFooterClass,
  tableFooterRowClass,
  numberOfSkeletonRows = 5,
  TableFooterRowCmp,
  footerDataArr = [],
  tableFooterRowCmpProps = {},
  isTableFooterSticky = false,
  isTableFooterShow = false,
  trCustomBaseHeight,
  tableBodyRowClass,
  totalHeight,
  getRowClass,
  isDisplaySubData = false,
  SubDataCmp,
  selectedData,
  selectedRows,
  subDataRowCmpProps = {},
}: IProps) => {
  const [headCellsModified, setHeadCellsModified] = useState<IHeadCell[]>([]);
  useEffect(() => {
    const modifiedHead = headCells?.map((cell) => ({
      ...cell,
      visible: cell.visible !== undefined ? cell.visible : true,
    }));
    if (modifiedHead) setHeadCellsModified(modifiedHead);
  }, [headCells]);

  const calculateHeight = (length: any, index: any) => {
    if (length <= 7 && index === length - 1 && trCustomBaseHeight) {
      // console.log('conditoin true');

      let baseHeightDecrementNumber = trCustomBaseHeight;
      const additionalHeight = (length - 1) * 65;
      // console.log('length ', length, ' additionalHeight ', additionalHeight);
      baseHeightDecrementNumber += additionalHeight;
      // console.log(`calc(${totalHeight} - ${baseHeightDecrementNumber}px)`);

      return `calc(${totalHeight} - ${baseHeightDecrementNumber}px)`;
    }

    return 'auto';
  };

  return (
    <>
      <table className="table text-sm text-left text-nowrap whitespace-nowrap">
        {headCellsModified && (
          <thead
            className={`text-xs text-grayLight600 font-medium bg-gray50  ${tableHeaderClass}`}
          >
            <tr className="border-b border-utilityGray200">
              {headCellsModified.map((head: IHeadCell) => (
                <th
                  scope="col"
                  className={`${head.rowClassName} px-5 py-3 font-medium ${
                    head.sortable ? 'cursor-pointer' : ''
                  } ${head.visible ? '' : 'hidden'}`}
                  key={head.id}
                  onClick={() => {
                    if (head.sortable && setParams) {
                      setParams((old: any) => ({
                        ...old,
                        sortType:
                          params.sortType === 'desc' &&
                          params.sortField === head.id
                            ? 'asc'
                            : 'desc',
                        sortField: head.id,
                      }));
                    }
                  }}
                >
                  <div className={`flex items-center `}>
                    <span>{head.name}</span>
                    {head.sortable && params && (
                      <>
                        {params.sortField === head.id ? (
                          <>
                            {params.sortType === 'desc' ? (
                              <ArrowDown className="w-4 h-4 cursor-pointer flex-none ms-1" />
                            ) : (
                              <ArrowUp className="w-4 h-4 cursor-pointer flex-none ms-1" />
                            )}
                          </>
                        ) : (
                          <>
                            {params.sortType === 'desc' ? (
                              <ArrowUp className="w-4 h-4 cursor-pointer flex-none ms-1 text-gray-300" />
                            ) : (
                              <ArrowDown className="w-4 h-4 cursor-pointer flex-none ms-1 text-gray-300" />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
        )}

        <tbody>
          {tableDataArr.length > 0 ? (
            <>
              {tableDataArr.map((data: any, index: any) => (
                <React.Fragment key={`PAGE_NUMBER_${index}`}>
                  <tr
                    key={`${index}_${params?.page ?? 'PAGE_NUMBER'}`}
                    className={`text-grayLight600 border-b border-utilityGray200 font-normal text-xs bg-transparent transition-all duration-[0.5s] ${tableBodyRowClass} ${
                      data.hasUnreadNotification ? 'notification-row' : ''
                    } ${getRowClass ? getRowClass(data) : ''} ${
                      isTableRowClickable
                        ? 'cursor-pointer hover:bg-utilityGray100'
                        : ''
                    }`}
                    style={{
                      height:
                        trCustomClass && data.id == 0
                          ? calculateHeight(tableDataArr.length, index)
                          : '',
                    }}
                    onClick={() =>
                      isTableRowClickable && onRowClick && onRowClick(data)
                    }
                  >
                    <TableRowCmp
                      data={data}
                      currentIndex={index}
                      {...tableRowCmpProps}
                    />
                  </tr>
                  {isDisplaySubData && selectedData?.id === data?.id && (
                    <SubDataCmp {...subDataRowCmpProps} />
                  )}

                  {isDisplaySubData &&
                    selectedRows &&
                    selectedRows[data.id] && (
                      <SubDataCmp
                        data={data}
                        currentIndex={index}
                        {...subDataRowCmpProps}
                      />
                    )}
                </React.Fragment>
              ))}
              {isTableDataLoadingWithData
                ? [...Array(numberOfSkeletonRows)].map((_, index) => (
                    <tr
                      key={index}
                      className="text-grayLight900 text-xs font-normal border-b border-gray100 "
                    >
                      <TableLoaderRowCmp key={index} />
                    </tr>
                  ))
                : false}
            </>
          ) : isTableDataLoading ? (
            [...Array(numberOfSkeletonRows)].map((_, index) => (
              <tr
                key={index}
                className="text-grayLight900 text-xs font-normal border-b border-gray100 "
              >
                <TableLoaderRowCmp key={index} />
              </tr>
            ))
          ) : (
            false
          )}
        </tbody>
        {!isTableFooterSticky &&
          !isTableDataLoading &&
          tableDataArr.length > 0 && (
            <tfoot className={`${tableFooterClass}`}>
              {footerDataArr.map((data: any, index: any) => (
                <tr
                  key={index}
                  className={`text-grayLight900 text-xs font-normal border-b border-utilityGray200 ${tableFooterRowClass}`}
                >
                  <TableFooterRowCmp data={data} {...tableFooterRowCmpProps} />
                </tr>
              ))}
            </tfoot>
          )}
        {isTableFooterShow && (
          <tfoot className={`${tableFooterClass}`}>
            {footerDataArr.map((data: any, index: any) => (
              <tr
                key={index}
                className={`text-grayLight900 text-xs font-normal border-b border-utilityGray200 ${tableFooterRowClass}`}
              >
                <TableFooterRowCmp data={data} {...tableFooterRowCmpProps} />
              </tr>
            ))}
          </tfoot>
        )}
      </table>
      {isTableFooterSticky && TableFooterRowCmp && tableDataArr.length > 0 && (
        <table className="table text-sm text-left text-nowrap whitespace-nowrap sticky bottom-0">
          <thead
            className={`text-xs text-grayLight600 font-medium bg-gray50 invisible ${tableHeaderClass}`}
          >
            <tr className="border-b border-utilityGray200">
              {headCellsModified.map((head: IHeadCell) => (
                <th
                  scope="col"
                  className={`${head.rowClassName} px-5 py-0 font-medium ${
                    head.sortable ? 'cursor-pointer' : ''
                  } ${head.visible ? '' : 'hidden'}`}
                  key={head.id}
                  onClick={() => {
                    if (head.sortable && setParams) {
                      setParams((old: any) => ({
                        ...old,
                        sortType:
                          params.sortType === 'desc' &&
                          params.sortField === head.id
                            ? 'asc'
                            : 'desc',
                        sortField: head.id,
                      }));
                    }
                  }}
                >
                  <div className={`flex items-center `}>
                    <span className="leading-[0]">{head.name}</span>
                    {head.sortable && params && (
                      <>
                        {params.sortField === head.id ? (
                          <>
                            {params.sortType === 'desc' ? (
                              <ArrowDown className="w-4 h-0 cursor-pointer flex-none ms-1" />
                            ) : (
                              <ArrowUp className="w-4 h-0 cursor-pointer flex-none ms-1" />
                            )}
                          </>
                        ) : (
                          <>
                            {params.sortType === 'desc' ? (
                              <ArrowUp className="w-4 h-0 cursor-pointer flex-none ms-1 text-gray-300" />
                            ) : (
                              <ArrowDown className="w-4 h-0 cursor-pointer flex-none ms-1 text-gray-300" />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tfoot className={`${tableFooterClass}`}>
            {footerDataArr.map((data: any, index: any) => (
              <tr
                key={index}
                className={`text-grayLight900 text-xs font-normal border-b border-utilityGray200 ${tableFooterRowClass}`}
              >
                <TableFooterRowCmp data={data} {...tableFooterRowCmpProps} />
              </tr>
            ))}
          </tfoot>
        </table>
      )}
    </>
  );
};

export default TableCmp;
