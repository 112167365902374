import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';

import ToastBodyContainer from '../../components/ToastContainer';
import { AuthContext } from '../../context/AuthContext';

import Sidebar from './Sidebar';

const Layout = () => {
  const navigate = useNavigate();
  const { isAuthenticated, currentUser } = useContext(AuthContext);
  const pathsToMatch = [ROUTES.SET_DETAILS, ROUTES.SET_BASIC_DETAILS];
  const [isRedirect, setIsRedirect] = useState<any>(false);

  useEffect(() => {
    if (currentUser && !currentUser?.isSetBasicDetail) {
      navigate(ROUTES.SET_DETAILS);
    }
    setIsRedirect(true);
  }, [isAuthenticated, currentUser]);

  return (
    <div id="app-layout" className="p-5 shadow-lgc bg-utilityGray100">
      <div className="bg-utilityGray300 rounded-[30px] h-full overflow-auto custom-scrollbar-v2 relative shadow-lg">
        {isAuthenticated &&
          !pathsToMatch?.includes(window.location.pathname) && <Sidebar />}
        {isRedirect && <Outlet />}
        <ToastBodyContainer />
      </div>
    </div>
  );
};

export default Layout;
