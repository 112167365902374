import React, { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from 'src/context/AuthContext';

import Loader from '../../components/Loader';
import { ROUTES } from '../../constants/routes';
import { verifyCode } from '../../services/AuthService';

const Callback = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setIsAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    let data = {
      code: searchParams.get('code'),
      state: searchParams.get('state'),
      verifier: localStorage.getItem('verifier'),
    } as any;

    if (data.code && data.state && data.verifier) {
      verifyCode(data)
        .then((response: any) => {
          if (response?.token) {
            setIsAuthenticated(true);
            navigate(ROUTES.HOME);
          } else {
            navigate(ROUTES.LOGIN, {
              state: {
                message: 'Google login failed. No matching account found.',
              },
            });
          }
        })
        .catch((error: any): any => {
          navigate(ROUTES.LOGIN, {
            state: {
              message:
                error?.response?.data?.message ||
                'Login failed due to Google authentication error. Please try again later.',
            },
          });
        })
        .finally(() => localStorage.removeItem('verifier'));
    } else {
      navigate(ROUTES.LOGIN, {
        state: {
          message:
            'Login failed due to Microsoft authentication error. Please try again later.',
        },
      });
    }
  }, []);

  return <Loader />;
};

export default Callback;
