import React from 'react';

import ButtonCmp from '../ButtonCmp';

interface IProps {
  handleActionType?: () => void;
  title: any;
  desc: string;
  containerClassName?: string;
  contentClassName?: string;
  iconClassName?: string;
  Icon?: any;
  buttonText?: string;
  buttonDisabled?: boolean;
  testId?: string;
}

const Confirmation = ({
  handleActionType,
  title,
  desc,
  containerClassName = '',
  contentClassName = '',
  Icon = '',
  iconClassName = '',
  buttonText,
  buttonDisabled,
  testId,
}: IProps) => (
  <div
    data-test-id={testId}
    className={`h-full flex my-[61px] mx-4 items-center justify-center overflow-hidden ${containerClassName}`}
  >
    <div
      className={` ${contentClassName} max-w-[480px] flex items-center justify-center flex-col z-[0] relative before:[''] before:bg-[url('../../assets/img/not-found-images/empty-state.png')] before:w-full before:h-[calc(100%+190px)] before:absolute before:bottom-0 before:z-[-1] before:bg-no-repeat before:bg-top `}
    >
      <div
        className={`${iconClassName} rounded-full  h-14 w-14 text-primary flex items-center justify-center mb-4 bg-gradient-to-b from-gray50 to-utilityGray500`}
      >
        {Icon}
      </div>
      <div className="bg-white">
        <div className="text-center text-grayLight900 text-lg font-semibold">
          {title}
        </div>
        <div className="sm:max-w-[342px] p-1 text-center text-grayLight600 text-sm font-normal leading-normal">
          {desc}
        </div>

        {handleActionType && buttonText && buttonText !== 'false' && (
          <div className="flex justify-center gap-3 mt-3">
            <ButtonCmp
              type="button"
              className="btn_primary  btn-heigh-auto shadow"
              onClick={handleActionType}
              disabled={buttonDisabled}
            >
              {buttonText}
            </ButtonCmp>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default Confirmation;
