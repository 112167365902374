import { Check, ChevronDown } from '@untitled-ui/icons-react/build/cjs';
import parsePhoneNumberFromString, {
  formatIncompletePhoneNumber,
  parseIncompletePhoneNumber,
} from 'libphonenumber-js';
import React, { memo, useEffect, useLayoutEffect } from 'react';
import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { usePhone } from 'src/hooks/usePhone';
import { getCountryFlag } from 'src/utils/CommonFunctions';

import ErrorMsg from '../errorMsg';
import InputText from '../InputText/InputText';

import { INumberList, ISelectedNumber } from './PhoneInput.interface';

interface IProps {
  getNumber?: any;
  onNumberChange: any;
  disabled?: any;
  errors: any;
  name: any;
  isFocus?: any;
  isButtonClicked?: any;
  phoneInputParentClassName?: any;
  value?: any;
}

const PhoneInput = ({
  getNumber,
  onNumberChange,
  disabled,
  errors,
  name,
  isFocus = false,
  isButtonClicked,
  phoneInputParentClassName,
  value = '',
}: IProps) => {
  const { setFormatPhone, isPhoneValid } = usePhone();

  const [searchTerm, setSearchTerm] = React.useState('');
  const phoneNumber = parsePhoneNumberFromString(value);

  // const inputRef = useRef<HTMLInputElement | null>(null);
  const initialSelectedNumber: ISelectedNumber = {
    code: phoneNumber?.countryCallingCode ?? '1',
    number: phoneNumber?.nationalNumber ?? '',
    image: phoneNumber?.country
      ? getCountryFlag(phoneNumber?.country) ||
        phoneNumber?.country.toUpperCase()
      : getCountryFlag('CA'),
    name: en[phoneNumber?.country ?? 'CA'],
    country: phoneNumber?.country ?? 'CA',
  };
  const [Switch, setSwitch] = React.useState(false);
  const [selectedNumber, setSelectedNumber] = React.useState<ISelectedNumber>(
    initialSelectedNumber
  );
  const [numberList, setNumberList] = React.useState<INumberList[]>([]);
  const [isInit, setIsInit] = React.useState(true);

  useEffect(() => {
    if (phoneNumber?.nationalNumber) {
      setSelectedNumber({
        code: phoneNumber?.countryCallingCode ?? '1',
        number: phoneNumber?.nationalNumber ?? '',
        image: phoneNumber?.country
          ? getCountryFlag(phoneNumber?.country)
          : getCountryFlag('ca'),
        name: en[phoneNumber?.country ?? 'CA'],
        country: phoneNumber?.country ?? 'CA',
      });
    }

    if (!value && !phoneNumber?.nationalNumber) {
      setSelectedNumber({
        code: phoneNumber?.countryCallingCode ?? '1',
        number: phoneNumber?.nationalNumber ?? '',
        image: phoneNumber?.country
          ? getCountryFlag(phoneNumber?.country)
          : getCountryFlag('ca'),
        name: en[phoneNumber?.country ?? 'CA'],
        country: phoneNumber?.country ?? 'CA',
      });
    }
  }, [phoneNumber?.nationalNumber]);

  useEffect(() => {
    const handler = (e: any) => {
      if (
        !e.target.classList.contains('country') &&
        !e.target.classList.contains('clear')
      ) {
        setSwitch(false);
      }
    };
    window.addEventListener('click', handler);

    return () => {
      window.removeEventListener('click', handler);
    };
  }, []);

  const handlerInputClik = (e: any) => {
    e.stopPropagation();

    if (!Switch) {
      setSearchTerm('');
    }
    setSwitch(!Switch);
  };

  const handleCountryCode = (option: ISelectedNumber) => {
    setSelectedNumber({ ...selectedNumber, ...option });
  };

  const handleInputNumberChange = (e: any) => {
    // if (!inputRef.current) return;

    // const currentCursorPos = inputRef.current.selectionStart ?? 0;

    let newValue = parseIncompletePhoneNumber(e.target.value);

    if (newValue === selectedNumber.number) {
      const newValueFormatted = formatIncompletePhoneNumber(
        newValue,
        selectedNumber.country
      );

      if (newValueFormatted.indexOf(e.target.value) === 0) {
        // Trim the last digit (or plus sign).
        newValue = newValue.slice(0, -1);
      }
    }
    setSelectedNumber({ ...selectedNumber, number: newValue });

    // setTimeout(() => {
    //   if (inputRef.current) {
    //     inputRef.current.selectionStart = currentCursorPos;
    //     inputRef.current.selectionEnd = currentCursorPos;
    //   }
    // }, 0);
  };

  useEffect(() => {
    if (getNumber) {
      getNumber(`+${selectedNumber.code}${selectedNumber.number}`);
    }

    const isValid = isPhoneValid(selectedNumber.number, selectedNumber.country);
    onNumberChange(
      selectedNumber.country,
      selectedNumber.number,
      selectedNumber.code,
      isValid
    );
  }, [selectedNumber.code, selectedNumber.number, selectedNumber.country]);

  const [filterList, setFilterList] = React.useState<INumberList[]>([]);
  // (870) 899-7878
  useLayoutEffect(() => {
    const fetchData = async () => {
      let list = getCountries().map((country) => ({
        // image: require(`src/assets/flags/${country}.svg`),
        image: getCountryFlag(country) || country.toUpperCase(),
        code: getCountryCallingCode(country),
        name: en[country],
        country: country,
      }));
      const priorityCountries = ['Canada', 'United States'];

      const customSortValue = (country: string) => {
        const priorityIndex = priorityCountries.indexOf(country);

        return priorityIndex !== -1
          ? priorityIndex
          : priorityCountries.length + 1;
      };

      const shortedList = list.sort(
        (a, b) =>
          customSortValue(a.name) - customSortValue(b.name) ||
          a.name.localeCompare(b.name)
      );
      setNumberList(shortedList);
      setFilterList(shortedList);
    };

    fetchData();
  }, []);

  // const handleSearchTermClick = (e: {
  //   stopPropagation(): unknown;
  //   preventDefault: () => void;
  // }) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // };

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  useEffect(() => {
    if (isInit) {
      setIsInit(false);

      return;
    }
    const filteredList = numberList.filter((country) =>
      country.name.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    if (searchTerm === '') {
      setFilterList(numberList);
    } else {
      setFilterList(filteredList);
    }
  }, [searchTerm]);

  return (
    <div className={`relative ${phoneInputParentClassName}`}>
      <div
        className={`${
          disabled ? 'hover:border-borderPrimary bg-gray50' : ''
        } form_control flex items-center  lg:py-[9px] py-[8px] ${
          Switch && ''
        } ${
          errors ||
          errors ||
          (!isPhoneValid(selectedNumber.number, selectedNumber.country) &&
            isButtonClicked)
            ? 'is-invalid'
            : ''
        }`}
      >
        <span
          className={`flex items-center gap-x-[5px] flex-none text-[1.4rem] ${
            disabled ? '!cursor-default' : 'cursor-pointer'
          }`}
          onClick={handlerInputClik}
        >
          {selectedNumber.image}
          {/* <FaAngleDown className="ml-2" /> */}
          <ChevronDown className="w-4 h-4 text-gray400 flex-none" />
        </span>
        <p className="ml-2.5 mr-1"> +{selectedNumber.code}</p>
        <input
          // ref={inputRef}
          className="text-xs bg-transparent rounded-r-3xl focus:ring-0 focus:ring-offset-0 focus-visible:outline-none w-full border-none"
          type="text"
          name={name}
          disabled={disabled}
          value={setFormatPhone(
            selectedNumber.code,
            selectedNumber.number,
            selectedNumber.country
          )}
          onChange={handleInputNumberChange}
          placeholder={setFormatPhone(
            selectedNumber.code,
            '8708708866',
            selectedNumber.country
          )}
          autoFocus={isFocus}
          maxLength={20}
        />
      </div>
      {/* {errors && errors.phone ? <p className="text-error">{errors && errors.phone ? errors.phone.message : "Invalid Phone number"}</p> : ""} */}
      {errors && errors ? (
        <ErrorMsg errorText={errors ?? 'Invalid Phone number'} />
      ) : (
        ''
      )}
      {!disabled && Switch && (
        <div
          id="dropdownDelay"
          className="z-10 absolute shadow-lg bg-white w-full border border-utilityGray200 my-1 rounded-lg"
        >
          <InputText
            className="country"
            placeholder="Search Country"
            value={searchTerm}
            onChangeFunc={handleSearchTermChange}
            // onIconClick={handleSearchTermClick}
            isClearable={true}
            parentClassName="px-1.5 py-2"
          />
          <ul
            className="country-code-dropdown-list py-1 px-1.5 max-h-[150px] overflow-y-auto scrollbar-hide"
            aria-labelledby="dropdownDelayButton"
          >
            {filterList.map((country: any, indexNumberCode: number) => (
              <li
                onClick={() => handleCountryCode(country)}
                className={`country-code-dropdown-li px-2 py-1.5 text-xs rounded-md cursor-pointer [&.active]:!bg-gray50 hover:!bg-gray50 transition-all duration-[0.3s] flex justify-between items-center align-middle ${
                  selectedNumber.name === country.name && 'active'
                }`}
                key={indexNumberCode}
                value={country.code}
              >
                <div className="flex items-center">
                  {/* <img
                    src={country.image}
                    className="w-[18px] h-[18px] object-contain mx-1.5"
                    alt=""
                  /> */}
                  <span className="font-normal text-grayLight600 mr-2">
                    {country.image}
                  </span>
                  <span className="font-normal text-grayLight600 mr-2">
                    +{country.code}
                  </span>

                  {/* <span className="font-medium mr-2">+{country.code}</span> */}
                  <span className="font-normal text-grayLight600 mr-2">
                    {country.name}
                  </span>
                </div>

                {selectedNumber.country === country.country && (
                  // <img
                  //     src={Tick}
                  //     className="w-3 "
                  //     alt=""
                  // />
                  <Check className="w-5 h-5 text-primary" />
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default memo(PhoneInput);
