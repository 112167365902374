import moment from 'moment';
import React from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import { STATUS } from 'src/constants/common';
import { getDateWithSuffixFormat } from 'src/utils/CommonFunctions';

import logo from '../../../assets/img/logo2.svg';

interface CarrierListTableRowProps {
  data: any; // You might want to replace 'any' with the specific data type
  currentIndex: number;
  handleAction: any;
  status: string;
}

const CarrierLisRow: React.FC<CarrierListTableRowProps> = ({
  data,
  status,
  currentIndex,
  handleAction,
}) => (
  <>
    <td className="px-5 py-4 w-[22.61%] max-w-[1px] min-w-[22.61%]">
      {' '}
      <div className=" flex items-center gap-x-3">
        <img
          src={logo}
          className="table-profile-customer-circle-xl rounded-md px-1 pt-1 pb-0.5"
        />
        <p className="text-grayLight900 font-medium truncate">
          Option {currentIndex + 1}
        </p>
      </div>
    </td>

    <td className="px-5 py-4 w-[22.61%] max-w-[22.61%] min-w-[22.61%]">
      {/* {data.projectedDeliveryDate ? moment(data.projectedDeliveryDate).format("MMM. Do - YYYY") : "-"} */}
      {data.transitTime > 0 ? (
        <span
          dangerouslySetInnerHTML={{
            __html: data.projectedDeliveryDate
              ? getDateWithSuffixFormat(
                  moment.utc(data.projectedDeliveryDate).format(`MMM Do - YYYY`)
                )
              : '-',
          }}
        />
      ) : (
        '-'
      )}
    </td>
    <td className="px-5 py-4 w-[22.61%] max-w-[22.61%] min-w-[22.61%]">
      {data.transitTime > 0 ? <span>Est. {data.transitTime} days</span> : '-'}
    </td>
    <td className="px-5 py-4 w-[22.61%] max-w-[22.61%] min-w-[22.61%]">
      <span className="text-textSecondary font-bold">{data.finalCharge}</span>
    </td>
    {status === STATUS.ACTIVE && (
      <td className="px-5 py-4 w-[155px] max-w-[155px] min-w-[155px]">
        <ButtonCmp
          className="btn-outline-primary"
          onClick={() => {
            console.log('handleAction');
            handleAction({ mode: 'confirm-order', data: data });
          }}
        >
          Select Quote
        </ButtonCmp>
      </td>
    )}
  </>
);

export default CarrierLisRow;
