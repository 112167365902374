import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const getOrderList = async (params: any) => {
  const response = await axiosInterceptor.get(API.ORDERS.GET, {
    params,
  });

  return response;
};
