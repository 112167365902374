import React from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import { STATUS } from 'src/constants/common';

interface CarrierListTableRowProps {
  status: string;
}

const CarrierListLoading: React.FC<CarrierListTableRowProps> = ({ status }) => (
  <>
    <td className="px-5 py-4 w-[22.61%] max-w-[1px] min-w-[22.61%]">
      <div className=" flex items-center gap-x-3">
        <div className="table-profile-customer-circle-xl rounded-md px-1 pt-1 pb-0.5 custom-loading">
          WA
        </div>
        <p className="text-grayLight900 font-medium truncate">Option 1</p>
      </div>
    </td>

    <td className="px-5 py-4 w-[22.61%] max-w-[22.61%] min-w-[22.61%]">
      <span className="custom-loading">
        Dec 30<sup>th</sup> - 2024
      </span>
    </td>
    <td className="px-5 py-4 w-[22.61%] max-w-[22.61%] min-w-[22.61%]">
      <span className="custom-loading">Est. 5 days</span>
    </td>
    <td className="px-5 py-4 w-[22.61%] max-w-[22.61%] min-w-[22.61%]">
      <span className="text-textSecondary font-bold custom-loading">
        249.09
      </span>
    </td>
    {status === STATUS.ACTIVE && (
      <td className="px-5 py-4 w-[155px] max-w-[155px] min-w-[155px]">
        <ButtonCmp className="btn-outline-primary custom-loading">
          Select Quote
        </ButtonCmp>
      </td>
    )}
  </>
);

export default CarrierListLoading;
