import { ArrowLeft, ArrowRight } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { onError } from 'src/utils/CommonFunctions';

import BadgeCmp from '../BadgeCmp';
import TooltipCmp from '../TooltipCmp';

const TabButton = ({
  tabArray,
  isTooltip,
  isActive,
  handleOnClick,
  className,
  activeClassName,
  parentClassName,
  tabTextClass,
  tabParentClassName,
  isTab,
  isTabBorderBottom,
  tabBadgeClassName,
  isScrollable = false,
}: any) => (
  <div className={`flex items-center ${tabParentClassName}`}>
    {isScrollable && (
      <ArrowLeft className="text-gray400 w-4 h-4 flex-none mb-0.5 mr-2 cursor-pointer" />
    )}

    <div
      className={`flex shadow-sm rounded-lg w-fit  custom-scrollbar  ${parentClassName} ${
        isTooltip ? '' : 'overflow-auto'
      } ${
        isTab
          ? 'rounded-[10px] border border-utilityGray200 bg-gray50 p-[3px]'
          : ''
      }`}
    >
      {tabArray.map((tab: any, index: number) =>
        isTooltip ? (
          <TooltipCmp
            key={index}
            message={tab.tooltipValue}
            childrenClassName={`${className} ${
              isTab
                ? 'rounded-md px-3 py-1 flex items-center justify-center gap-x-2 lg:min-h-[28px] min-h-[26px]'
                : 'lg:min-h-[36px] min-h-[34px] flex justify-center items-center border border-borderPrimary border-r-0 last:rounded-r-lg last:border-r first:rounded-l-lg'
            } cursor-pointer text-xs font-semibold !block text-center  ${
              isActive === tab.value
                ? activeClassName ?? 'bg-white shadow-sm text-textSecondary'
                : isTab
                ? 'text-gray500'
                : 'bg-white text-textSecondary '
            }`}
          >
            <div
              data-value={tab.value}
              onClick={handleOnClick}
              data-test-id={tab.name}
              className="xl:px-4 lg:px-4 sm:px-3 px-2 lg:h-[36px] h-[34px] flex items-center justify-center"
            >
              {tab.image && (
                <img
                  data-value={tab.value}
                  className="rounded border border-utilityGray200 w-5 h-5 flex-none"
                  onError={onError}
                  src={tab.image}
                />
              )}

              <span data-value={tab.value} className={`${tabTextClass} `}>
                {tab.name}
              </span>
            </div>
          </TooltipCmp>
        ) : (
          <div
            key={index}
            className={`${className} ${
              isTab
                ? 'rounded-md px-3 py-1 flex items-center justify-center gap-x-2 lg:min-h-[28px] min-h-[26px]'
                : 'lg:min-h-[36px] min-h-[34px] flex justify-center items-center lg:py-1 py-2 xl:px-4 lg:px-4 sm:px-3 px-2  border border-borderPrimary border-r-0 last:rounded-r-lg last:border-r first:rounded-l-lg  '
            } cursor-pointer text-xs font-semibold text-center ${
              isActive === tab.value
                ? activeClassName ?? 'bg-white shadow-md text-textSecondary'
                : isTab
                ? 'text-gray500 '
                : 'bg-white text-textSecondary '
            }`}
            data-value={tab.value}
            onClick={handleOnClick}
            data-test-id={tab.name}
          >
            {tab.image && (
              <img
                data-value={tab.value}
                className="rounded border border-utilityGray200 w-5 h-5 flex-none"
                onError={onError}
                src={tab.image}
              />
            )}

            <span data-value={tab.value} className={`${tabTextClass}`}>
              {tab.name}
            </span>

            {tab?.count && tab?.count > 0 ? (
              <BadgeCmp
                // style="modern"
                style={`${isTabBorderBottom ? 'pill' : 'modern'}`}
                // type="primary"
                type={
                  isTabBorderBottom &&
                  (isActive === tab.value ? 'primary' : 'gray')
                }
                mainClassName={`${
                  isTabBorderBottom ? '' : 'bg-white'
                }  ${tabBadgeClassName}`}
                isHidePillDot
                dataValue={tab.value}
              >
                {tab?.count}
              </BadgeCmp>
            ) : (
              ''
            )}
          </div>
        )
      )}
    </div>
    {isScrollable && (
      <ArrowRight className="text-gray400 w-4 h-4 flex-none mb-0.5 ml-2 cursor-pointer" />
    )}
  </div>
);

export default TabButton;
