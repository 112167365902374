import {
  Edit05,
  Mail01,
  Plus,
  SearchLg,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import BadgeCmp from '../../../components/BadgeCmp';
import ButtonCmp from '../../../components/ButtonCmp';
import CommonModal from '../../../components/CommonModal';
import CustomPagination from '../../../components/CustomPagination';
import DeleteModal from '../../../components/DeleteModal';
import InputText from '../../../components/InputText/InputText';
import NotFoundUI from '../../../components/NotFoundUI';
import SelectBox from '../../../components/SelectBox/SelectBox';
import TableCmp from '../../../components/TableCmp';
// import TooltipCmp from '../../../components/TooltipCmp';
import {
  deleteMember,
  editMember,
  fetchOrganizationMemberList,
} from '../../../services/OrganizationService';
import WalToast from '../../../utils/WalToast';

import AddMember from './AddMember';
import MemberRow from './MemberRow';
import LoadingRow from './MemberRow/LoadingRow';
// import WalToast from '../../../utils/WalToast';
// import { BasicContext } from '../../../context/BasicContext';
// import { upperFirst } from 'lodash';
// import checkrighticon from '../../../assets/img/checkright.svg';
// import pendingicon from '../../../assets/img/pendingicon.svg';

const userRole = [
  { label: 'User', value: 'user' },
  { label: 'Admin', value: 'admin' },
];

const recordsPerPageArray = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '25', value: 25 },
];
// const initFormData: any = {
//   member_id: '',
//   role: '',
// };

const initParams = {
  search: '',
  page: 1,
  limit: 5,
  role: '',
  sortType: 'asc',
  sortField: 'name',
};
const initAction = {
  mode: null,
  team: false,
};

const OrganizationMembers = () => {
  // const [formData, setFormData] = useState<any>(initFormData);
  const [orgMember, setOrgMember] = useState<any>([]);
  const [adminParams, setAdminParams] = useState(initParams);
  const [searchInput, setSearchInput] = useState('');

  // const [openEditMember, setOpenEditMember] = useState(false);
  const [isMemberLoading, setIsMemberLoading] = useState(false);
  const [isEditMemberLoading, setIsEditMemberLoading] = useState(false);
  const [totalMembers, setTotalMembers] = useState(0);
  const [action, setAction] = useState(initAction);
  const [member, setMember] = useState<any>(null);
  const [openAddOrgMember, setOpenAddOrgMember] = useState<any>(false);

  const headCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Name',
        sortable: true,
        rowClassName: '',
      },
      {
        id: 'role',
        name: 'Role',
        sortable: true,
        rowClassName: '',
      },
      {
        id: 'lastLoginAt',
        name: 'Last Active',
        sortable: true,
        rowClassName: '',
      },
      {
        id: 'isActive',
        name: 'Status',
        sortable: true,
        rowClassName: '',
      },
      {
        id: 'action',
        sortable: false,
        rowClassName: '',
      },
    ],
    []
  );

  const handleAdminsPagination = (page: number) => {
    setAdminParams((old) => ({ ...old, page }));
  };

  const getOrganizationData = () => {
    setIsMemberLoading(true);
    fetchOrganizationMemberList({ ...adminParams })
      .then((response: any) => {
        setOrgMember(response?.data || []);
        setTotalMembers(response?.total || 0);
      })
      .finally(() => {
        setIsMemberLoading(false);
      })
      .catch(console.log);
  };

  useEffect(() => {
    getOrganizationData();
  }, [adminParams]);

  const adminSearchDebounce = useCallback(
    debounce(
      (search: string) =>
        // setAdminParams(() => ({ ...init, ...{ search: search } })),
        setAdminParams((old: any) => ({
          ...old,
          search: search || '',
        })),
      700
    ),
    []
  );

  const handleAdminsFilter = (event: any) => {
    setSearchInput(event.target.value);
    adminSearchDebounce(event.target.value);
  };

  const handleActionType =
    (keyAction: any, mode: any = null, memberRec: any = {}) =>
    () => {
      setMember(memberRec);
      setAction((old) => ({ ...old, [keyAction]: true, mode }));
    };

  const handleCloseModel = useCallback(() => {
    // setUserCreateFormData({});
    setAction(initAction);
    setMember(null);
  }, []);

  const handleCloseMember = useCallback(() => {
    setOpenAddOrgMember(false);
  }, []);

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      setAction(initAction);
      setMember(null);

      if (status) {
        deleteMember(member?.id)
          .then(() => {
            getOrganizationData();
            // setIsRefresh(true);
            WalToast.success(
              `${member?.name ?? 'Member'}
                 was removed from the organization.`
            );
          })
          .catch((e) => {
            WalToast.error(
              e?.response?.data?.message ??
                'Something went wrong while deleting member account.'
            );
          });
      }
    },
    [member]
  );

  const handleEditMember = () => {
    setIsEditMemberLoading(true);
    console.log(member);
    editMember({ id: member?.id, role: member?.role })
      .then((response: any) => {
        if (response?.status) {
          handleCloseModel();
          getOrganizationData();
          WalToast.success(
            'Member Role Updated',
            'Member role was successfully updated.'
          );
        } else {
          WalToast.error(
            'Error: Role Update',
            'Error encountered while updating role. Please try again.'
          );
        }
      })
      .finally(() => {
        setIsEditMemberLoading(false);
      })
      .catch(() => {
        setIsEditMemberLoading(false);
        WalToast.error(
          'Error: Role Update',
          'Error encountered while updating role. Please try again.'
        );
      });
  };

  const handleSelectChange = (e: any) => {
    setMember({ ...member, role: e?.value });
  };

  return (
    <>
      <div className="flex flex-wrap gap-y-4 last:border-b-0 last:pb-0 pb-9 border-b border-utilityGray200">
        <div className="xl:w-[30%] w-full xl:pr-11">
          <h6 className="text-textSecondary text-sm font-semibold">
            Organization Members
          </h6>
          <p className="text-grayLight600 text-sm font-normal">
            Manage your organization member
          </p>
        </div>

        <div className="xl:w-[70%] w-full flex flex-col gap-y-[30px]">
          <div className=" min-h-full w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
            <div className="table-top-header">
              <div className="table-left-wrap ">
                <div className="table-title-wrap">
                  <h5 className="table-title"> All Organization Members</h5>
                  <BadgeCmp
                    style="modern"
                    type="success"
                    mainClassName={`${isMemberLoading ? 'custom-loading' : ''}`}
                  >
                    {totalMembers} {totalMembers > 1 ? 'Members' : 'Member'}
                  </BadgeCmp>
                </div>
                <p className="table-subtitle">
                  View, add, and organize your team members
                </p>
              </div>
              <ButtonCmp
                type="button"
                className="btn-outline-primary"
                onClick={() => setOpenAddOrgMember(true)}
                icon={<Plus className="w-4 h-4" />}
              >
                Invite team member
              </ButtonCmp>
            </div>

            <div className="table-bottom-header">
              <div className="table-header-bottom-left ">
                <InputText
                  inputName="searchTeam"
                  placeholder="Search"
                  value={searchInput}
                  className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                  onChangeFunc={handleAdminsFilter}
                  icon={
                    <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                  }
                  isClearable={true}
                  parentClassName="table-searchInput"
                />
              </div>

              <div className="table-statustbox">
                <SelectBox
                  name="role"
                  placeholder="Filter by Role"
                  id="role"
                  className="form_control shadow"
                  size="sm"
                  options={userRole}
                  onChangeFunc={(event: any) => {
                    setAdminParams((old: any) => ({
                      ...old,
                      role: event?.value || '',
                    }));
                  }}
                  isSearchable={false}
                  isClearable
                  // value={null}
                  value={
                    userRole.find(
                      (val: any) => val?.value === adminParams?.role
                    ) || null
                  }
                />
              </div>

              <div className="table-recordsPerPage">
                <SelectBox
                  name="recordsPerPageGroup"
                  id="recordsPerPageGroup"
                  className="form_control shadow"
                  size="sm"
                  options={recordsPerPageArray}
                  isSearchable={false}
                  value={recordsPerPageArray.find(
                    (val: any) => val.value === adminParams.limit
                  )}
                  onChangeFunc={(event: any) => {
                    setAdminParams((old: any) => ({
                      ...old,
                      limit: event.value,
                      page: 1,
                    }));
                  }}
                />
              </div>
            </div>

            <div className="h-full w-full border-t border-gray100">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
                <TableCmp
                  headCells={headCells}
                  tableDataArr={orgMember}
                  TableLoaderRowCmp={LoadingRow}
                  TableRowCmp={MemberRow}
                  params={adminParams}
                  setParams={setAdminParams}
                  tableRowCmpProps={{
                    handleActionType: handleActionType,
                  }}
                  isTableDataLoading={isMemberLoading}
                  numberOfSkeletonRows={adminParams.limit}
                  isTableRowClickable={false}
                />
              </div>
              {!orgMember.length && !isMemberLoading ? (
                <NotFoundUI
                  title="No members found"
                  desc="There are not found any organization member in your organization, you can add a new one."
                  buttonText={`Add New Member`}
                  handleActionType={() => true}
                  buttonDisabled={false}
                />
              ) : (
                ''
              )}
            </div>
            {totalMembers ? (
              <CustomPagination
                recordsPerPage={adminParams.limit}
                totalRecords={totalMembers}
                currentPage={adminParams.page}
                handlePagination={handleAdminsPagination}
              />
            ) : null}
          </div>
        </div>
      </div>

      {action.mode === 'member-edit' && (
        // <CommonModal
        //   handleClose={() => handleCloseModel()}
        //   size={'max-w-lg w-[90%]'}
        // >
        <CommonModal
          title={'Edit Team Member'}
          titleDesc="Update and manage team member details"
          handleClose={handleCloseModel}
          headerIcon={<Edit05 />}
          secondaryBtnOnClick={() => handleCloseModel()}
          size={'max-w-[600px]'}
          primaryBtnText={'Save changes'}
          primaryBtnOnClick={handleEditMember}
          primaryBtnLoading={isEditMemberLoading}
          secondaryBtnText="Cancel"
          isOverflow={true}
        >
          <div className="p-5 flex flex-wrap gap-3">
            <InputText
              parentClassName="w-[55%]"
              inputName="email"
              label="Email address"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal "
              placeholder="you@untitledui.com"
              value={member?.email || ''}
              disabled={true}
              icon={
                <Mail01 className="absolute top-1/2 -translate-y-1/2 left-2 text-gray500 h-4 w-4" />
              }
            />

            <SelectBox
              parentClassName="flex-1"
              label="Role"
              labelClassName="form_label"
              name="role"
              placeholder="Select Role"
              className={`form_control cursor-pointer rounded-md `}
              options={userRole}
              onChangeFunc={(e) => handleSelectChange(e)}
              value={userRole?.find((val: any) => val?.value === member?.role)}
            />
          </div>
        </CommonModal>
      )}

      {action.mode === 'member-delete' && (
        <DeleteModal
          moduleName={`${member.name ?? ''} from Organization ${
            member?.name ?? ''
          }`}
          handleClose={handleModalClose}
        />
      )}

      {openAddOrgMember && (
        <AddMember
          handleCloseMember={handleCloseMember}
          getOrganizationData={getOrganizationData}
        />
      )}
    </>
  );
};

export default memo(OrganizationMembers);
