export const ORDER_STATUS_TAB: { value: string; name: string }[] = [
  {
    value: 'all',
    name: 'View All',
  },
  {
    value: 'draft',
    name: 'Drafted',
  },
  {
    value: 'booked',
    name: 'Booked',
  },
  {
    value: 'dispatched',
    name: 'Dispatched',
  },
  {
    value: 'in-transit',
    name: 'In-Transit',
  },
  {
    value: 'delivered',
    name: 'Delivered',
  },
  {
    value: 'cancelled',
    name: 'Canceled',
  },
  {
    value: 'completed',
    name: 'Completed',
  },
];

export const ORDER_LIST_HEADER = [
  {
    id: 'salesRepId',
    name: 'User',
    rowClassName: '',
    sortable: false,
  },
  {
    id: 'o.orderStatus',
    name: 'Status',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'o.orderType',
    name: 'Service Type',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'orderFromOrgName',
    name: 'Address',
    rowClassName: '',
    sortable: false,
  },
  {
    id: 'handlingUnits',
    name: 'Handling Units',
    rowClassName: '',
    sortable: false,
  },

  {
    id: 'totalWeights',
    name: 'Total Weight',
    rowClassName: '',
    sortable: false,
  },
  {
    id: 'o.createdAt',
    name: 'Order Created',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'o.combinedCost',
    name: 'Total Cost',
    rowClassName: '',
    sortable: true,
  },
];
