import {
  Mail01,
  Plus,
  UserPlus01,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
// import moment from 'moment';
import { find, findIndex, isEqual, map, maxBy } from 'lodash';
import React, { useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { inviteMember } from 'src/services/OrganizationService';
import WalToast from 'src/utils/WalToast';

const addMoreArr = [
  {
    id: 1,
    email: '',
    organization_id: '',
    role: 'user',
    emailErrorMsg: '',
    roleErrorMsg: '',
  },
];

const userRole = [
  { label: 'User', value: 'user' },
  { label: 'Admin', value: 'admin' },
];

const AddMember = ({ handleCloseMember, getOrganizationData }: any) => {
  const [addMoreInvites, setAddMoreInvites] = useState(addMoreArr);
  const [isSaveMemberLoading, setIsSaveMemberLoading] = useState(false);

  const handleMultiInputChange = (e: any, index: any) => {
    const ind = findIndex(addMoreInvites, { id: parseInt(index) });
    const newUpdate = JSON.parse(JSON.stringify(addMoreInvites));
    newUpdate[ind].email = e.target.value;
    setAddMoreInvites(newUpdate);
  };

  const handleSelectChange = (e: any, index: any) => {
    const ind = findIndex(addMoreInvites, { id: parseInt(index) });
    const newUpdate = JSON.parse(JSON.stringify(addMoreInvites));
    newUpdate[ind].role = e.value;
    setAddMoreInvites(newUpdate);
  };

  const handleSelected = (id: any) => {
    const ind = findIndex(addMoreInvites, { id: parseInt(id) });

    if (addMoreInvites?.[ind]?.role) {
      return userRole?.find(
        (val: any) => val.value === addMoreInvites?.[ind]?.role || ''
      );
    } else {
      return userRole?.find((val: any) => val.value === 'user');
    }
  };

  const handleRemoveMember = (id: any) => {
    const newObj = addMoreInvites.filter((member: any) => member?.id !== id);
    setAddMoreInvites(newObj);
  };

  const addNewInvites = () => {
    const maxId = maxBy(addMoreInvites, 'id') as any;
    const newObj = {
      id: maxId?.id + 1 || 1,
      email: '',
      role: 'user',
      errorMsg: '',
    };
    setAddMoreInvites((s: any) => [...s, newObj]);
  };

  const validMemberForm = () => {
    let valid = true;

    if (addMoreInvites?.length > 0) {
      const arr = addMoreInvites;
      addMoreInvites?.map((v: any, index: any) => {
        const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;
        arr[index].emailErrorMsg = '';
        arr[index].roleErrorMsg = '';

        if (v?.email === undefined || v?.email === '' || v?.email === null) {
          arr[index].emailErrorMsg = 'Email is required';
          valid = false;
        } else if (
          addMoreInvites[index]?.email &&
          !emailRegex.test(addMoreInvites[index]?.email)
        ) {
          arr[index].emailErrorMsg = 'Please enter valid email address';
          valid = false;
        }

        if (v?.role === undefined || v?.role === '' || v?.role === null) {
          arr[index].roleErrorMsg = 'Role is required';
          valid = false;
        }

        return valid;
      });

      map(addMoreInvites, function (o: any, i) {
        var eq = find(addMoreInvites, function (e: any, ind) {
          if (i > ind) {
            return isEqual(e.email, o.email);
          }
        });

        if (eq) {
          if (o.emailErrorMsg === '') {
            o.emailErrorMsg = 'This email address is already added in the list';
            valid = false;
          }

          return o;
        } else {
          return o;
        }
      });

      setAddMoreInvites(() => [...arr]);
    }

    return valid;
  };

  const handleSaveMember = () => {
    if (!validMemberForm()) {
      return true;
    }
    setIsSaveMemberLoading(true);
    const payload = addMoreInvites?.map((member: any) => ({
      email: member?.email,
      role: member?.role,
    }));

    inviteMember({ inviteMembers: JSON.stringify(payload) })
      .then((response: any) => {
        if (response?.status) {
          handleCloseMember();
          getOrganizationData();
          WalToast.success('Member Invitation', 'Member successfully invited.');
        } else {
          WalToast.error(
            'Error: Member Invitation',
            'Error encountered while inviting the member. Please try again.'
          );
        }
      })
      .finally(() => {
        setIsSaveMemberLoading(false);
      })
      .catch(() => {
        setIsSaveMemberLoading(false);
        WalToast.error(
          'Error: Member Invitation',
          'Error encountered while inviting the member. Please try again.'
        );
      });
  };

  return (
    <>
      <CommonModal
        title={'Invite Team Member'}
        titleDesc={'Easily invite team members to collaborate and contribute'}
        handleClose={handleCloseMember}
        headerIcon={<UserPlus01 />}
        secondaryBtnOnClick={() => handleCloseMember()}
        size={'max-w-[600px]'}
        primaryBtnText={'Invite Members'}
        primaryBtnOnClick={handleSaveMember}
        primaryBtnLoading={isSaveMemberLoading}
        secondaryBtnText="Cancel"
        isOverflow={true}
      >
        <div className="p-5">
          <div className="flex flex-col gap-y-4">
            {addMoreInvites?.map((item, i) => (
              <div className="flex gap-x-3" key={i}>
                <InputText
                  parentClassName="w-[52%]"
                  inputName="email"
                  required={true}
                  className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal "
                  label="Email address"
                  placeholder="Enter invitee's email address"
                  value={item?.email || ''}
                  onChangeFunc={(e) => handleMultiInputChange(e, item?.id)}
                  id={`email_${item?.id}`}
                  errorText={item?.emailErrorMsg ? item?.emailErrorMsg : null}
                  icon={
                    <Mail01 className="absolute top-1/2 -translate-y-1/2 left-2 text-gray500 h-4 w-4" />
                  }
                />

                <SelectBox
                  parentClassName="flex-1"
                  label="Role"
                  labelClassName="form_label"
                  name="role"
                  required={true}
                  placeholder="Select Role"
                  className={`form_control cursor-pointer`}
                  options={userRole}
                  id={`role_${item?.id}`}
                  onChangeFunc={(e) => handleSelectChange(e, item?.id)}
                  value={handleSelected(item?.id)}
                  errorText={
                    item.roleErrorMsg && !item?.role ? item.roleErrorMsg : null
                  }
                />
                <div
                  className={`mt-[22px] lg:h-[36px] h-[34px] lg:w-[36px] w-[34px] flex items-center justify-center ${
                    i > 0 ? '' : ''
                  }`}
                >
                  <ButtonCmp
                    className={`border-0 !p-0 ${
                      addMoreInvites?.length <= 1 ? 'hidden' : ''
                    }`}
                    onClick={() => handleRemoveMember(item?.id)}
                    icon={<Trash01 className="w-4 h-4 text-gray400" />}
                    // disabled={addMoreInvites?.length <= 1}
                  >
                    <></>
                  </ButtonCmp>
                </div>
              </div>
            ))}
          </div>
          <ButtonCmp
            className="btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent mt-3"
            icon={<Plus className="w-4 h-4" />}
            onClick={() => addNewInvites()}
          >
            Add another
          </ButtonCmp>
        </div>
      </CommonModal>
    </>
  );
};

export default AddMember;
