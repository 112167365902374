// import { Plus } from '@untitled-ui/icons-react/build/cjs';
// import { groupBy } from 'lodash';
import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
// import { useLocation, , useParams } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
// import ButtonCmp from 'src/components/ButtonCmp';
import InternalChatAndNotes from 'src/components/InternalChatAndNotes';
import PageSectionLayout from 'src/components/PageSectionLayout';
// import TabButton from 'src/components/TabButton';
// import TrackTraceAiCmp from 'src/components/TrackTraceAiCmp';
// import { MEASUREMENT } from 'src/constants/common';
// import { ROUTES } from 'src/constants/routes';
// import { listHandlingUnit, listService } from 'src/services/CommonService';
// import { CustomersAllList } from 'src/services/CustomerService';
// import { getOrder } from 'src/services/OrderService';
// import { getSelectBoxOptions } from 'src/utils/CommonFunctions';
import TooltipCmp from 'src/components/TooltipCmp';

import defaultImage from '../../../assets/img/default-image.jpg';
import MapboxMap from '../Common/MapBoxMap';
import OrderHeader from '../Common/OrderHeader';

import AddressDetails from './AddressDetails';
import DocumentDetails from './DocumentDetails';
import OrderDetails from './OrderDetails';

// import RadarMap from '../Common/RadarMap';
// import OrderInfoSection from '../Common/OrderInfoSection';
// import RadarAndInternalChat from '../Common/RadarAndInternalChat';
// import { defualtOrderValue, processOrderData } from '../order.interface';

// import AddressDetails from './AddressDetails';
// import Appointment from './Appointment';
// import CarrierAndSalesPrice from './CarrierAndSalesPrice';
// import DocumentDetails from './DocumentDetails';
// import LegDetails from './LegDetails';
// import LegForm from './LegForm';
// import OrderCostBreakDown from './OrderCostBreakDown';
// import OrderDetails from './OrderDetails';
// import OrderStatus from './OrderStatus';
// import SplitLegDetails from './SplitLegDetails';

// eslint-disable-next-line max-lines-per-function
export const OrderContext = createContext({});

// const dummyOrderLeg = [
//   { value: 'leg1', name: 'Master (#Leg 1)' },
//   { value: 'leg1', name: 'Master (#Leg 1)' },
//   { value: 'leg1', name: 'Master (#Leg 1)' },
// ];

const OrderView = () => {
  const { id } = useParams<{ id: any }>();
  // const location = useLocation();
  const navigate = useNavigate();
  const markerRef = useRef<mapboxgl.Marker | null>(null);
  const [startPoint, setStartPoint] = useState<any>(null);
  const [endPoint, setEndPoint] = useState<any>(null);

  // const [order, setOrder] = useState<any>([]);
  // const [orderLegIdState, setOrderLegIdState] = useState(
  //   location.state?.legId || null
  // );
  // const [salesPrice, setSalesPrice] = useState<any>([]);
  // const [carrierPrice, setCarrierPrice] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [customerList, setCustomerList] = useState<any[]>([]);
  // const [isCustomerLoading, setIsCustomerLoading] = useState<boolean>(false);
  // const [handlingUnitOptions, setHandlingUnitOptions] = useState<any>([]);
  // const [isHandlingUnitLoading, setIsHandlingUnitLoading] =
  //   useState<any>(false);
  // const [isTailgate, setIsTailgate] = useState<any>([]);

  // const [serviceTypes, setServiceTypes] = useState<any>();
  // const [serviceList, setServiceList] = useState([]);
  // const [isServiceLoading, setIsServiceLoading] = useState<any>(false);
  // const [carrierData, setCarrierData] = useState<any[]>([]);

  // const [isShowSplitLegModal, setIsShowSplitLegModal] = useState(false);

  // const [orderLegs, setOrderLegs] = useState<any>([]);
  // const [activeLeg, setActiveLeg] = useState<any>(null);
  // const [currentLegData, setCurrentLegData] = useState<any>(null);
  // const [tabValues, setTabValues] = useState<{ [key: string]: any }>({
  //   leg1: {},
  // });
  // const [legData, setLegData] = useState<any>([]);
  // const [weightMeasurement, setWeightMeasurement] = useState(
  //   MEASUREMENT.WEIGHT1.value
  // );
  // const [lengthMeasurement, setLengthMeasurement] = useState(
  //   MEASUREMENT.LENGTH1.value
  // );

  // const [isMasterOrder, setIsMasterOrder] = useState(true);
  // const [isOrderHasLeg, setIsOrderHasLeg] = useState(false);
  // const [refreshKey, setRefreshKey] = useState(0);
  // const [shipperFullAddress, setShipperFullAddress] = useState<any>(null);
  // const [consigneeFullAddress, setConsigneeFullAddress] = useState<any>(null);

  // const refreshPage = () => {
  //   setRefreshKey((prevKey) => prevKey + 1);
  // };

  useEffect(() => {
    setStartPoint({
      lat: 35.9603217,
      lng: -83.92302529999999,
      name: null,
    });

    setEndPoint({
      lat: 32.7789675,
      lng: -96.7976765,
      name: null,
    });
    setIsLoading(false);
  }, []);

  const map = useMemo(() => {
    if (startPoint && endPoint) {
      return (
        <MapboxMap
          startPoint={{
            lat: 35.9603217,
            lng: -83.92302529999999,
            name: 'Alabama',
          }}
          endPoint={{
            lat: 32.7789675,
            lng: -96.7976765,
            name: null,
          }}
          height="190px"
          className="map-container"
          markerRef={markerRef}
          isShowRoute={startPoint && endPoint}
          isZoomable={true}
          isDraggable={true}
          isFullscreen={false}
          defaultZoom={2}
        />
      );
    }
  }, [startPoint, endPoint]);

  return (
    <OrderContext.Provider
      value={{
        // currentLegData,
        // id,
        // customerList,
        // isCustomerLoading,
        // isMasterOrder,
        // isOrderHasLeg,
        // legData,
        // tabValues,
        // handlingUnitOptions,
        // isHandlingUnitLoading,
        // serviceTypes,
        // serviceList,
        // isServiceLoading,
        // setCurrentLegData,
        // setTabValues,
        // isLoading,
        // carrierData,
        // setCarrierData,
        // order,
        // setOrder,
        // refreshPage,
        // isTailgate,
        // setIsTailgate,
        // salesPrice,
        // setSalesPrice,
        // carrierPrice,
        // setCarrierPrice,
        // weightMeasurement,
        // lengthMeasurement,
        // setWeightMeasurement,
        // setLengthMeasurement,
        // setIsMasterOrder,
        // activeLeg,
        data: null,
      }}
    >
      <PageSectionLayout
        header={
          <OrderHeader
            id={id}
            isLoading={isLoading}
            order={[]}
            navigate={navigate}
            isDiscardButton={false}
            isShowActionButton={true}
          />
        }
        contentClassName="h-[calc(100%_-_88px)] overflow-x-hidden !pb-0 !pt-0 relative"
      >
        <div className="flex h-full w-full">
          <div className="flex-1 xl:pr-8 pb-5 overflow-auto custom-scrollbar-v2 pt-6 flex flex-col gap-y-6">
            <div className="flex-1 flex flex-wrap -mx-1 gap-2 justify-between border-b border-utilityGray200">
              <div className="relative py-5 px-1 ">
                <h6 className="text-xs font-normal text-grayLight600 mb-1">
                  From
                </h6>
                <TooltipCmp message="Western Alliance HQ (Montréal)">
                  <p className="mr-6 text-textSecondary text-sm font-medium truncate ">
                    Western Alliance HQ (Montréal)
                  </p>
                </TooltipCmp>
              </div>
              <div className="relative py-5 px-1 ">
                <h6 className="text-xs font-normal text-grayLight600 mb-1">
                  To
                </h6>
                <TooltipCmp message="Western Alliance HQ (Montréal)">
                  <p className="mr-6 text-textSecondary text-sm font-medium truncate ">
                    Bernier Group (Florida)
                  </p>
                </TooltipCmp>
              </div>
              <div className="relative py-5 px-1 ">
                <h6 className="text-xs font-normal text-grayLight600 mb-1">
                  Sales Rep.
                </h6>
                <div className="flex items-center gap-x-1">
                  <img
                    src={defaultImage}
                    className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                  />
                  {/* IF NO IMAGE FOUND BELOW CODE WILL BE USED */}
                  {/* <div
                    className={`w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[8px]  ${
                      isLoading ? 'custom-loading image-loading' : ''
                    }`}
                  >
                    WA
                  </div> */}

                  <p className={`text-sm text-primary hover:underline`}>
                    Olivia Rhye
                  </p>
                </div>
              </div>
              <div className="relative py-5 px-1 ">
                <h6 className="text-xs font-normal text-grayLight600 mb-1">
                  Expected Delivery
                </h6>
                <p className="xlm:max-w-56 max-w-40 text-textSecondary text-sm font-medium truncate ">
                  27<sup>th</sup> December 2024
                </p>
              </div>
              <div className="relative py-5 px-1 ">
                <h6 className="text-xs font-normal text-grayLight600 mb-1">
                  Total Price
                </h6>
                <p className="text-textSecondary text-sm font-medium truncate ">
                  $532.12
                </p>
              </div>
            </div>
            {/* ORDER DETAIL SECTION START */}
            <div>
              <h6 className="text-grayLight900 text-base font-semibold mb-3">
                Order Details
              </h6>
              <ul className="flex flex-col gap-y-6">
                <li>
                  <div className=" relative overflow-hidden border-b border-l border-utilityGray200 p-4 max-xl:pt-10">
                    <div className="rounded-lg overflow-hidden border border-utilityGray200">
                      <div className="h-full flex flex-col transition-all duration-[0.5s] max-xl:absolute">
                        {map}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <>
                    <DocumentDetails />
                  </>
                </li>
                <li>
                  <>
                    <AddressDetails />
                  </>
                </li>
                <li>
                  <>
                    <OrderDetails />{' '}
                  </>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col bg-gray25">
            <InternalChatAndNotes
              data={[]}
              chatType={'order'}
              chatChannelName={'order'}
              isShowInternalNote={false}
              notesCount={0}
              setNotesCount={<></>}
              isShowChatAndNotesSideBar={<></>}
              setIsShowChatAndNotesSideBar={<></>}
              chatEntityId={null}
              chatWrapClassName="!h-auto flex-1 overflow-x-auto"
            />
          </div>
        </div>
      </PageSectionLayout>
    </OrderContext.Provider>
  );
};

export default OrderView;
